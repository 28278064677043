import moment from 'moment';
import React, { Component   } from 'react';
import Select from 'react-select';
import { Button, Form, FormGroup, InputGroupAddon, Input, InputGroup, Modal, ModalBody, ModalHeader, Label, FormFeedback, ModalFooter } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import axios from 'axios';
import { InputMasked } from '../../InputText/InputMasked';
import { getToken } from '../../../auth/set_services';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import ModelReturn from '../ReturnsApiModals/ModelReturn';

var CurrencyInput = require('react-currency-masked-input');

const planos_list = [
    {
        modelo: 'CONTADOR-BASICO-12-2024',
        texto: 'Contador Básico',
        value: 'CONTADOR-BASICO-12-2024',
        label: 'Contador Básico',
        valor: 79.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 1,
        descricao: '1 Funcionário Incluso',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PADRAO-12-2024',
        texto: 'Contador Padrão',
        value: 'CONTADOR-PADRAO-12-2024',
        label: 'Contador Padrão',
        valor: 149.00,
        quantidade_disponivel: 6,
        limite: 6,
        funcionarios: 2,
        descricao: '2 Funcionário Incluso (max 6)',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-ESSENCIAL-12-2024',
        texto: 'Contador Essencial',
        value: 'CONTADOR-ESSENCIAL-12-2024',
        label: 'Contador Essencial',
        valor: 299.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 3,
        descricao: '10 Funcionários Inclusos (max 23)',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PLUS-12-2024',
        texto: 'Contador PLUS ',
        value: 'CONTADOR-PLUS-12-2024',
        label: 'Contador PLUS',
        valor: 399.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 3,
        descricao: '14 Funcionários Inclusos (max 20)',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-BASICO',
        texto: 'Contador Básico (Anterior)',
        value: 'CONTADOR-BASICO',
        label: 'Contador Básico',
        valor: 79.90,
        quantidade_disponivel: 1,
        limite: 255,
        funcionarios: 0,
        descricao: 'Sem Funcionário Incluso',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PADRAO',
        texto: 'Contador Padrão (Anterior)',
        value: 'CONTADOR-PADRAO',
        label: 'Contador Padrão',
        valor: 129.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 1,
        descricao: '1 Funcionário Incluso',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-EMPREENDEDOR',
        texto: 'Contador Empreendedor Plus+',
        value: 'CONTADOR-EMPREENDEDOR',
        label: 'Contador Empreendedor Plus+',
        valor: 293.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 3,
        descricao: '3 Funcionários Inclusos',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-ESSENCIAL',
        texto: 'Contador Essencial  (Anterior)',
        value: 'CONTADOR-ESSENCIAL',
        label: 'Contador Essencial  (Anterior)',
        valor: 299.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 3,
        descricao: '3 Funcionários Inclusos',
        entrega: ''
    },
    {
        modelo: 'CONTADOR-PRO',
        texto: 'Contador PRO (Anterior)',
        value: 'CONTADOR-PRO',
        label: 'Contador PRO (Anterior)',
        valor: 499.00,
        quantidade_disponivel: 1,
        limite: 1,
        funcionarios: 5,
        descricao: '5 Funcionários Inclusos',
        entrega: ''
    }
];

const tipos = [
    {   value: 'plano', label: "Cupom para plano especifico"  },
    {   value: 'planos', label: "Cupom para varios planos"  },
    {   value: 'cliente', label: "Cupom para cliente especifico"  }
];

const limite_list = [
    {   value: '0', label: "Indefinido"  },
    {   value: '1', label: "1 vez por cliente"  },
    {   value: 'personalizado', label: "Personalizado"  }
]

/* const getPlanos = (callback) => {
    var url = ENDPOINT+"planos_list";
    axios.get(url)
    .then((response) => {
        var data = response.data;
        var planos_list = []
        data.data.forEach(e => {
            planos_list.push({
                value: e.id,
                label: e.name
            })
        });
        callback(planos_list);
    })
} */

const maquininhas_list = [
    {
        modelo: 'MP5',
        label: 'Maquininha MP5',
        value: 'MP5',
        valor: 199.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/mp5.webp'
    },
    {
        modelo: 'D195',
        label: 'Maquininha D195',
        value: 'D195',
        valor: 399.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'MP35P',
        label: 'Maquininha MP35P',
        value: 'MP35P',
        valor: 999.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/d195.webp'
    },
    {
        modelo: 'S920',
        label: 'Maquininha S920',
        value: 'S920',
        valor: 599.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
    },
    {
        modelo: 'LINKPAY',
        label: 'Link de Pagamento',
        value: 'LINKPAY',
        valor: 99.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
    },
    {
        modelo: 'SHOPAQPAGO',
        label: 'E-commerce',
        value: 'SHOPAQPAGO',
        valor: 99.00,
        image: 'https://aqpago.com.br/assets/img/maquinas/s920.webp'
    }
];

class CupomModalAQContabil extends Component{
    constructor(props){
        super(props);
        this.state = {
            tipo_cupom: null,
            tipo_cupom_invalid: false,
            document: '',
            document_invalid: false,
            hash: '',
            hash_invalid: false,
            limite: null,
            limite_invalid: false,
            limite_personalizado: null,
            limite_personalizado_invalid: false,
            validade_cupom_check: false,
            validade_cupom: moment(new Date().setDate(new Date().getDate() + 10)).format('YYYY-MM-DD'),
            validade_cupom_invalid: false,
            habilitar_ja_envio: 0,
            habilitar_dias_uso: false,
            plano: null,
            plano_invalid: false,
            planos: [],
            planos_invalid: false,
            maquininha: null,
            maquininha_invalid: false,
            isCnpj: false,
            valor: 0,
            valor_invalid: false,
            valor_tipo: 'porcentagem',
            planos_list: [],
            search: '',
            loading: false,
            error_message: '',
            success_message: '',
        }
    }

    onPlano(planos_list){
        this.setState({planos_list});
    }

    componentDidMount(){
        this.onPlano(planos_list)
    }

    stepSelect = () => {
        if(this.state.tipo_cupom.value === 'plano'){
            return <>
                        <Select 
                            placeholder="Selecione o plano" 
                            options={this.state.planos_list}
                            value={this.state.plano}
                            onChange={(plano) => this.setState({ plano })}
                        />
                        <Input hidden invalid={this.state.plano_invalid}/>
                        <FormFeedback>Selecione o plano</FormFeedback>
                    </>
        }
        else if(this.state.tipo_cupom.value === 'planos'){
            return <>
                <Select 
                    placeholder="Selecione os planos" 
                    value={this.state.planos} 
                    options={this.state.planos_list}
                    onChange={(planos) => this.setState({ planos })}
                    isMulti
                />
                <Input hidden invalid={this.state.planos_invalid}/>
                <FormFeedback>Selecione os planos</FormFeedback>
            </>
        }
        else if(this.state.tipo_cupom.value === 'cliente'){
            return <>
                    <FormGroup>
                        &nbsp;&nbsp;<input type="radio" checked={this.state.isCnpj === false} onChange={() => this.setState({ isCnpj: false })}/>&nbsp;CPF &nbsp;&nbsp;
                        <input type="radio" checked={this.state.isCnpj} onChange={() => this.setState({ isCnpj: true })}/>&nbsp;CNPJ
                    </FormGroup>
                    <InputMasked
                        mask={this.state.isCnpj === true ? "99.999.999/9999-99" :"999.999.999-99"}
                        placeholder={"Documento "+ this.state.isCnpj === true ? 'CNPJ' : 'CPF'}
                        value={this.state.document}
                        invalid={this.state.document_invalid}
                        onChange={e => this.setState({ document: e.target.value })}
                    />
                </>
        }
        else{
            return <></>
        }
    }

    gerar_hash = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }
     
     validar_campos = () => {
         var invalids = 0;

        if(this.state.hash.length > 3){
            this.setState({ hash_invalid: false})
        }
        else{
            invalids++;
            this.setState({ hash_invalid: true})
        }

        if(this.state.limite !== null){
            this.setState({ limite_invalid: false});

            if(this.state.limite.value == 'personalizado'){
                if(this.state.limite_personalizado !== ''){
                    this.setState({ limite_personalizado_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({ limite_personalizado_invalid: true})
                }
            }

        }
        else{
            invalids++;
            this.setState({ limite_invalid: true})
        }

        if(this.state.tipo_cupom !== null){
            this.setState({ tipo_cupom_invalid: false});

            if(this.state.tipo_cupom.value == 'plano'){
                if(this.state.plano !== null){
                    this.setState({ plano_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({ plano_invalid: true})
                }
            }
            else if(this.state.tipo_cupom.value == 'planos'){
                if(this.state.planos.length > 0){
                    this.setState({ planos_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({ planos_invalid: true})
                }
            }
            else if(this.state.tipo_cupom.value == 'cliente'){
                if(this.state.isCnpj == true){
                    if(cnpj.isValid(this.state.document.replace(/([^\d])+/gim, ''))){
                        this.setState({ document_invalid: false})
                    }
                    else{
                        invalids++;
                        this.setState({ document_invalid: true})
                    }
                }
                else{
                    if(cpf.isValid(this.state.document.replace(/([^\d])+/gim, ''))){
                        this.setState({ document_invalid: false})
                    }
                    else{
                        invalids++;
                        this.setState({ document_invalid: true})
                    }
                }

            }
            else if(this.state.tipo_cupom.value == 'maquininha'){
                if(this.state.maquininha !== null){
                    this.setState({ maquininha_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({ maquininha_invalid: true})
                }
                
            }
        }
        else{
            invalids++;
            this.setState({ tipo_cupom_invalid: true})
        }

        if(invalids > 0){
            return false;
        }

        return true;
     }
    
     onRegister = () => {
        if(this.validar_campos() === true){
            this.setState({loading: true})
            var url = ENDPOINT+"cupons-aqcontabil/criar_cupom";

            var formData = {
                "hash": this.state.hash,
                "condicao": this.state.tipo_cupom.value,
                "limite": this.state.limite.value === "personalizado" ? this.state.limite_personalizado : parseInt(this.state.limite.value, 10),
                "valor": this.state.valor,
                'habilitar_ja_existente': this.state.habilitar_ja_envio,
                "valor_tipo": this.state.valor_tipo
            }

            if(this.state.validade_cupom_check == false){
                formData.validate = this.state.validade_cupom;
            }
            
            switch(this.state.tipo_cupom.value){
                case 'plano' : {
                    formData.plano = this.state.plano.label;
                    break;
                }
                case 'planos' : {
                    formData.planos = [];
                    this.state.planos.forEach(p => {
                        formData.planos.push(p.label);
                    })
                    break;
                }
                case 'maquininha' : {
                    formData.maquininha = this.state.maquininha.label;
                    break;
                }
                case 'cliente' : {
                    formData.documento = this.state.document.replace(/([^\d])+/gim, '');
                    break;
                }
            }

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }})
            .then((response) => {
                var data = response.data;
                this.onData(data);
                return data;
            })
            .catch((error) => {
                if(error.message === 'Network Error'){
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                }
                var data = error.response.data;
                this.onData(data);
            });

        }
    }
    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
        valor_current = valor_current.replaceAll('.', '');
        var digits = valor_current.substr(valor_current.length - 2);
        valor_current = valor_current.slice(0, -2);
        valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({ valor: valor_current });
        }
    };
    onError = () => {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onClose = () => {
        this.setState({
            tipo_cupom: null,
            tipo_cupom_invalid: false,
            document: '',
            document_invalid: false,
            hash: '',
            hash_invalid: false,
            limite: null,
            limite_invalid: false,
            limite_personalizado: null,
            limite_personalizado_invalid: false,
            validade_cupom_check: false,
            validade_cupom: moment(new Date().setDate(new Date().getDate() + 10)).format('YYYY-MM-DD'),
            validade_cupom_invalid: false,
            habilitar_ja_envio: 0,
            plano: null,
            plano_invalid: false,
            planos: [],
            planos_invalid: false,
            maquininha: null,
            maquininha_invalid: false,
            isCnpj: false,
            valor: 0,
            valor_invalid: false,
            valor_tipo: 'porcentagem',
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }
    render(){
        return(
            <Modal isOpen={this.props.visible}>
                <ModalHeader>Novo Cupom</ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <InputGroup>
                                <Input 
                                    placeholder="Hash"
                                    invalid={this.state.hash_invalid}
                                    value={this.state.hash}
                                    onChange={e => this.setState({ hash: e.target.value })} />
                                <InputGroupAddon addonType="append">
                                    <Button 
                                        color="info"
                                        onClick={() => this.setState({ hash: this.gerar_hash(10) })}>GERAR HASH</Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup> 
                        <FormGroup>
                            <InputGroup>
                                {/* <Input 
                                    placeholder="valor"
                                    invalid={this.state.hash_invalid}
                                    value={this.state.hash}
                                    onChange={e => this.setState({ hash: e.target.value })} /> */}
                                    
                                <CurrencyInput 
                                    inputMode="tel"
                                    className="form-control" 
                                    currency="BRL"
                                    name="valor_de"
                                    //value={this.state.valor}
                                    defaultValue={'0.00'}
                                    onChange={this.onValor}/>
                                <InputGroupAddon addonType="append">
                                    <Input 
                                        type="select" 
                                        style={{borderRadius: '0'}} 
                                        value={this.state.valor_tipo}
                                        onChange={e => this.setState({ valor_tipo: e.target.value })}>
                                        <option value="porcentagem">% - Porcentagem</option>
                                        <option value="moeda">R$ - Moeda</option>
                                    </Input>
                                    {/* <Button 
                                        color="info"
                                        onClick={() => this.setState({ hash: this.gerar_hash(10) })}>GERAR HASH</Button> */}
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup> 
                        <FormGroup>
                            <Select
                                placeholder="Tipo" 
                                options={tipos}
                                isSearchable={false}
                                value={this.state.tipo_cupom}
                                onChange={(tipo_cupom) => this.setState({ tipo_cupom })}
                            />
                            <Input hidden invalid={this.state.tipo_cupom_invalid}/>
                            <FormFeedback>Selecione o tipo de cupom</FormFeedback>
                        </FormGroup>
                        {this.state.tipo_cupom !== null ? 
                            <FormGroup>
                                {this.stepSelect()}
                            </FormGroup>
                        :null}

                        <FormGroup>
                            <Select
                                isSearchable={false}
                                options={limite_list}
                                placeholder="Limite de uso (por cliente)" 
                                value={this.state.limite}
                                onChange={(limite) => this.setState({ limite })}
                            />
                            <Input hidden invalid={this.state.limite_invalid}/>
                            <FormFeedback>Selecione o limite de uso por cliente</FormFeedback>
                        </FormGroup>
                            {this.state.limite !== null && this.state.limite.value == 'personalizado' ?
                                <>
                                <FormGroup>
                                    <Input 
                                        min={1}
                                        type="number" 
                                        placeholder="Insira o limite de usos por cliente"
                                        value={this.state.limite_personalizado}
                                        invalid={this.state.limite_personalizado_invalid}
                                        onChange={e => this.setState({ limite_personalizado: e.target.value })}
                                    />
                                    </FormGroup>
                                </>
                            :
                            null}
                        <FormGroup>
                            <Label>Insira a validade do cupom:</Label>
                            {this.state.validade_cupom_check == false ?
                                <Input 
                                    type="date"
                                    value={this.state.validade_cupom}
                                    invalid={this.state.validade_cupom_invalid}
                                    onChange={e => this.setState({ validade_cupom: e.target.value })}
                                />
                            :<br/>}
                            <input 
                                type="checkbox"
                                value={this.state.validade_cupom_check}
                                onChange={e => this.setState({ validade_cupom_check: e.target.checked })}/> Validade indefinida
                        </FormGroup>
                        <FormGroup>
                            <div style={{display: 'inline'}}>
                                <hr/>
                                <input type="radio" checked={this.state.habilitar_ja_envio === 0} name="habilitar_ja_envio" onChange={() => this.setState({habilitar_ja_envio: 0})}/>&nbsp;Somente para novos clientes&nbsp;&nbsp;
                                <input type="radio" checked={this.state.habilitar_ja_envio === 1} name="habilitar_ja_envio" onChange={() => this.setState({habilitar_ja_envio: 1})}/>&nbsp;Para novos e e já clientes
                            </div>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Salvar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default CupomModalAQContabil;