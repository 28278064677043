import axios from "axios";
import { Component } from "react";
import { MdArrowBack, MdCheck, MdClear, MdMail, MdWarning } from "react-icons/md";
import { BsLock, BsUnlock } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Collapse, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import styled from "styled-components";
import { ENDPOINT } from "../../auth/endpoint";
import { getPermissoes, getToken, getUser } from "../../auth/set_services";
import Select from "react-select";
import { list_prevencao_status, list_status_pagamentos } from "../../auth/listas";
import { InputMasked } from '../../components/InputText/InputMasked';
import { InputText } from "../../components/InputText/InputText";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from 'react-spinners/ClipLoader'
import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import sanitizeHtml from 'sanitize-html';
import ReactTooltip from "react-tooltip";
import GoogleMapReact from 'google-map-react';
import DataTable from 'react-data-table-component';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FaEye, FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import Pusher from 'pusher-js';
import { ButtonRed } from "../../components/ButtonStylish/ButtonRed";
import { DropdownIcon } from "../../components/DropdownIcon/DropdownIcon";
import SendEmailByCard from "../../components/Modals/CreditCard/SendEmailByCard";
import { MessageNoTable } from "../MessageTable/MessageNoTable";
import ConsultarBuyer from "../../components/Modals/Prevencao/ConsultarBuyer";
import ConsultarSeller from "../../components/Modals/Prevencao/ConsultarSeller";
import ReactScoreIndicator from "react-score-indicator";
import ConsultaMixDataView from "../../components/Modals/Prevencao/ConsultaMixDataView";
import ConsultarSellerLocaliza from "../../components/Modals/Prevencao/ConsultarSellerLocaliza";
import ConsultaLocalizaDataView from "../../components/Modals/Prevencao/ConsultaLocalizaDataView";

var CurrencyInput = require('react-currency-masked-input');

const maskDate = value => {
    let v = value.replace(/\D/g,'').slice(0, 10);
    if (v.length >= 5) {
      return `${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`;
    }
    else if (v.length >= 3) {
      return `${v.slice(0,2)}/${v.slice(2)}`;
    }
    return v
  }
const getStringMaps = (mail) => {
    var string_mail = "";
    mail.forEach((p) => {
      string_mail += getTranslate(p.recordType) + " " + moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a') + "<br /> "
    });
  
    return string_mail;
  }
  const getTranslate = (name) => {
    switch (name) {
      case 'Delivery': return 'Entregue'
      case 'Open': return 'Aberto'
      case 'Click': return 'Clicado'
      case 'SpamComplaint': return 'Caixa de SPAM'
      case 'Bounce': return 'Mensagem devolvida'
      default : return name;
    }
  }
  
const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`;
const getResposta = (resposta) => {
  if(resposta === 'Message delivered to handset'){
      return "entregue ao destinatário";
  }
  else{
      return resposta;
  }
}

const status_transacao = (payment_type) => {
    switch(payment_type){
        case 'succeeded' : return <><MdCheck />Aprovada</>;
        case 'canceled' : return <><MdClear/>Cancelada</>;
        case 'pending' : return 'Pendente';
        case 'failed' : return <><MdClear/>Falhada</>;
        case 'disputed': return 'Em disputa';
        default: return payment_type
    }
}
const operation = (operation_type) => {
    switch(operation_type){
        case 'created' : return 'Criado';
        case 'authorization' : return 'Autorizado';
        case 'pre_authorization' : return 'Pré Autorizado';
        case 'capture' : return 'Capturado';
        case 'void' : return 'Estornado';
        default: return operation_type
    }
}
const colunas_pagamentos = [
    {
        name: "ID",
        selector: "id",
        width: '280px',
        sortable: false,
    },
    {
        name: "Data",
        selector: "created_at",
        sortable: false,
        width: '150px',
        cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
    },
    {
        name: "Operação",
        selector: "operation_type",
        sortable: false,
        width: '15%',
        cell: p => operation(p.operation_type)
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
        cell: row => row.status !== 'succeeded' && row.error_message !== null ? <>{status_transacao(row.status)} - ERRO NA TRANSAÇÃO: {row.response_message}</> : status_transacao(row.status),
    },
    {
        name: "Valor",
        selector: "amount",
        sortable: false,
        width: '170px',
        cell: p => <>R$ {p.amount.replace('.', ',')}</>
    },
];
const getStatus = (status) => {
    if(status === 'pendente'){
        return "Pendente";
    }
    else if(status === 'PENDENTE'){
        return "Pendente";
    }
    else if(status === 'DELIVERED'){
        return "Entregue";
    }
    else{
        return status;
    }
}
const getStatusPagamento = (value, status_current) => {
  if(value === "" || value === null){
      var status_is_not_antifraude = '';
      list_status_pagamentos.map((p) => {
          if(p.value === status_current){
              status_is_not_antifraude = p.label
          }
          return "";
      })
      return status_is_not_antifraude;
  }
  else{
      var status_is = '';
      const status = [
          { value: 'NVO', label: "Análise Pendente"},
          { value: 'AMA', label: "Análise Iniciada"},
          { value: 'APA', label: 'Aprovado Automaticamente'},  
          { value: 'APM', label: "Aprovado Manualmente"},
          { value: 'SUS', label: "Suspeita de Fraude"},
          { value: 'FRD', label: "Fraude Confirmada"},
          { value: 'RMA', label: 'Reprovado Manualmente'},
          { value: 'RPM', label: 'Reprovado sem contato'},
      ];

      status.forEach(p => {
          if(p.value === value){
              status_is = p.label;
          }
      });

      return status_is;
  }
}
const getId = () => {
    return window.location.pathname.replace('/prevencao-detalhes/', '')
}
const operadores = [
    {value: '84168552004', label: 'Luciano Santos'},
    {value: '01692302523', label: 'Aline Katielly'},
    {value: '64204186076', label: 'Guilherme Batista'}
]

const payment = (payment_type) => {
    switch(payment_type){
        case 'credit' : return 'Crédito';
        case 'boleto' : return 'Boleto';
        case 'debit' : return 'Débito';
        default: return payment_type
    }
}
const get_status_antifraude = (value) => {
    var status_is = '';
    const status = [
        { value: 'NVO', label: "Análise Pendente"},
        { value: 'AMA', label: "Análise Iniciada"},
        { value: 'APA', label: 'Aprovado Automaticamente'},  
        { value: 'APM', label: "Aprovado Manualmente"},
        { value: 'SUS', label: "Suspeita de Fraude"},
        { value: 'FRD', label: "Fraude Confirmada"},
        { value: 'RMA', label: 'Reprovado Manualmente'},
        { value: 'RPM', label: 'Reprovado sem contato'},
    ];

    status.forEach(p => {
        if(p.value === value){
            status_is = p.label;
        }
    });

    return status_is;
}
const OptionsSMS = styled.div`
    display: flex;
    margin-bottom: 8px;

    button{
        margin-right: 4px;
    }

    .select-option{
        width: 100%;
    }
`;

const columns = (state, setAnaliseCartao, bloquearCartao) => [
    {
        name: "",
        sortable: false,
        width: '70px',
        cell: p => <>
                        <Button 
                            color="dark" 
                            outline 
                            style={{
                                border: 0,
                                padding: 3
                            }}
                            disabled={state.loading_analises_cartao} 
                            onClick={() => setAnaliseCartao(p.numero)}>
                            {state.loading_analises_cartao === true ?
                                <ClipLoader color="#000" size={25}/>
                                :
                                <IoReceiptOutline style={{padding: 0}}/>} 
                        </Button>
                        <Button 
                            color="dark" 
                            outline 
                            style={{
                                border: 0,
                                padding: 3
                            }}
                            disabled={state.loading_analises_cartao} 
                            onClick={() => bloquearCartao(p)}
                        >
                            {state.loading_card === p.numero ?
                                <ClipLoader color="#000" size={25}/>
                                :
                                (p.block_admin === false ?
                                    <BsLock style={{padding: 0}}/>
                                    :
                                    <BsUnlock style={{padding: 0}}/>)
                                
                            } 
                            
                        </Button>
                    </>
    },
    {
        name: "Tipo de cartão",
        selector: "tipo_cartao",
        sortable: false,
        width: '12%',
        cell: p => p.tipo_cartao.toUpperCase()
    },
    {
        name: "Nome no Cartão & Apelido",
        selector: "nome_proprietario",
        sortable: false,
        width: '20%',
        cell: p => <ul style={{listStyleType: 'circle', marginTop: '15px', paddingLeft: 0}}>
            <li key={'nome_prop'} style={{marginBottom: '7px'}}>{p.nome_proprietario}</li>
            <li key={'apelido_card'} style={{marginBottom: '7px'}}>{p.apelido_cartao}</li>
        </ul>
    },
    {
        name: "Número do cartão",
        selector: "numero",
        sortable: false,
        width: '150px'
    },
    {
        name: "Validade",
        selector: "tipo_cartao",
        sortable: false,
        width: '100px',
        cell: p => p.validade_mes + "/"+p.validade_ano
    },
    /* {
        name: "Apelido do cartão",
        selector: "apelido_cartao",
        sortable: false,
        width: '12%'
    }, */
    {
        name: "Bandeira",
        selector: "bandeira",
        sortable: false,
        width: '150px',
        cell: p => p.bandeira !== null ? p.bandeira.toUpperCase() : 'Sem informações'
    },
    {
        name: "Dono do Cartao",
        sortable: false,
        cell: p => <ul style={{listStyleType: 'circle', marginTop: '15px', paddingLeft: 0}}>
            <li key={'sobrenome'} style={{marginBottom: '7px'}}>{p.user.nome} {p.user.sobrenome}</li>
            <li key={'email'} style={{marginBottom: '7px'}}>{p.user.email}</li>
            <li key={'doc'} style={{marginBottom: '7px'}}>
                {p?.user?.cpf ? (<NumberFormat displayType={'text'} value={p.user.cpf} format={p.user.cpf.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/>) : (<></>)}
            </li>
        </ul>
    },
];

const collumns_transacao = () => [
    {
        name: "Buyer",
        selector: "buyer",
        sortable: false,
        width: '25%',
        cell: p => p.comprador.user ? (p.comprador.user.nome + ' ' + p.comprador.user.sobrenome) + ' - ' + p.comprador.user.cpf : p.comprador.user.razao_social + p.comprador.empresa.cnpj
    },
    {
        name: "Seller",
        selector: "seller",
        sortable: false,
        width: '25%',
        cell: p => typeof p.vendedor.user_tipo != 'undefined' ? (p.vendedor.user_tipo === 'empresarial' ? p.vendedor.empresa.razao_social + ' - ' + p.vendedor.empresa.cnpj : p.vendedor.user.nome + ' ' +  p.vendedor.user.sobrenome) : 'Sem informações'
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        width: '150px',
        cell: p => getStatusPagamento(p.antifraude_code, p.status)
    },
    {
        name: "Valor",
        selector: "valor",
        sortable: false,
        width: '150px',
        cell: p => "R$ "+parseFloat(p.valor).toFixed(2).replace('.', ',')
    },
    {
        name: "Data do Pedido",
        selector: "data_pagamento",
        sortable: false,
        width: '150px',
        cell: p => moment(p.data_pagamento).format('DD/MM/YYYY HH:mm')
    },

];

const collumns_ip = () => [
    {
        name: "Buyer",
        selector: "buyer",
        sortable: false,
        width: '20%',
        cell: p => p.comprador !== null ? p.comprador.nome + ' ' + p.comprador.sobrenome : 'Sem info'
    },
    {
        name: "Seller",
        selector: "seller",
        sortable: false,
        width: '20%',
        cell: p => p.vendedor !== null ? typeof p.vendedor !== 'string' ? (p.vendedor.nome + ' ' + p.vendedor.sobrenome) : p.vendedor : ''
    },
    {
        name: "Forma de Pagamento",
        selector: "forma_pagamento",
        sortable: false,
        width: '12%',
        cell: p => payment(p.forma_pagamento)
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        width: '15%',
        cell: p => getStatusPagamento(p.antifraude_code, p.status)
    },
    {
        name: "Valor",
        selector: "valor",
        sortable: false,
        width: '150px',
        cell: p => "R$ "+parseFloat(p.valor).toFixed(2).replace('.', ',')
    },
    {
        name: "Data do Pedido",
        selector: "data_pagamento",
        sortable: false,
        width: '150px',
        cell: p => moment(p.data_pagamento).format('DD/MM/YYYY HH:mm')
    },

]

const AnyReactComponent = ({ text }) => <div style={{width: '126px', textAlign: 'center', fontWeight: '800'}}><FaMapMarkerAlt style={{color: 'red'}}/> <br/>{text}</div>;

class ResumoPrevencao extends Component{
    constructor(props){
        super(props);
        this.state = {
            isOpenSMSBuyer: false,
            isOpenEmailBuyer: false,
            loading_suspeito_seller: false,
            loading_suspeito_buyer: false,
            loading_buyer_messages: true,
            loading_buyer_messages_email: true,
            ip_transacoes_count: 1,
            solicitacao_bloqueio: false,
            loading_payout: false,
            loading_email_reenviar: false,

            view_cartao: false,
            numero_cartao: '',
            email_vendedor: '',
            transacoes: [],
            id_transacao: '',
            history: [],
            sellers_mesmo_endereco: [],
            buyers_mesmo_endereco: [],
            transferencias_seller: [],
            loading_analises_cartao: false,
            loading_card: null,

            valor_validacao_primeiro: '0.00',
            show_primeiro: false,
            loading_primeiro: false,

            visible_consulta_comprador: false,

            valor_validacao_segundo: '0.00',
            show_segundo: false,
            loading_segundo: false,
            loading_consultar_comprador: false,
            loading_consultar_vendedor: false,

            view_ip: false,
            transacoes_ip: [],
            loading_analises_ip: false,

            suspeito_comprador: 0,
            suspeito_vendedor: 0,
            notas_index: [],
            nota_mensagem: '',
            is_open_nota: false,
            loading_nota: false,

            notas_buyer: [],
            is_open_nota_buyer: false,
            nota_mensagem_buyer: '',
            loading_nota_buyer: false,
            show_suspeita_buyer: false,
            show_suspeita_seller: false,

            notas_seller: [],
            is_open_nota_seller: false,
            nota_mensagem_seller: '',
            loading_nota_seller: false,
            cancelar_motivo: false,

            loading_nota_id: null,
            mensagem: '',
            mensagem_email:  EditorState.createEmpty(),
            mensagens: [],
            mensagens_emails: [],
            atividade_estabelecimento: '',
            nome_seller: '',
            cadastro_data_estabelecimento: '',
            cpf_cnpj_estabelecimento: '',
            endereco_estabelecimento: '',
            nome_comprador: '',
            email_comprador: '',
            cpf_comprador: '',
            produto_servico: '',
            idade_comprador: '',
            endereco_comprador: '',
            funcionario_responsavel: {name:''},
            telefone_comprador: '',
            historico_reprovacoes: [],
            reference_id_service_antifraud: null,
            historico_service_antifraud: [],
            historico_charge_back_seller: [],
            transferencias_cartoes_buyer: [],
            data_pagamento: '',
            inicio_analise: '',
            funcionario_inicio_responsavel: {name:''},
            token_sms: '',
            token_sms_invalid: false,
            forma_pagamento: '',
            produto_nome: '',
            email: '',
            valor: '0',
            valor_primario: '0',
            valor_secundario: '0',
            numero_parcelas: 1,
            email_invalid: false,
            loading_button_message: false,
            final_analise: null,
            operador: null,
            operador_invalid: false,
            aqbank_request: {},
            aqbank_request_primario: null,
            aqbank_request_secundario: null,
            prevencao_exists: false,
            browser: '',
            ip_client: '',
            device: '',
            status: '',
            plano_assinatura: '',
            antifraude_code: '',
            situacao_cpf: '',
            nome_fatura: '',
            cartao_credito: null,
            status_current: null,
            status_current_invalid: false,

            status_current_first: null,
            status_current_secound: null,

            
            token_sms_first: '',
            token_sms_first_invalid: false,
            operador_first: null,
            operador_first_invalid: false,

            token_sms_secound: '',
            token_sms_secound_invalid: false,
            operador_secound: null,
            operador_secound_invalid: false,

            antifraude_finalizada: false,
            loading_page: true,
            loading_verificacao: false,
            loading_cancelamento_transacao: false,
            loading_button: false,
            loading_cartao: true,
            is_token: false,
            cartoes: [],
            ip_localization_client: null,
            modal_localization: false,
            status_bloqueio_comprador: false,
            status_bloqueio_vendedor: false,
            loading_bloqueio_seller: false,
            loading_bloqueio_buyer: false,
            id: getId(),
            prevencao_show: false,
            pagamento_parcial: false,
            pagamento_secundario: null,
            tipo_pagamento: 'prevencao',
            nota_suspeita_seller: '',
            nota_suspeita_buyer: '',
            nome_receita: '',
            data_nascimento: '',
            nascimento: '',
            consulta_view: '',
            consulta_localiza_view: '',

            visible_email_card: false,
            email_card_id: null,
        }

        this.callback_consultar_buyer = this.handleNewConsultaBuyer.bind(this);
        this.callback_consultar_seller = this.handleNewConsultaSeller.bind(this);
        this.callback_consultar_seller_localiza = this.handleNewConsultaSellerLocaliza.bind(this);
    }
    componentDidMount(){
        this.PusherInstancia();
        this.getUser();
        this.getSmsList(getId());
        this.getEmailsList(getId());
        this.getNotas(getId());
    }
    getHitoricoConsultasVendedorLocaliza = () => {
        if (this.state.cpf_cnpj_estabelecimento === undefined ||
            this.state.cpf_cnpj_estabelecimento == false ||
            this.state.cpf_cnpj_estabelecimento == null ||
            this.state.cpf_cnpj_estabelecimento == '') {
            return false;
        }

        var url = ENDPOINT+"prevencao/consultar_historico_localiza/"+this.state.cpf_cnpj_estabelecimento;
        //var url = "http://127.0.0.1:8000/juridico/teste";

        var myHeaders = new Headers();
        
        // console.log(url)
        // return false;

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let response = p.data;
            if (response.data !== undefined && response.data.length > 0) {
                this.setState({hitorico_consultas_localiza: response.data})
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!') 
        })
    }
    getHitoricoConsultasVendedor = () => {
        if (this.state.cpf_cnpj_estabelecimento === undefined ||
            this.state.cpf_cnpj_estabelecimento == false ||
            this.state.cpf_cnpj_estabelecimento == null ||
            this.state.cpf_cnpj_estabelecimento == '') {
            return false;
        }
        var url = ENDPOINT+"prevencao/consultar_historico_mix/"+this.state.cpf_cnpj_estabelecimento;
        // var url = "http://127.0.0.1:8000/juridico/teste";

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let response = p.data;

            if (response.data !== undefined && response.data.length > 0) {
                this.setState({hitorico_consultas_vendedor: response.data})
            }
        })
        .catch((error) => {
            toast.error('Ocorreu um erro no servidor!') 
        })
    }
    getHitoricoConsultasComprador = () => {
        if (this.state.cpf_comprador === undefined ||
            this.state.cpf_comprador == false ||
            this.state.cpf_comprador == null ||
            this.state.cpf_comprador == '') {
            return false;
        }

        var url = ENDPOINT+"prevencao/consultar_historico_mix/"+this.state.cpf_comprador;
        // var url = "http://127.0.0.1:8000/juridico/teste";

        var myHeaders = new Headers();

        myHeaders.append('Authorization', 'Bearer ' + getToken());
        var myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };

        fetch(url, myInit)
        .then(response => response.json())
        .then((p) => {
            let response = p.data;
            if (response.data !== undefined && response.data.length > 0) {
                this.setState({hitorico_consultas_comprador: response.data})
            }
        })
        .catch((error) => {
            console.log(error)
            toast.error('Ocorreu um erro no servidor!');     
        })
    }
    
    PusherInstancia = () => {
        const pusher_instance = new Pusher("2892f3b88d766b1b14d3", {
            cluster: 'us3',
        });

        var response = pusher_instance.subscribe('atualizar_analise_prevencao_'+getId());
        response.bind('atualizar_prevencao_resumo', () => {
            this.getUser();
            this.getSmsList(getId());
            this.getEmailsList(getId());
            this.getNotas(getId());
        });
    }

    onCloseOpenSendEmail = (visible_email_card = false, email_card_id = null) => {
        this.setState({visible_email_card, email_card_id});

        if(visible_email_card === false){
            this.getSmsList(this.state.id);
        }
    }
    
    getCPFReceita = (cpf_comprador) => {
        var url = ENDPOINT+"user/search_cpf_new?cpf="+cpf_comprador;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data.success === true){
                this.setState({
                    nome_receita: data?.data?.nome,
                    data_nascimento: data?.data?.nascimento,
                    situacao_cpf: data?.data?.situacao?.descricao
                })
            }
            else{
                this.setState({situacao_cpf: data.errors})
            }
        })
    }
    getNotas = (id) => {
        var url = ENDPOINT+"prevencao/notas_index/"+id;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({notas_index: data})
        })
    }
    
    bloquearPagamento = () => {
        this.setState({loading_payout: true})
        var url = ENDPOINT+"payout/store";

        var formData = new FormData();
        formData.append('nome', this.state.nome_fatura);
        formData.append('email', this.state.email_vendedor);
        formData.append('documento', this.state.cpf_cnpj_estabelecimento);
        formData.append('valor_bloqueado', this.state.valor);
        formData.append('id_transacao', this.state.id_transacao);
        formData.append('data_venda', moment(this.state.data_pagamento).format('YYYY-MM-DD HH:mm'));
        formData.append('data_liquidacao', moment(this.state.data_pagamento).format('YYYY-MM-DD'));
        formData.append('cliente_notificado_document', this.state.cpf_cnpj_estabelecimento);
        formData.append('novo_bloqueio', true);
        formData.append('tipo_bloqueio', this.state.tipo_pagamento);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                loading_payout: false,
                solicitacao_bloqueio: true
            });
            return data;
        })
        .catch((error) => {
            var data = error.response.data;
            this.setState({
                loading_payout: false
            });
            toast.error(data.errors);
        });

    }

    handleCancelarTransacaoAuto = () => {
        this.setState({loading_cancelamento_transacao: true})
       
        var url = ENDPOINT + "prevencao/cancelar_transacao/" + getId();
        // var url = "http://127.0.0.1:8000/prevencao/cancelar_transacao/" + getId();
        
        var formData = new FormData();

        // console.log(this.state)
        // return false;

        formData.append('descricao', this.state.motivo_cancelamento)
        formData.append('code_analise', 'RPM')


        // console.log(formData)
        // return false;
        // console.log(
        //     getToken(),
        //     url,
        //     this.state.motivo_cancelamento
        // );
        // return false;

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((r) => {
            console.log(r)
            let response = r.data;
            this.setState({loading_cancelamento_transacao: false})

            if (response.data.success) {
                toast.success('Cancelado com sucesso!')
            } else {
                toast.error('Falha ao cancelar transação!')
            }
        })
        .catch((error) => {
            console.log(error)
            var data = error.response.data;
            this.setState({loading_cancelamento_transacao: false})
            toast.error(data.errors);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        })
    }

    getNotasBuyer = (cpf) => {
        var url = ENDPOINT+"prevencao/notas_buyer_index/"+cpf;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({notas_buyer: data})
        })
    }
    getNotasSeller = (id) => {
        var url = ENDPOINT+"prevencao/notas_seller_index/"+id;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({notas_seller: data})
        })
    }
    getSmsList = (id) => {
        var url = ENDPOINT+"prevencao/sms_list/"+id;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({mensagens: data, loading_buyer_messages: false})
        })
    }
    getEmailsList = (id) => {
        var url = ENDPOINT+"prevencao/emails_list/"+id;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({mensagens_emails: data, loading_buyer_messages_email: false})
        })
    }
    getCartoes = (cpf_comprador, produto_servico) => {
        var url = ENDPOINT+"prevencao/consultar_cartoes/"+cpf_comprador+'?produto_servico='+produto_servico+'&pagamento_id='+getId();
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                loading_cartao: false,
                cartoes: data
            })
        })
        .catch(() => {
            this.setState({
                loading_cartao: false,
                cartoes: []
            })
        })

    }
    getTransacoesByIp = (numero_cartao) => {
        this.setState({loading_analises_ip: true})
        var url = ENDPOINT+`prevencao/ip_list/${this.state.ip_client}`;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            
            this.setState({
                transacoes_ip: data,
                view_ip: true,
                loading_analises_ip: false
            });
            this.scrollable_ip();
        })
        .catch((error) => {
            var data = error.response.data;
            this.setState({
                loading_analises_ip: false
            });
            toast.error(data.errors);
        })
    }
    getTransacoesBycard = (numero_cartao) => {
        this.setState({loading_analises_cartao: true})
        var url = ENDPOINT+`payout/listar_pagamentos_por_cartao?page=1&numeros_iniciais=${numero_cartao.substr(0,6)}&numeros_finais=${numero_cartao.substr(-4,4)}`;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                transacoes: data,
                numero_cartao,
                view_cartao: true,
                loading_analises_cartao: false
            });
            this.scrollable();
        })
        .catch((error) => {
            console.log(error)
            var data = error.response.data;
            this.setState({
                loading_analises_cartao: false
            });
            toast.error(data.errors);
        })

    }
    getUser = () => {
        var url = ENDPOINT+"prevencao/consultar_prevencao/"+getId();

        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({
                ...data.data,
                loading_page: false,
                loading_button: false
            })

            let state = this.state;
            state.historico_service_antifraud = (response.data.data.historico_service_antifraud !== undefined && response.data.data.historico_service_antifraud.length > 0) ? response.data.data.historico_service_antifraud : [];
            state.reference_id_service_antifraud = (response.data.data.reference_id_service_antifraud !== undefined) ? response.data.data.reference_id_service_antifraud : null;
            this.setState({...state})

            this.getNotasBuyer(data.data.cpf_comprador);
            this.getNotasSeller(data.data.cpf_cnpj_estabelecimento);
            this.getCartoes(data.data.cpf_comprador, data.data.produto_servico);
            //this.getCPFReceita(data.data.cpf_comprador);
            if(data.data.dados_transacao !== null){
                this.setState({
                    history: data.data.dados_transacao.history
                })
            }
            if(data.data.aqbank_request !== null){
                if(data.data.aqbank_request.finalizado === 0){
                    this.setState({
                        inicio_analise: data.data.aqbank_request.inicio_analise,
                        status: data.data.aqbank_request.status,
                        prevencao_exists: true
                    })
                }
                else{
                    this.setState({
                        antifraude_finalizada: Boolean(data.data.aqbank_request.finalizado),
                        inicio_analise: data.data.aqbank_request.inicio_analise,
                        final_analise: data.data.aqbank_request.final_analise,
                        status: data.data.aqbank_request.status,
                        prevencao_exists: true
                    })

                }
            }

            this.getHitoricoConsultasComprador()
            this.getHitoricoConsultasVendedor()
            this.getHitoricoConsultasVendedorLocaliza()

            return data;
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error);
              console.log(error.response.data);
              //console.log(error.response.status); // status code
              //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            // console.log(error.config);
        })
        // .catch(e => {
        //     console.log(e)
        // })
    }
    iniciarVerificacao = () => {
        this.setState({loading_verificacao: true})
        var url = ENDPOINT+"prevencao/iniciar_verificacao/"+getId();
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                ...data.data,
                loading_verificacao: false
            })
            this.getCartoes(data.data.cpf_comprador, data.data.produto_servico);
            this.getNotas(this.state.id);
            if(data.data.aqbank_request !== null){
                if(data.data.aqbank_request.finalizado === 0){
                    this.setState({
                        inicio_analise: data.data.aqbank_request.inicio_analise,
                        prevencao_exists: true
                    })
                }
                else{
                    this.setState({
                        antifraude_finalizada: Boolean(data.data.aqbank_request.finalizado),
                        inicio_analise: data.data.aqbank_request.inicio_analise,
                        final_analise: data.data.aqbank_request.final_analise,
                        status: data.data.aqbank_request.status,
                        prevencao_exists: true
                    })
                }
            }
            return data;
        })
    }
    iniciar_segunda_verificacao = (id) => {
        this.setState({loading_verificacao_secound: true})
        var url = ENDPOINT+"prevencao/iniciar_segunda_verificacao/"+id;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            toast.error(data.message);

            this.setState({
                loading_verificacao_secound: false
            });
            this.getUser();
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    enviarToken = () => {
        var url = ENDPOINT+"prevencao/enviar_token/"+getId();
        var formData = new FormData();
        formData.append('cpf_id', this.state.operador.value)
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            toast.success('Enviado com sucesso');
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    deletarNota = (id) => {
        this.setState({
            loading_nota_id: id
        });
        
        var url = ENDPOINT+"notas/delete/"+id;
        axios.delete(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            this.getNotas(this.state.id);
            toast.success('Deletado com sucesso');
            this.setState({
                loading_nota_id: null
            });
        })
        .catch((error) => {
            var data = error.response.data;
            toast.error(data.errors);
            this.setState({
                loading_nota_id: null
            });
        })
    }
    registrarPrevencaoMultipla = () => {
        if(this.validar_enviar_multiplo() === true){
            this.setState({loading_button: true});
            var url = ENDPOINT+"prevencao/registrar_prevencao_multipla";
            var formData = new FormData();

            /* var status = '';
            var status_label = '';
    
            if(this.state.status_current_secound === null){
                status = '';
                status_label = '';

                if(this.state.status_current_first.value === 'APG'){
                    status = 'APG';
                    status_label = "Aprovação Gerencial";
                    formData.append('token', this.state.token_sms);
                }
                else{
                    status = this.state.status_current_first.value;
                    status_label = this.state.status_current_first.label;
                }

                formData.append('status', status);
                formData.append('status_label', status_label);
                formData.append('multipla', true);
            }
            else if(this.state.status_current_first.value !== this.state.status_current_secound.value){
                if(this.state.status_current_secound.value === 'SUS'
                || this.state.status_current_secound.value === 'FRD'
                || this.state.status_current_secound.value === 'RMA'
                || this.state.status_current_secound.value === 'RPM'){
                    formData.append('status', this.state.status_current_secound.value);
                    formData.append('status_label', this.state.status_current_secound.label);
                    formData.append('multipla', true);
                }
                else if(this.state.status_current_first.value === 'SUS'
                || this.state.status_current_first.value === 'FRD'
                || this.state.status_current_first.value === 'RMA'
                || this.state.status_current_first.value === 'RPM'){
                    formData.append('status', this.state.status_current_first.value);
                    formData.append('status_label', this.state.status_current_first.label);
                    formData.append('multipla', true);
                }
                else{
                    status = '';
                    status_label = '';

                    if(this.state.status_current_first.value === 'APG' 
                    || this.state.status_current_secound.value === 'APG'){
                        status = 'APG';
                        status_label = "Aprovação Gerencial";
                        formData.append('token', this.state.token_sms);
                    }
                    else{
                        status = this.state.status_current_first.value;
                        status_label = this.state.status_current_first.label;
                    }
    
                    formData.append('status', status);
                    formData.append('status_label', status_label);
                    formData.append('multipla', true);

                }
            }
            else{
                if(this.state.status_current_first.value === 'APG'){
                    formData.append('token', this.state.token_sms);
                }
                else if(this.state.status_current_first.value === 'APE'){
                    formData.append('email', this.state.email);
                }

                formData.append('status', this.state.status_current_first.value);
                formData.append('status_label', this.state.status_current_first.label);
                formData.append('multipla', true);
            } */

            if(this.state.status_current_first !== null){
                if(this.state.status_current_first.value === 'APG'){
                    formData.append('first_token', this.state.token_sms);
                }

                formData.append('first_id', getId());
                formData.append('first_status', this.state.status_current_first.value);
                formData.append('first_status_label', this.state.status_current_first.label);
            }

            if(this.state.status_current_secound !== null){
                if(this.state.status_current_secound.value === 'APG'){
                    formData.append('secound_token', this.state.token_sms);
                }

                formData.append('secound_id', this.state.pagamento_secundario.id);
                formData.append('secound_status', this.state.status_current_secound.value);
                formData.append('secound_status_label', this.state.status_current_secound.label);
            }

            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.getUser();
                //this.setState({loading_button: false});
                toast.success('Enviado com sucesso');
                /* setTimeout(() => window.location.reload(), 2000); */
            })
            .catch((error) => {
                this.setState({loading_button: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
        }
    }
    registrarPrevencao = () => {
        if(this.validar_enviar() === true){
            this.setState({loading_button: true});
            var url = ENDPOINT+"prevencao/registrar_prevencao/"+getId();
            var formData = new FormData();
    
            if(this.state.status_current.value === 'APG'){
                formData.append('token', this.state.token_sms);
            }
            else if(this.state.status_current.value === 'APE'){
                formData.append('email', this.state.email);
            }

            formData.append('status', this.state.status_current.value);
            formData.append('status_label', this.state.status_current.label);
            formData.append('multipla', false);
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.setState({loading_button: false});
                toast.success('Enviado com sucesso');
                setTimeout(() => window.location.reload(), 2000);
            })
            .catch((error) => {
                this.setState({loading_button: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
        }
    }
    reverterPrevencao = () => {
            this.setState({loading_verificacao: true});
            var url = ENDPOINT+"prevencao/reverter_prevencao/"+getId();
            var formData = new FormData();
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.setState({loading_verificacao: false});
                toast.success('Revertida com sucesso');
                window.location.reload();
            })
            .catch((error) => {
                this.setState({loading_verificacao: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
    }
    enviarSMS = () => {
        if(this.state.mensagem.length > 0){
            this.setState({loading_button_message: true});
            var url = ENDPOINT+"prevencao/enviar_sms_buyer/"+getId();
            var formData = new FormData();
            formData.append('mensagem', this.state.mensagem);
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.setState({loading_button_message: false, mensagem: '', isOpenSMSBuyer: false});
                toast.success('Enviado com sucesso');
                this.getSmsList(this.state.id);
            })
            .catch((error) => {
                this.setState({loading_button_message: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
        }
    }
    enviarEmail = () => {
        //if(this.state.mensagem_email.length > 0){
            this.setState({loading_button_message: true});
            var url = ENDPOINT+"prevencao/enviar_email_buyer/"+getId();
            var formData = new FormData();
            formData.append('mensagem',draftToHtml(convertToRaw(this.state.mensagem_email.getCurrentContent())));
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.setState({loading_button_message: false, mensagem_email: EditorState.createEmpty(), isOpenEmailBuyer: false});
                toast.success('Enviado com sucesso');
                this.getEmailsList(this.state.id);
            })
            .catch((error) => {
                this.setState({loading_button_message: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
        //}
    }
    re_enviar_email = (position = 'primeiro') => {
        var id = 0;
        var valor = '0.00';

        if(position === 'primeiro'){
            this.setState({loading_email_reenviar: true});
            id = this.state.cartao_credito.id;
            valor = this.state.valor_validacao_primeiro;
        }
        else{
            this.setState({loading_email_reenviar: true});
            id = this.state.pagamento_secundario.cartao_credito.id;
            valor = this.state.valor_validacao_segundo;
        }
        var url = ENDPOINT+"prevencao/enviar_email_validacao/"+this.state.id;
        var formData = new FormData();
        formData.append('valor', valor);
        formData.append('request_id', id);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            if(position === 'primeiro'){
                this.setState({loading_email_reenviar: false});
            }
            else{
                this.setState({loading_email_reenviar: false});
            }
            this.getUser();
            toast.success('E-mail re-enviado');
        })
        .catch((error) => {
            this.setState({
                loading_primeiro: false,
                loading_segundo: false
            });
            var data = error.response.data;
            toast.error(data.errors);
        })

    }
    debitarCartao = (position = 'primeiro') => {
        var id = 0;
        var valor = '0.00';

        if(position === 'primeiro'){
            this.setState({loading_primeiro: true});
            id = this.state.cartao_credito.id;
            valor = this.state.valor_validacao_primeiro;
        }
        else{
            this.setState({loading_segundo: true});
            id = this.state.pagamento_secundario.cartao_credito.id;
            valor = this.state.valor_validacao_segundo;
        }

        var url = ENDPOINT+"prevencao/registrar_valor/"+id;
        var formData = new FormData();
        formData.append('valor', valor);
        formData.append('request_id', this.state.id);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            if(position === 'primeiro'){
                this.setState({loading_primeiro: false});
            }
            else{
                this.setState({loading_segundo: false});
            }
            this.getUser();
            toast.success('Cobrança feita em cartão');
        })
        .catch((error) => {
            this.setState({
                loading_primeiro: false,
                loading_segundo: false
            });
            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    validarCartao = (position = 'primeiro') => {
        var id = 0;

        if(position === 'primeiro'){
            this.setState({loading_primeiro: true});
            id = this.state.cartao_credito.id;
        }
        else{
            this.setState({loading_segundo: true});
            id = this.state.pagamento_secundario.cartao_credito.id;
        }

        var url = ENDPOINT+"prevencao/validar_cartao/"+id;
        var formData = new FormData();
        formData.append('request_id', this.state.id);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            if(position === 'primeiro'){
                this.setState({loading_primeiro: false, show_primeiro: true});
            }
            else{
                this.setState({loading_segundo: false, show_segundo: true});
            }
            toast.success('Cobrança feita em cartão');
        })
        .catch((error) => {
            this.setState({
                loading_primeiro: false,
                loading_segundo: false
            });
            var data = error.response.data;
            toast.error(data.errors);
        })
    }

    novaNota = () => {
        if(this.state.nota_mensagem.length > 0){
            this.setState({loading_nota: true});
            var url = ENDPOINT+"prevencao/enviar_notas_buyer/"+getId();
            var formData = new FormData();
            formData.append('descricao',this.state.nota_mensagem);
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.setState({loading_nota: false, is_open_nota: false, nota_mensagem: ''});
                toast.success('Nota criada com sucesso');
                this.getNotas(this.state.id);
            })
            .catch((error) => {
                this.setState({loading_nota: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
        }
    }
    novaNotaBuyer = () => {
        if(this.state.nota_mensagem_buyer.length > 0){
            this.setState({loading_nota_buyer: true});
            var url = ENDPOINT+"prevencao/nova_nota_buyer/"+this.state.cpf_comprador;
            var formData = new FormData();
            formData.append('descricao',this.state.nota_mensagem_buyer);
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.getNotasBuyer(this.state.cpf_comprador);
                this.setState({loading_nota_buyer: false, is_open_nota_buyer: false, nota_mensagem_buyer: ''});
                toast.success('Nota criada com sucesso');
                this.getNotas(this.state.id);
            })
            .catch((error) => {
                this.setState({loading_nota: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
        }
    }
    novaNotaSeller = () => {
        if(this.state.nota_mensagem_seller.length > 0){
            this.setState({loading_nota_seller: true});
            var url = ENDPOINT+"prevencao/nova_nota_seller/"+this.state.cpf_cnpj_estabelecimento;
            var formData = new FormData();
            formData.append('descricao',this.state.nota_mensagem_seller);
            axios.post(url, formData, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then(() => {
                this.getNotasSeller(this.state.cpf_cnpj_estabelecimento);
                this.setState({loading_nota_seller: false, is_open_nota_seller: false, nota_mensagem_seller: ''});
                toast.success('Nota criada com sucesso');
                this.getNotas(this.state.id);
            })
            .catch((error) => {
                this.setState({loading_nota_seller: false});
                var data = error.response.data;
                toast.error(data.errors);
            })
        }
    }
    handleInputChange = (e) => {
      const { name, value } = e.target;
      
      this.setState({ [name]: value });
    }
    validarEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    validar_enviar_multiplo = () =>{
        var invalids = 0;
        //Validação de primeiro pagamento
        if(this.state.status_current_first !== null){
            if(this.state.status_current_first.value === 'APG'){
                if(this.state.operador !== null){
                    this.setState({operador_invalid: false })
                }
                else{
                    invalids++;
                    this.setState({operador_invalid: true })
                }

                if(this.state.token_sms.replace(/([^\d])+/gim, '').length > 5){
                    this.setState({token_sms_invalid: false })
                }
                else{
                    invalids++;
                    this.setState({token_sms_invalid: true})
                }
            }
            else if(this.state.status_current_first.value === 'APE'){
                if(this.validarEmail(this.state.email) === true){
                    this.setState({email_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({email_invalid: true})
                }
            }
        }

        //Validação de segundo pagamento
        if(this.state.status_current_secound !== null){
            if(this.state.status_current_secound.value === 'APG'){
                if(this.state.operador !== null){
                    this.setState({operador_invalid: false })
                }
                else{
                    invalids++;
                    this.setState({operador_invalid: true })
                }
    
                if(this.state.token_sms.replace(/([^\d])+/gim, '').length > 5){
                    this.setState({token_sms_invalid: false })
                }
                else{
                    invalids++;
                    this.setState({token_sms_invalid: true})
                }
            }
            else if(this.state.status_current_secound.value === 'APE'){
                if(this.validarEmail(this.state.email) === true){
                    this.setState({email_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({email_invalid: true})
                }
            }
        }
        
        if(invalids > 0){
            return false;
        }

        return true;
    }
    validar_enviar = () =>{
        var invalids = 0;
        //if(this.state.status_current.value === 'APM'){
            if(this.state.status_current.value === 'APG'){
                if(this.state.operador !== null){
                    this.setState({operador_invalid: false })
                }
                else{
                    invalids++;
                    this.setState({operador_invalid: true })
                }

                if(this.state.token_sms.replace(/([^\d])+/gim, '').length > 5){
                    this.setState({token_sms_invalid: false })
                }
                else{
                    invalids++;
                    this.setState({token_sms_invalid: true})
                }
            }
            else if(this.state.status_current.value === 'APE'){
                if(this.validarEmail(this.state.email) === true){
                    this.setState({email_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({email_invalid: true})
                }
            }
        //}

        if(invalids > 0){
            return false;
        }

        return true;
    }

    uniqueID = () => {
        return Math.floor(Math.random() * Date.now())
    }

    getStatusByPayment = (value) => {
        var status_is = value;

        const status = [
            { value: 'EM ANALISE', label: "Análise Pendente"},
            { value: 'ANALISANDO', label: "Análise Iniciada"},
            { value: 'APROVADO AUTOMATICA', label: 'Aprovado Automaticamente'},  
            { value: 'APROVADO MANUALMENTE', label: "Aprovação Manual"},
            { value: 'APG', label: "Aprovação Gerencial"},
            { value: 'APE', label: "Aprovação do Estabelecimento"},
            { value: 'FRAUDE SUSPEITA', label: "Suspeita de Fraude"},
            { value: 'FRAUDE COMFIRMADA', label: "Fraude Confirmada"},
            { value: 'REPROVADO MANUALMENTE', label: "Reprovado Manualmente"},
            { value: 'FRAUDE SEM SUSPEITA', label: 'Reprovado sem contato'},
            { value: 'REPROVADO MANUALMENTE', label: "Reprovado Manualmente"},
        ];
        
        status.forEach(p => {
            if(p.value === value){
                status_is = p.label;
            }
        });

        return status_is;
    }

    getStatus = (value) => {
        if(value === ""){
            var status_is_not_antifraude = '';
            list_status_pagamentos.map((p) => {
                if(p.value === this.state.status){
                    status_is_not_antifraude = p.label
                }
                return "";
            })
            return status_is_not_antifraude;
        }
        else{
            var status_is = '';
            const status = [
                { value: 'INC', label: "Pagamento Incompleto"},
                { value: 'NVO', label: "Análise Pendente"},
                { value: 'AMA', label: "Análise Iniciada"},
                { value: 'APA', label: 'Aprovado Automaticamente'},  
                { value: 'APM', label: "Aprovação Manual"},
                { value: 'APG', label: "Aprovação Gerencial"},
                { value: 'APE', label: "Aprovação do Estabelecimento"},
                { value: 'SUS', label: "Suspeita de Fraude"},
                { value: 'FRD', label: "Fraude Confirmada"},
                { value: 'RMA', label: 'Reprovado Manualmente'},
                { value: 'RPM', label: 'Reprovado sem contato'},
            ];
    
            status.forEach(p => {
                if(p.value === value){
                    status_is = p.label;
                }
            });
    
            return status_is;

        }
    }
    stepPrevencao = (status_current) => {
        switch(status_current.value){
            case 'APG': return <div>
                            <FormGroup>
                                <Select
                                    options={operadores}
                                    placeholder="Operador"
                                    onChange={(operador) => this.setState({operador})}
                                    value={this.state.operador}
                                />
                                <Input hidden invalid={this.state.operador_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            {this.state.operador !== null ?
                                <>
                                    <FormGroup hidden={this.state.operador === null}>
                                        <Button onClick={() => this.enviarToken()}>Enviar/Reenviar Token</Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMasked
                                            mask="999999"
                                            placeholder="Token"
                                            name="token_sms"
                                            value={this.state.token_sms}
                                            invalid={this.state.token_sms_invalid}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </>
                                :
                                <></>
                            }
                        </div>;
            case 'APE': return <div>
                                <FormGroup>
                                    <InputText
                                        placeholder="E-mail"
                                        name="email"
                                        value={this.state.email}
                                        invalid={this.state.email_invalid}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                            </div>
            default: return <></>
        }
    }

    stepPrevencaoFirst = (status_current) => {
        switch(status_current.value){
            case 'APG': return <div>
                            <FormGroup>
                                <Select
                                    options={operadores}
                                    placeholder="Operador"
                                    onChange={(operador_first) => this.setState({operador_first})}
                                    value={this.state.operador_first}
                                />
                                <Input hidden invalid={this.state.operador_first_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            {this.state.operador_first !== null ?
                                <>
                                    <FormGroup hidden={this.state.operador_first === null}>
                                        <Button onClick={() => this.enviarToken()}>Enviar/Reenviar Token</Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMasked
                                            mask="999999"
                                            placeholder="Token"
                                            name="token_sms_first"
                                            value={this.state.token_sms_first}
                                            invalid={this.state.token_sms_first_invalid}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </>
                                :
                                <></>
                            }
                        </div>;
            case 'APE': return <div>
                                <FormGroup>
                                    <InputText
                                        placeholder="E-mail"
                                        name="email"
                                        value={this.state.email}
                                        invalid={this.state.email_invalid}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                            </div>
            default: return <></>
        }
    }

    stepPrevencaoSecound = (status_current) => {
        switch(status_current.value){
            case 'APG': return <div>
                            <FormGroup>
                                <Select
                                    options={operadores}
                                    placeholder="Operador"
                                    onChange={(operador_secound) => this.setState({operador_secound})}
                                    value={this.state.operador_secound}
                                />
                                <Input hidden invalid={this.state.operador_secound_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            {this.state.operador_secound !== null ?
                                <>
                                    <FormGroup hidden={this.state.operador_secound === null}>
                                        <Button onClick={() => this.enviarToken()}>Enviar/Reenviar Token</Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputMasked
                                            mask="999999"
                                            placeholder="Token"
                                            name="token_sms_secound"
                                            value={this.state.token_sms_secound}
                                            invalid={this.state.token_sms_secound_invalid}
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                </>
                                :
                                <></>
                            }
                        </div>;
            case 'APE': return <div>
                                <FormGroup>
                                    <InputText
                                        placeholder="E-mail"
                                        name="email"
                                        value={this.state.email}
                                        invalid={this.state.email_invalid}
                                        onChange={this.handleInputChange}
                                    />
                                </FormGroup>
                            </div>
            default: return <></>
        }
    }

    adicionarMensagem = (value) => {
        this.setState({mensagem: this.state.mensagem+''+value})
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === 'valor_validacao_primeiro' || name === 'valor_validacao_segundo'){
            var valor_current = value;
            valor_current = valor_current.replaceAll('.', '');
            var digits = valor_current.substr(valor_current.length - 2);
            valor_current = valor_current.slice(0, -2);
            valor_current = valor_current + '.' + digits;
            valor_current = parseFloat(valor_current).toString();
            this.setState({ [name]: valor_current });
        }
        else{
            this.setState({ [name]: value });
        }
    }
    handleChangeEmail = (mensagem_email) => {
        this.setState({mensagem_email});
    }
    getNameByNotas = () => {
        var name = ''
        this.state.notas_index.forEach(p => {
            if(p.descricao === 'Verificação de Antifraude Iniciado'){
                name = p.admin !== null ? p.admin.name : 'Sem Informações';
            }
        });

        return name;
    }
    scrollable = () => {
        var element = document.getElementById("card-transaction");
        var pos = element.style.position;
        var top = element.style.top;
        element.style.position = 'relative';
        element.style.top = '-100px';
        element.scrollIntoView({block: "start", behavior: 'smooth'});
        element.style.top = top;
        element.style.position = pos;
    }

    scrollable_ip = () => {
        var element = document.getElementById("ip-transaction");
        var pos = element.style.position;
        var top = element.style.top;
        element.style.position = 'relative';
        element.style.top = '-100px';
        element.scrollIntoView({block: "start", behavior: 'smooth'});
        element.style.top = top;
        element.style.position = pos;
    }
    setAnaliseCartao = (numero_cartao) => {
        this.getTransacoesBycard(numero_cartao);
    }
    bloquearCartao = (data_cartao) => {
        this.setState({loading_card: data_cartao.numero});
        var url = ENDPOINT+"prevencao/bloquear_cartao/"+data_cartao.id;
        var formData = new FormData();
        formData.append('block_admin', !data_cartao.block_admin)
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            this.setState({
                loading_card: null
            });
            toast.success('Cartao bloqueado com sucesso');
            this.getCartoes(this.state.cpf_comprador, this.state.produto_servico)
        })
        .catch((error) => {
            this.setState({
                loading_card: null
            });

            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    bloquearBuyer = () => {
        this.setState({loading_bloqueio_buyer: true});
        var url = ENDPOINT+"prevencao/bloquear_buyer/"+this.state.id;
        var formData = new FormData();
        formData.append('mensagem', this.state.mensagem);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            this.setState({
                loading_bloqueio_buyer: false,
                status_bloqueio_comprador: true
            });
            toast.success('Buyer Bloqueado sucesso');
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                loading_bloqueio_buyer: false
            });

            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    bloquearSeller = () => {
        this.setState({loading_bloqueio_seller: true});
        var url = ENDPOINT+"prevencao/bloquear_seller/"+this.state.id;
        var formData = new FormData();
        formData.append('mensagem', this.state.mensagem);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            this.setState({
                loading_bloqueio_seller: false,
                status_bloqueio_vendedor: true
            });
            toast.success('Seller Bloqueado sucesso');
        })
        .catch((error) => {
            this.setState({
                loading_bloqueio_seller: false
            });
            
            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    removerSuspeitaBuyer = () => {
        this.setState({loading_suspeito_buyer: true});
        var url = ENDPOINT+"prevencao/remover_suspeita_buyer/"+this.state.id;
        var formData = new FormData();
        formData.append('descricao', this.state.nota_suspeita_buyer);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            this.setState({
                nota_suspeita_buyer: '',
                loading_suspeito_buyer: false
            });
            this.getUser();
            toast.success('Marcação de seller suspeito removida');
        })
        .catch((error) => {
            this.setState({
                loading_suspeito_buyer: false
            });
            
            var data = error.response.data;
            toast.error(data.errors);
        })

    }
    removerSuspeitaSeller = () => {
        this.setState({loading_suspeito_seller: true});
        var url = ENDPOINT+"prevencao/remover_suspeita_seller/"+this.state.id;
        var formData = new FormData();
        formData.append('descricao', this.state.nota_suspeita_seller);
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then(() => {
            this.setState({
                nota_suspeita_seller: '',
                loading_suspeito_seller: false
            });
            this.getUser();
            toast.success('Marcação de seller suspeito removida');
        })
        .catch((error) => {
            this.setState({
                loading_suspeito_seller: false
            });
            
            var data = error.response.data;
            toast.error(data.errors);
        })
    }
    hiddenDelete = (id) => {
        /* var permissao = getPermissoes().flag;
        var id_permissao = getPermissoes().id; */

        var permissao = getUser().access_system;
        var id_permissao = getUser().id;

        if(permissao === 'super_user'
         || permissao === 'diretoria'
         || permissao === 'supervisor_setor'){
             return true;
         }
         else if(id_permissao === id){
            return true;
         }

         return false;
    }

    primeira_validacao = () => (<>
        <small>Insira o valor debitado no cartão</small>
            <CurrencyInput 
                inputMode="tel"
                className="form-control" 
                currency="BRL"
                name="valor_validacao_primeiro"
                //value={this.state.valor_validacao_primeiro}
                defaultValue={'0.00'}
                onChange={this.handleInputChange}
            />
            {/* {this.state.mensagens_validacao_cartao.map(e => 
                <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                <div style={{border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px'}}>
                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                </div>
                <br/></small>
            )} */}
            <b>E-mails de validação recebidos do cliente:</b><br/>
            {this.state.mensagens_validacao_cartao.map(e => 
                <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                <div 
                    style={{
                        border: '1px solid #7f7f7f66', 
                        borderRadius: '15px', 
                        padding: '19px',
                        height: '150px',
                        overflow: 'auto'
                    }}>
                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                </div>
                <br/></small>
            )}
            <hr/>
            <ButtonGroup>
                <Button 
                    color="success"
                    disabled={this.state.loading_primeiro}
                    onClick={() => this.debitarCartao()}
                >
                {this.state.loading_primeiro === true ?
                    <ClipLoader color="#fff" size={15}/>
                :
                ''} Confirmar
                </Button>
                <Button 
                    color="dark"
                    disabled={this.state.loading_primeiro}
                    onClick={() => this.validarCartao()}>
                    {this.state.loading_primeiro === true ?
                        <ClipLoader color="#fff" size={15}/>
                    :
                    ''} Debitar novamente</Button>
                    <Button 
                        color="success"
                        disabled={this.state.loading_email_reenviar}
                        onClick={() => this.re_enviar_email()}
                    >
                    {this.state.loading_email_reenviar === true ?
                        <ClipLoader color="#fff" size={15}/>
                    :
                    ''} Re-enviar E-mail
                </Button>
                <Button
                    color="info"
                    onClick={() => this.onCloseOpenSendEmail(true, null)}>Enviar SMS</Button>
            </ButtonGroup>
            <br/>
            <br/>
    </>);

    segundo_pagamento = () => (<>
        <small>Insira o valor debitado no cartão</small>
        <CurrencyInput 
            inputMode="tel"
            className="form-control" 
            currency="BRL"
            name="valor_validacao_segundo"
            //value={this.state.valor_validacao_segundo}
            defaultValue={'0.00'}
            onChange={this.handleInputChange}
        />
        <br/>
        <b>E-mails de validação recebidos do cliente:</b><br/>
        {this.state.pagamento_secundario.mensagens_validacao_cartao.map(e => 
            <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
            <div 
                style={{
                    border: '1px solid #7f7f7f66', 
                    borderRadius: '15px', 
                    padding: '19px',
                    height: '150px',
                    overflow: 'auto'
                }}>
                <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
            </div>
            <br/></small>
        )}
        <br/>
        <ButtonGroup>
            <Button 
                color="success"
                disabled={this.state.loading_segundo}
                onClick={() => this.debitarCartao('segundo')}
            >
            {this.state.loading_segundo === true ?
                <ClipLoader color="#fff" size={15}/>
            :
            ''} Confirmar
            </Button>
            <Button 
                color="dark"
                disabled={this.state.loading_segundo}
                onClick={() => this.validarCartao()}>
                {this.state.loading_segundo === true ?
                    <ClipLoader color="#fff" size={15}/>
                :
                ''} Debitar novamente</Button>
                <Button 
                    color="success"
                    disabled={this.state.loading_email_reenviar}
                    onClick={() => this.re_enviar_email('segundo')}
                >
                {this.state.loading_email_reenviar === true ?
                    <ClipLoader color="#fff" size={15}/>
                :
                ''} Re-enviar E-mail
            </Button>
            <Button
                color="info"
                onClick={() => this.onCloseOpenSendEmail(true, this.state.cartao_credito.id)}>Enviar E-mail</Button>
        </ButtonGroup>
        <br/>
    </>)

    modalConsultaBuyer = (status) => {
        this.setState({callback_modal: this.callback_consultar_buyer})
        this.setState({visible_consulta_comprador: status})
    }
    modalConsultaSeller = (status) => {
        this.setState({callback_modal: this.callback_consultar_seller})
        this.setState({visible_consulta_vendedor: status})
    }   
    modalConsultaSellerLocalizador = (status) => {
        this.setState({callback_modal: this.callback_consultar_seller_localiza})
        this.setState({visible_consulta_localiza_vendedor: status})
    }
    modalConsultaView = (status) => {
        this.setState({visible_consulta_view: status})
    }
    modalConsultaLocalizaView = (status) => {
        this.setState({visible_consulta_localiza_view: status})
    }

    handleNewConsultaSellerLocaliza = () => {
        var url = ENDPOINT+"prevencao/consultar_localiza";
        //var url = "http://127.0.0.1:8000/consultar_localiza";

        if (
            this.state.cpf_cnpj_estabelecimento === undefined || 
            this.state.cpf_cnpj_estabelecimento === '' || 
            this.state.cpf_cnpj_estabelecimento === null
        ) {
            toast.error('Falha ao consultar crédito, aguarde o carregamento dos dados', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return false;
        }

        this.setState({loading_consultar_vendedor_localiza: true})
        
        var formData = new FormData();
        formData.append('documento', this.state.cpf_cnpj_estabelecimento);
        formData.append('id_pagamento', getId());

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({loading_consultar_vendedor_localiza: false})
            if (data.success) {
                toast.success('Consulta de crédito realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedorLocaliza()
            } else {
                toast.error('Falha ao consultar crédito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedorLocaliza()
            }
        })
        .catch((error) => {
            this.setState({loading_consultar_comprador: false})
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }

    handleNewConsultaSeller = () => {
        var url = ENDPOINT+"prevencao/consultar_credito";
        //var url = "http://127.0.0.1:8000/consultar_credito";

        if (
            this.state.cpf_cnpj_estabelecimento === undefined || 
            this.state.cpf_cnpj_estabelecimento === '' || 
            this.state.cpf_cnpj_estabelecimento === null
        ) {
            toast.error('Falha ao consultar crédito, aguarde o carregamento dos dados', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return false;
        }

        this.setState({loading_consultar_vendedor: true})
        
        var formData = new FormData();
        formData.append('documento', this.state.cpf_cnpj_estabelecimento);
        formData.append('id_pagamento', getId());

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;

            this.setState({loading_consultar_vendedor: false})
            if (data.success) {
                toast.success('Consulta de crédito realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedor()
            } else {
                toast.error('Falha ao consultar crédito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasVendedor()
            }
        })
        .catch((error) => {
            this.setState({loading_consultar_comprador: false})
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }

    handleNewConsultaBuyer = () => {
        var url = ENDPOINT+"prevencao/consultar_credito";
        //var url = "http://127.0.0.1:8000/consultar_credito";

        if (this.state.cpf_comprador === undefined || 
            this.state.cpf_comprador === '' || 
            this.state.cpf_comprador === null) {
            toast.error('Falha ao consultar crédito, aguarde o carregamento dos dados', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })

            return false;
        }

        this.setState({loading_consultar_comprador: true})

        var formData = new FormData();
        formData.append('documento', this.state.cpf_comprador);
        formData.append('id_pagamento', getId());

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({loading_consultar_comprador: false})
            if (data.success) {
                toast.success('Consulta de crédito realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                this.getHitoricoConsultasComprador()
            } else {
                toast.error('Falha ao consultar crédito', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }

        })
        .catch((error) => {
            this.setState({loading_consultar_comprador: false})
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status); // status code
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              // console.log(error.config);
        })
    }

    handleOpenModalDetalhado = (consulta) => {
        this.setState({consulta_view: consulta})
        this.modalConsultaView(true)
    }
    
    handleOpenModalLocalizaDetalhado = (consulta) => {
        this.setState({consulta_localiza_view: consulta})
        this.modalConsultaLocalizaView(true)
    }

    render(){
        return(
            <Div key={'div_principal'}>
                <h3>
                    <Button 
                        outline 
                        style={{border: 'none'}}
                        onClick={() => window.location.href = '/prevencao'}
                    >
                        <MdArrowBack style={{padding: 0}}/>
                    </Button>&nbsp;&nbsp;Detalhes do Pagamento</h3>
                    <hr />
                    <Row hidden={!this.state.loading_page}>
                        <Col md={12}>
                            <center>
                                <small><PropagateLoader color={'#52c565'} loading={50} size={15} /></small>
                            </center>
                            <br/>
                        </Col>
                    </Row>

                    <ConsultarBuyer 
                        visible={this.state.visible_consulta_comprador}
                        onClose={() => this.modalConsultaBuyer(false)}
                        callback={this.callback_consultar_buyer}
                        nome_comprador={this.state.nome_comprador}
                        cpf_comprador={this.state.cpf_comprador}
                        />   

                    <ConsultarSeller 
                        visible={this.state.visible_consulta_vendedor}
                        onClose={() => this.modalConsultaSeller(false)}
                        callback={this.callback_consultar_seller}
                        nome_comprador={this.state.nome_seller}
                        cpf_comprador={this.state.cpf_cnpj_estabelecimento}
                        /> 
                    
                    <ConsultarSellerLocaliza
                        visible={this.state.visible_consulta_localiza_vendedor}
                        onClose={() => this.modalConsultaSellerLocalizador(false)}
                        callback={this.callback_consultar_seller_localiza}
                        nome_comprador={this.state.nome_seller}
                        cpf_comprador={this.state.cpf_cnpj_estabelecimento}
                        /> 

                    <ConsultaMixDataView
                        visible={this.state.visible_consulta_view}
                        onClose={() => this.modalConsultaView(false)}
                        callback={this.callback_consultar_seller}
                        consulta_view={this.state.consulta_view}
                        />
                    
                    <ConsultaLocalizaDataView
                        visible={this.state.visible_consulta_localiza_view}
                        onClose={() => this.modalConsultaLocalizaView(false)}
                        callback={this.callback_consultar_seller}
                        consulta_view={this.state.consulta_localiza_view}
                        />

                    <Row>
                        <Col md={6}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>Dados do Comprador</h5><hr/>
                                {this.state.suspeito_comprador === 1 ?
                                    <p>
                                        <b style={{color: 'red'}}><MdWarning style={{padding : 0}}/> 
                                            Buyer com suspeita&nbsp;
                                        </b>
                                        <Button 
                                            color="warning" 
                                            disabled={this.state.loading_suspeito_buyer}
                                            onClick={() => this.setState({show_suspeita_buyer: !this.state.show_suspeita_buyer})}
                                            style={{
                                                padding: 2, 
                                                paddingLeft: 8, 
                                                paddingRight: 8, 
                                                fontSize: '80%'
                                            }}><b>Remover suspeita de buyer</b></Button>
                                            <Collapse isOpen={this.state.show_suspeita_buyer}>
                                                <div>
                                                    <hr/>
                                                    <Input
                                                        name="nota_suspeita_buyer"
                                                        placeholder="Por qual motivo você deseja remover a suspeita de buyer?"
                                                        value={this.state.nota_suspeita_buyer}
                                                        type="textarea"
                                                        style={{
                                                            height: '124px',
                                                            resize: 'none'
                                                        }}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <br/>
                                                <Button 
                                                    disabled={this.state.loading_suspeito_buyer}
                                                    color="success"
                                                    onClick={this.removerSuspeitaBuyer}
                                                >Salvar</Button>
                                            </Collapse>
                                        <br/>
                                        </p>
                                    :
                                    <></>
                                }
                                <p><b>Nome:</b> {this.state.nome_comprador}</p>
                                <p><b>Nome (Receita Federal):</b> {this.state.nome_receita}</p>
                                <p><b>CPF (Receita Federal):</b> <NumberFormat displayType={'text'} value={this.state.cpf_comprador} format={this.state.cpf_comprador.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/> {this.state.situacao_cpf.length > 0 ? ' - '+this.state.situacao_cpf : ''}</p>
                                <p><b>Nascimento (Receita Federal):</b> {maskDate(this.state.data_nascimento)}</p>
                                <p hidden={this.state.idade_comprador == 0}><b>Idade:</b> {this.state.idade_comprador} anos</p>
                                <p><b>Endereco:</b> {this.state.endereco_comprador}</p>
                                <p><b>Telefone:</b> <a href={"tel:"+this.state.telefone_comprador}><NumberFormat displayType={"text"} value={this.state.telefone_comprador} format="(##) #####-####" /></a></p>
                                <p><b>Email: </b>{this.state.email_comprador}</p>
                                
                                {this.state.status_bloqueio_comprador === false ?
                                    <div key="btn_comprador">
                                    <Button color="success" onClick={() => this.bloquearBuyer()} disabled={this.state.loading_bloqueio_buyer}>
                                        {this.state.loading_bloqueio_buyer === true ? 
                                        <ClipLoader color="#fff" size={15}/>
                                        :
                                        <></>
                                        }
                                        Bloquear Buyer
                                    </Button>
                                    <Button 
                                        color="success" 
                                        style={{marginLeft: '1rem'}}
                                        onClick={() => {this.modalConsultaBuyer(true)}} 
                                        disabled={this.state.loading_consultar_comprador}>
                                        {this.state.loading_consultar_comprador === true ? 
                                        <ClipLoader color="#fff" size={15}/>
                                        :
                                        <FaSearch />
                                        }
                                        Consultar Crédito
                                    </Button>
                                    </div>
                                    :
                                    <p style={{color: 'red'}}><b>Buyer bloqueado</b></p>
                                }
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Dados do Estabelecimento</h5><hr/>
                                {this.state.suspeito_vendedor === 1 ?
                                    <p>
                                        <b style={{color: 'red'}}>
                                            <MdWarning style={{padding : 0}}/> Seller com suspeita
                                        </b>&nbsp;
                                        <Button 
                                            color="warning" 
                                            disabled={this.state.loading_suspeito_seller}
                                            onClick={() => this.setState({show_suspeita_seller: !this.state.show_suspeita_seller})}
                                            style={{
                                                padding: 2, 
                                                paddingLeft: 8, 
                                                paddingRight: 8, 
                                                fontSize: '80%'
                                            }}><b>Remover suspeita de seller</b></Button>
                                            <Collapse isOpen={this.state.show_suspeita_seller}>
                                                <div>
                                                    <hr/>
                                                    <Input
                                                        name="nota_suspeita_seller"
                                                        placeholder="Por qual motivo você deseja remover a suspeita de seller?"
                                                        value={this.state.nota_suspeita_seller}
                                                        type="textarea"
                                                        style={{
                                                            height: '124px',
                                                            resize: 'none'
                                                        }}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <br/>
                                                <Button 
                                                    disabled={this.state.loading_suspeito_seller}
                                                    color="success"
                                                    onClick={this.removerSuspeitaSeller}
                                                >Salvar</Button>
                                            </Collapse>
                                    </p>
                                    :
                                    <>
                                    </>
                                }
                                <p><b>Nome do Seller:</b> {this.state.nome_seller}</p>
                                <p><b>Nome da Fatura:</b> {this.state.nome_fatura}</p>
                                <p><b>Atividade:</b> {this.state.atividade_estabelecimento}</p>
                                <p><b>Data de cadastro:</b> {moment(this.state.cadastro_data_estabelecimento).format('DD/MM/YYYY HH:mm')}</p>
                                <p><b>CPF/CNPJ:</b> <NumberFormat displayType={'text'} value={this.state.cpf_cnpj_estabelecimento} format={this.state.cpf_cnpj_estabelecimento.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/></p>
                                <p><b>Endereço:</b> {this.state.endereco_estabelecimento}</p>
                                <p><b>Plano de Assinatura:</b> {this.state.plano_assinatura}</p>
                                {this.state.status_bloqueio_vendedor === false ?
                                    <div key="btns_div_seller">
                                    <Button 
                                        color="success"  
                                        onClick={() => this.bloquearSeller()} 
                                        disabled={this.state.loading_bloqueio_seller}
                                    >
                                        {this.state.loading_bloqueio_seller === true ? 
                                        <ClipLoader color="#fff" size={15}/>
                                        :
                                        <></>}
                                        Bloquear Seller
                                    </Button>

                                        <Button 
                                            color="success" 
                                            style={{marginLeft: '1rem'}}
                                            onClick={() => {this.modalConsultaSeller(true)}} 
                                            disabled={this.state.loading_consultar_vendedor}>
                                            {this.state.loading_consultar_vendedor === true ? 
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            <FaSearch />
                                            }
                                            Consultar Crédito
                                        </Button>
                                        </div>
                                    :
                                    <p style={{color: 'red'}}><b>Seller bloqueado</b></p>
                                }
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <div style={{
                                overflow: 'auto',
                                height: this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ? '855px' : 'auto',
                                padding: '15px', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5
                                }}>
                                <h5>Consultas de crédito</h5><hr/>
                                {this.state.hitorico_consultas_comprador !== undefined && this.state.hitorico_consultas_comprador.length > 0 ?
                                    
                                    this.state.hitorico_consultas_comprador.map((consulta, i) => {

                                        if (i == 0) {
                                            return (
                                            <div key={'div_historico_comprador_'+i}>
                                                    <Button 
                                                        disabled={this.state.loading_analises_ip} 
                                                        onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                        style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                        color="info">
                                                        {this.state.loading_analises_ip === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            <FaEye />
                                                        } Ver Detalhado
                                                    </Button>
                                                <p>
                                                    <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    <div key={"score_bar"+consulta.id} style={{position: 'relative'}}>
                                                        <ReactScoreIndicator 
                                                            stepsColors={[
                                                                '#d12000',
                                                                '#ed8d00',
                                                                '#f1bc00',
                                                                '#84c42b',
                                                                '#84c42b',
                                                                '#84c42b',
                                                                '#3da940',
                                                                '#3da940',
                                                              ]}
                                                            lineWidth={30}
                                                            value={consulta.payload.resposta.ocorrencias.score.score.pontos}
                                                            maxValue={1000}
                                                            />
                                                        <span style={{
                                                            position: 'absolute',
                                                            top: '2rem', 
                                                            width: '100%',
                                                            fontSize: '55px',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold'
                                                        }}>
                                                            {consulta.payload.resposta.ocorrencias.score.score.classe}
                                                        </span>
                                                        <h5 style={{textAlign: 'center'}}>{consulta.payload.resposta.ocorrencias.score.score.faixa.titulo}</h5>
                                                        <p>{consulta.payload.resposta.ocorrencias.score.score.faixa.descricao}</p>
                                                    </div>
                                                    <p>
                                                        <b>Renda Presumida:</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.rendaPresumida)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.debitos.sumQuantidade} Débito{consulta.payload.resposta.resumos.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.debitos.sumValorTotal)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.protestos.sumQuantidade} Protesto{consulta.payload.resposta.resumos.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.protestos.sumValorTotal)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    {consulta.payload.resposta.resumos.acoes ?
                                                        <p>
                                                            <b>{consulta.payload.resposta.resumos.acoes.sumQuantidade} Aç{consulta.payload.resposta.resumos.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                            <NumberFormat 
                                                                prefix="R$ " 
                                                                displayType="text" 
                                                                value={parseFloat(consulta.payload.resposta.resumos.acoes.sumValorTotal)} 
                                                                decimalSeparator=","/>
                                                        </p>
                                                        :
                                                        <></>
                                                    }
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.cheques.sumQuantidade} Cheque{consulta.payload.resposta.resumos.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                    </p>
                                                </Card>

                                                <hr />
                                                
                                            </div>
                                            )
                                        } else {
                                            return (
                                                <div key={'div_historico_comprador_'+i}>
                                                        <Button 
                                                            disabled={this.state.loading_analises_ip} 
                                                            onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                            style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                            color="info">
                                                            {this.state.loading_analises_ip === true ?
                                                                <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                <FaEye />
                                                            } Ver Detalhado
                                                        </Button>
                                                    <p>
                                                        <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                    </p>

                                                    <hr />
                                                </div>
                                            )
                                        }
                                    })
                                :
                                    <p>Nenhuma consulta encontrada</p>
                                }
                            </div>
                        </Col>
                        <Col md={6}>
                            <div style={{
                                overflow: 'auto',
                                height: this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ? '855px' : 'auto', 
                                padding: '15px', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5}}>
                                <h5>Consultas de localiza</h5><hr/>
                                {this.state.hitorico_consultas_vendedor !== undefined && this.state.hitorico_consultas_vendedor.length > 0 ?
                                    
                                    this.state.hitorico_consultas_vendedor.map((consulta, i) => {

                                        if (i == 0) {
                                            return (
                                            <div key={'div_historico_vendedor_'+i}>
                                                    <Button 
                                                        disabled={this.state.loading_analises_ip} 
                                                        onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                        style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                        color="info">
                                                        {this.state.loading_analises_ip === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            <FaEye />
                                                        } Ver Detalhado
                                                    </Button>
                                                <p>
                                                    <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    <div key={"score_bar"+consulta.id} style={{position: 'relative'}}>
                                                        <ReactScoreIndicator 
                                                            stepsColors={[
                                                                '#d12000',
                                                                '#ed8d00',
                                                                '#f1bc00',
                                                                '#84c42b',
                                                                '#84c42b',
                                                                '#84c42b',
                                                                '#3da940',
                                                                '#3da940',
                                                              ]}
                                                            lineWidth={30}
                                                            value={consulta.payload.resposta.ocorrencias.score.score.pontos}
                                                            maxValue={1000}
                                                            />
                                                        <span style={{
                                                            position: 'absolute',
                                                            top: '2rem', 
                                                            width: '100%',
                                                            fontSize: '55px',
                                                            textAlign: 'center',
                                                            fontWeight: 'bold'
                                                        }}>
                                                            {consulta.payload.resposta.ocorrencias.score.score.classe}
                                                        </span>
                                                        <h5 style={{textAlign: 'center'}}>{consulta.payload.resposta.ocorrencias.score.score.faixa.titulo}</h5>
                                                        <p>{consulta.payload.resposta.ocorrencias.score.score.faixa.descricao}</p>
                                                    </div>
                                                    <p>
                                                        <b>Renda Presumida:</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.rendaPresumida)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.debitos.sumQuantidade} Débito{consulta.payload.resposta.resumos.debitos.sumQuantidade > 1 ? 's' : ''}:</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.debitos.sumValorTotal)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.protestos.sumQuantidade} Protesto{consulta.payload.resposta.resumos.protestos.sumQuantidade > 1 ? 's' : ''} :</b>&nbsp;                                                         
                                                        <NumberFormat 
                                                            prefix="R$ " 
                                                            displayType="text" 
                                                            value={parseFloat(consulta.payload.resposta.resumos.protestos.sumValorTotal)} 
                                                            decimalSeparator=","/>
                                                    </p>
                                                    {consulta.payload.resposta.resumos.acoes ?
                                                        <p>
                                                            <b>{consulta.payload.resposta.resumos.acoes.sumQuantidade} Aç{consulta.payload.resposta.resumos.acoes.sumQuantidade > 1 ? 'ões' : 'ão'}:</b>&nbsp;                                                         
                                                            <NumberFormat 
                                                                prefix="R$ " 
                                                                displayType="text" 
                                                                value={parseFloat(consulta.payload.resposta.resumos.acoes.sumValorTotal)} 
                                                                decimalSeparator=","/>
                                                        </p>
                                                        :
                                                        <></>
                                                    }
                                                    <p>
                                                        <b>{consulta.payload.resposta.resumos.cheques.sumQuantidade} Cheque{consulta.payload.resposta.resumos.cheques.sumQuantidade > 1 ? 's' : ''}</b>&nbsp;                                                         
                                                    </p>
                                                </Card>

                                                <hr />
                                                
                                            </div>
                                            )
                                        } else {
                                            return (
                                                <div key={'div_historico_vendedor_'+i}>
                                                        <Button 
                                                            disabled={this.state.loading_analises_ip} 
                                                            onClick={(event) => {this.handleOpenModalDetalhado(consulta)}} 
                                                            style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                            color="info">
                                                            {this.state.loading_analises_ip === true ?
                                                                <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                <FaEye />
                                                            } Ver Detalhado
                                                        </Button>
                                                    <p>
                                                        <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                    </p>

                                                    <hr />
                                                </div>
                                            )
                                        }
                                    })
                                :
                                    <p>Nenhuma consulta encontrada</p>
                                }
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={6}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>Histórico de disputas/chargebacks dos cartões do buyer</h5>
                            <hr/>
                                {this.state.transferencias_cartoes_buyer.map(p => {
                                    return <div>
                                                <p><b>ID da Transação: </b> {p.api_transacao_id}</p>
                                                <p><b>Data do Pagamento:</b> {moment(p.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                <p><b>Valor:</b> {parseFloat(p.valor).toFixed(2)}</p>
                                                <p><b>Status: </b>{p.status_pagamento}</p>
                                            <hr/>
                                    </div>
                                })}
                                {this.state.transferencias_cartoes_buyer.length === 0 ? 'Nenhum chargeback encontrado' : ''}
                            </div>
                            <br/>
                        </Col>
                        <Col md={6}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>Histórico de disputas/chargebacks do estabelecimento</h5>
                            <hr/>
                                {this.state.historico_charge_back_seller.map(p => {
                                    return <div>
                                                <p><b>ID da Transação: </b> {p.id}</p>
                                                <p><b>Data do Pagamento:</b> {moment(p.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                <p><b>Valor:</b> R$ {parseFloat(p.amount).toFixed(2)}</p>
                                                <p><b>Descrição: </b>{p.description}</p>
                                            <hr/>
                                    </div>
                                })}
                                {this.state.historico_charge_back_seller.length === 0 ? 'Nenhum chargeback encontrado' : ''}
                            </div>
                            <br/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Histórico Sistema KOIN: <b>{this.state.reference_id_service_antifraud !== undefined ? this.state.reference_id_service_antifraud : ''}</b></h5>
                                <hr/>
                                {this.state.historico_service_antifraud !== undefined && this.state.historico_service_antifraud.length > 0 ?
                                    this.state.historico_service_antifraud.map((p, i) => {
                                        return <div key={p.id}>
                                                <Row>
                                                    <Col md={6}>
                                                        <label><b>Status</b></label>
                                                        <p>{p.status}</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <label><b>Data de envio</b></label>
                                                        <p>{moment(p.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                    </Col>
                                                    {/* <Col md={4}>
                                                        <label><b>Processo</b></label>
                                                        <p>{p.processo}</p>
                                                    </Col> */}
                                                    {/* <Col md={3}>
                                                        <label><b>ID de transação</b></label>
                                                        <p>{p.id}</p>
                                                    </Col> */}
                                                </Row>
                                                {/* <Row>
                                                    <Col md={4}>
                                                        <label><b>Descrição</b></label>
                                                        {p.descricao !== undefined && p.descricao !== null ?
                                                            <p><b>Descrição:</b> {p.descricao}</p>
                                                        :
                                                            <></>
                                                        }
                                                    </Col>
                                                </Row> */}
{/* 
                                                    <p><b>ID de transação:</b> {p.id}</p>
                                                    <p><b>Processo:</b> {p.processo}</p>
                                                    <p><b>Data de envio:</b> {moment(p.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                    <p><b>Status:</b> {p.status}</p>
                                                    <p><b>Valor:</b> R$ {parseFloat(p.valor).toFixed(2).replace('.', ',')}</p>
                                                    {p.descricao !== undefined && p.descricao !== null ?
                                                        <p><b>Descrição:</b> {p.descricao}</p>
                                                    :
                                                        <></>
                                                    } */}
                                                    
                                                <hr/>
                                        </div>
                                    })
                                    //this.state.historico_service_antifraud.length === 0 ? 'Nenhum hitórico encontrado' : ''             
                                :
                                'Nenhum hitórico encontrado'
                                }
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Histórico de Reprovações</h5>
                                <hr/>
                                {this.state.historico_reprovacoes.map(p => {
                                    return <div>
                                                <p><b>ID de transação:</b> {p.api_transacao}</p>
                                                <p><b>Data do Pagamento:</b> {moment(p.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                <p><b>Status:</b> {p.status}</p>
                                                <p><b>Status da Analise:</b> {get_status_antifraude(p.antifraude_code)}</p>
                                                <p><b>Valor:</b> R$ {parseFloat(p.valor).toFixed(2).replace('.', ',')}</p>
                                            <hr/>
                                    </div>
                                })}
                                {this.state.historico_reprovacoes.length === 0 ? 'Nenhum pagamento encontrado' : ''}
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div 
                                style={{
                                    padding: '15px', 
                                    border: '1px solid #dee2e6', 
                                    borderRadius: 5
                                }}>
                                <h5>Histórico de Transferências (últimos 10 dias)</h5>
                                <hr/>
                                <div style={{
                                    maxHeight: '90vh',
                                    overflow: 'auto'
                                }}>
                                    {this.state.transferencias_seller.map(p => {
                                        return <div>
                                                    <p><b>ID de Transferência:</b> {p.id}</p>
                                                    <p><b>Status:</b> {status_transacao(p.status)}</p>
                                                    <p><b>Valor:</b> R$ {parseFloat(p.amount).toFixed(2).replace('.', ',')}</p>
                                                    <p><b>Data do Pagamento:</b> {moment(p.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                                <hr/>
                                        </div>
                                    })}
                                    {this.state.transferencias_seller.length === 0 ? 'Nenhuma transferência encontrado' : ''}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Sellers com mesmo Endereço (Identificado pelo CEP)</h5>
                                <hr/>
                                {this.state.sellers_mesmo_endereco.map(p => {
                                    return <div>
                                                <p><b>Nome:</b> {p.nome}</p>
                                                <p><b>Documento (CPF/CNPJ):</b> <NumberFormat displayType={'text'} value={p.documento} format={p.documento.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/></p>
                                                <p><b>Endereço:</b> {p.endereco}</p>
                                                <p><b>CEP:</b> {p.cep}</p>
                                                <p><b>Criação do Seller: </b>{moment(p.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                            <hr/>
                                    </div>
                                })}
                                {this.state.sellers_mesmo_endereco.length === 0 ? 'Nenhum seller encontrado' : ''}
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Buyers com mesmo Endereço (Identificado pelo CEP)</h5>
                                <hr/>
                                {this.state.buyers_mesmo_endereco.map(p => {
                                    return <div>
                                                <p><b>Nome:</b> {p.nome}</p>
                                                <p><b>Documento (CPF/CNPJ):</b> <NumberFormat displayType={'text'} value={p.documento} format={p.documento.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/></p>
                                                <p><b>Endereço:</b> {p.endereco}</p>
                                                <p><b>CEP:</b> {p.cep}</p>
                                                <p><b>Criação do Buyer: </b>{moment(p.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                            <hr/>
                                    </div>
                                })}
                                {this.state.buyers_mesmo_endereco.length === 0 ? 'Nenhum buyer encontrado' : ''}
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h5>Histórico de vendas</h5>
                                    <hr style={{ marginBottom: 0 }}/>
                                    <DataTable
                                        noHeader={true}
                                        columns={colunas_pagamentos}
                                        data={this.state.history}
                                        noDataComponent={<MessageNoTable message="Nenhum histórico encontrado" />}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Pagamento usado</h5><hr/>
                            {this.state.ip_transacoes_count > 1 ?
                                <p>
                                    <b style={{color: 'red'}}><MdWarning style={{padding : 0}}/> Existe transações com o mesmo IP ({this.state.ip_client})</b>
                                    <br/>
                                    <Button disabled={this.state.loading_analises_ip} onClick={() => this.getTransacoesByIp()} style={{margunTop: '7px'}} color="warning">
                                        {this.state.loading_analises_ip === true ?
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            ''} Ver Transações do IP
                                    </Button>
                                </p>
                                :
                                <></>
                            }
                            {this.state.solicitacao_bloqueio === true ?
                                <p><b style={{color: 'red'}}>Bloqueio Solicitado</b></p>
                            :
                            <>
                                <p>
                                    <Button
                                        color="warning"
                                        disabled={this.state.loading_payout}
                                        onClick={() => this.setState({prevencao_show: !this.state.prevencao_show})}>
                                        Bloquear Pagamento&nbsp;
                                        {this.state.loading_payout === true ?
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            <>
                                                <AiFillCaretDown hidden={this.state.prevencao_show}/>
                                                <AiFillCaretUp hidden={!this.state.prevencao_show}/>
                                            </>
                                        }
                                    </Button>
                                    <Collapse isOpen={this.state.prevencao_show}>
                                        <hr/>
                                        <div>
                                            <input 
                                                type="radio" 
                                                name="block-type"
                                                checked={this.state.tipo_pagamento === 'prevencao'}
                                                onChange={() => this.setState({tipo_pagamento: 'prevencao'})}
                                            /> Prevenção &nbsp;
                                            <input 
                                                type="radio" 
                                                name="block-type"
                                                checked={this.state.tipo_pagamento === 'cartao_proprio'}
                                                onChange={() => this.setState({tipo_pagamento: 'cartao_proprio'})}
                                            /> Cartão Proprio
                                        </div>
                                        <hr/>
                                        <Button 
                                            onClick={() => this.bloquearPagamento()} 
                                            disabled={this.state.loading_payout}
                                            color="warning">
                                            {this.state.loading_payout === true ?
                                                <ClipLoader color="#fff" size={15}/>
                                                :
                                                ''} Confirmar Bloqueio
                                        </Button>
                                    </Collapse>
                                </p>
                            </>}

                            <p><b><u>{this.state.pagamento_parcial === false ? 'Pagamento Integral' : 'Multiplos Pagamentos'}</u></b></p>
                            <p><b>Valor Bruto:</b> R$ {parseFloat(this.state.valor).toFixed(2).replace('.', ',')}</p>
                            {this.state.pagamento_parcial === true ? 
                            <>
                                <small><b><u>Primeiro pagamento</u></b></small><br/>
                                <Button 
                                    color="dark" 
                                    style={{
                                        margin: 15
                                    }}
                                    onClick={() => this.setState({primeiro_pagamento_show: !this.state.primeiro_pagamento_show})}>
                                        <DropdownIcon dropdown={this.state.primeiro_pagamento_show} />&nbsp;
                                        Visualizar Primeiro Pagamento
                                </Button><br/>
                            </> 
                            : 
                            <><Button color="dark" onClick={() => this.setState({primeiro_pagamento_show: !this.state.primeiro_pagamento_show})}>Visualizar Pagamento</Button><br/></>}
                            
                            <Collapse isOpen={this.state.primeiro_pagamento_show}>
                                <p><b>ID da transação:</b> {this.state.id_transacao}</p>
                                <p><b>Forma de Pagamento:</b> {payment(this.state.forma_pagamento)}</p>
                                <p><b>Data de Pagamento:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                {this.state.pagamento_parcial === true ? <>
                                    <p><b>Valor:</b> R$ {parseFloat(this.state.valor_primario).toFixed(2).replace('.', ',')} / {this.state.numero_parcelas}x</p>
                                </> 
                                : 
                                <>
                                    <p><b>Valor:</b> R$ {parseFloat(this.state.valor).toFixed(2).replace('.', ',')} / {this.state.numero_parcelas}x</p>
                                </>
                                }
                                {this.state.cartao_credito !== null ?
                                    <div>
                                            <p><b>Tipo de cartão:</b> {this.state.cartao_credito.tipo_cartao.toUpperCase()}</p>
                                            <p><b>Nome no cartão:</b> {this.state.cartao_credito.nome_proprietario}</p>
                                            <p><b>Número do cartão:</b> {this.state.cartao_credito.numero}</p>
                                            <p><b>Validade:</b> {this.state.cartao_credito.validade_mes}/{this.state.cartao_credito.validade_ano}</p>
                                            <p><b>Apelido do cartão:</b> {this.state.apelido_cartao}</p>
                                            <p><b>Bandeira:</b> {this.state.cartao_credito.bandeira !== null ? this.state.cartao_credito.bandeira.toUpperCase() : 'Sem informações'}</p>
                                            {this.state.cartao_credito.validate_card == 1 ?
                                                <>
                                                    <p style={{color: 'green'}}><b>Cartão Validado</b></p>
                                                    <b>E-mails de validação recebidos do cliente:</b><br/>
                                                    {this.state.mensagens_validacao_cartao.map(e => 
                                                        <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                                                        <div 
                                                            style={{
                                                                border: '1px solid #7f7f7f66', 
                                                                borderRadius: '15px', 
                                                                padding: '19px',
                                                                height: '150px',
                                                                overflow: 'auto'
                                                            }}>
                                                            <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                                                        </div>
                                                        <br/></small>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    {this.state.cartao_credito.pagamento_validacao_card === 0  || this.state.cartao_credito.validate_card == false?
                                                        <>  
                                                            <Button 
                                                                color="dark" 
                                                                style={{marginBottom: 10}} 
                                                                hidden={this.state.show_primeiro}
                                                                disabled={this.state.loading_primeiro}
                                                                onClick={() => this.validarCartao()}
                                                            >
                                                                {this.state.loading_primeiro === true ?
                                                                    <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                ''} Validar cartão
                                                            </Button>
                                                            <Collapse isOpen={this.state.show_primeiro} style={{marginBottom: 10}} >
                                                                {this.primeira_validacao()}
                                                            </Collapse>
                                                        </>
                                                    :
                                                    <>
                                                        {this.primeira_validacao()}
                                                </>}
                                            </>}
                                        {/* */} 
                                    </div>
                                    :
                                    <></>
                                }
                                <p><b>Nome do Produto: </b> {this.state.produto_nome}</p>
                                <p><b>Navegador:</b> {this.state.browser.length > 0 ? this.state.browser : 'Sem informações'}</p>
                                <p><b>Aparelho utilizado:</b> {this.state.device.length > 0 ? this.state.device : 'Sem informações'}</p>
                                <p><b>Endereço de IP:</b> {this.state.ip_client.length > 0 ? this.state.ip_client : 'Sem informações'}</p>
                            </Collapse>
                            {this.state.pagamento_parcial === true && this.state.aqbank_request_primario !== null ?
                                <>
                                    {this.state.prevencao_exists === true && this.state.antifraude_finalizada === false ?
                                        <>
                                            <br/>
                                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                            <h5>Análise Antifraude do primeiro pagamento</h5><hr/>
                                                {this.state.aqbank_request_primario.finalizado == 0 ?
                                                    <>
                                                        <p><b>Status:</b> {this.state.aqbank_request_primario.status}</p>
                                                        <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                        <p><b>Inicio de Análise:</b> {moment(this.state.aqbank_request_primario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                        - 
                                                        {this.state.aqbank_request_primario.responsavel_analise === null ? '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name}</p>
                                                        <FormGroup>
                                                            <Select 
                                                                placeholder="Status" 
                                                                options={list_prevencao_status}
                                                                onChange={(status_current_first) => this.setState({status_current_first})}
                                                                value={this.state.status_current_first}
                                                            />
                                                        </FormGroup>
                                                        <div>
                                                            {this.state.status_current_first !== null ?
                                                                this.stepPrevencao(this.state.status_current_first)
                                                                :
                                                                <></>}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <p><b>Status:</b> {this.getStatus(this.state.aqbank_request_primario.status)}</p>
                                                        {/* <p><b>Chegada em Fila:</b> {this.state.chegada_fila}</p> */}
                                                        <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                        <p><b>Inicio de Análise:</b> {moment(this.state.aqbank_request_primario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                        &nbsp;-&nbsp;
                                                        {this.state.aqbank_request_primario.responsavel_inicio_analise === null ? 
                                                            '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name
                                                        }</p>
                                                        <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_primario.final_analise).format('DD/MM/YYYY HH:mm')} - {this.state.aqbank_request_primario.responsavel_analise !== null ? this.state.aqbank_request_primario.responsavel_analise.name : ''}</p>
                                                    </>
                                                }
                                                {/* <div>
                                                    {this.state.status_current_first !== null ?
                                                    this.stepPrevencao(this.state.status_current_first)
                                                    :
                                                    <></>}
                                                </div> */}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <p><b>Status do pagamento: </b> {this.getStatus(this.state.antifraude_code)}</p>
                                            {this.state.aqbank_request_primario !== null ? 
                                                <>
                                                    <p>
                                                        <b>Inicio de Análise:</b> {moment(this.state.aqbank_request_primario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                        &nbsp;-&nbsp;
                                                        {this.state.aqbank_request_primario.responsavel_inicio_analise === null ? 
                                                            '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name
                                                        }
                                                    </p>
                                                    <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_primario.final_analise).format('DD/MM/YYYY HH:mm')}
                                                        &nbsp;-&nbsp;
                                                        {this.state.aqbank_request_primario.responsavel_inicio_analise === null ? 
                                                            '' : this.state.aqbank_request_primario.responsavel_inicio_analise.name
                                                        }
                                                    </p>

                                                </>
                                            :
                                            <></>}
                                        </>
                                    }
                                </>
                            :<></>}
                            {this.state.pagamento_secundario !== null ? <>
                                <hr/>
                                <small><b><u>Segundo pagamento</u></b></small><br/>
                                <Button 
                                    color="dark" 
                                    style={{
                                        margin: 15
                                    }}
                                    onClick={() => this.setState({segundo_pagamento_show: !this.state.segundo_pagamento_show})}>
                                        <DropdownIcon dropdown={this.state.segundo_pagamento_show} />&nbsp;
                                        Visualizar Segundo Pagamento
                                </Button>
                                <br/>
                                <Collapse isOpen={this.state.segundo_pagamento_show}>
                                    <p><b>ID da transação:</b> {this.state.pagamento_secundario.id_transacao}</p>
                                    <p><b>Forma de Pagamento:</b> {payment(this.state.pagamento_secundario.forma_pagamento)}</p>
                                    <p><b>Data de Pagamento:</b> {moment(this.state.pagamento_secundario.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                    <p><b>Valor:</b> R$ {parseFloat(this.state.pagamento_secundario.valor).toFixed(2).replace('.', ',')} / {this.state.pagamento_secundario.numero_parcelas}x</p>
                                    {this.state.pagamento_secundario.cartao_credito !== null ?
                                        <div>
                                            <p><b>Tipo de cartão:</b> {this.state.pagamento_secundario.cartao_credito.tipo_cartao.toUpperCase()}</p>
                                            <p><b>Nome no cartão:</b> {this.state.pagamento_secundario.cartao_credito.nome_proprietario}</p>
                                            <p><b>Número do cartão:</b> {this.state.pagamento_secundario.cartao_credito.numero}</p>
                                            <p><b>Validade:</b> {this.state.pagamento_secundario.cartao_credito.validade_mes}/{this.state.pagamento_secundario.cartao_credito.validade_ano}</p>
                                            <p><b>Apelido do cartão:</b> {this.state.pagamento_secundario.apelido_cartao}</p>
                                            <p><b>Bandeira:</b> {this.state.pagamento_secundario.cartao_credito.bandeira !== null ? this.state.pagamento_secundario.cartao_credito.bandeira.toUpperCase() : 'Sem informações'}</p>
                                            {this.state.pagamento_secundario.cartao_credito.validate_card === 1 ?
                                                <>
                                                    <p style={{color: 'green'}}><b>Cartão Validado</b></p>
                                                    <b>E-mails de validação recebidos do cliente:</b><br/>
                                                    {this.state.pagamento_secundario.mensagens_validacao_cartao.map(e => 
                                                        <small><b>{moment(e.created_at).format('DD/MM/YYYY HH:mm')}: </b>
                                                        <div 
                                                            style={{
                                                                border: '1px solid #7f7f7f66', 
                                                                borderRadius: '15px', 
                                                                padding: '19px',
                                                                height: '150px',
                                                                overflow: 'auto'
                                                            }}>
                                                            <div dangerouslySetInnerHTML={{__html: sanitizeHtml(e.descricao)}}/>
                                                        </div>
                                                        <br/></small>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    {this.state.pagamento_secundario.cartao_credito.pagamento_validacao_card === 0 || this.state.pagamento_secundario.cartao_credito.validate_card == false ?
                                                        <>
                                                            <Button 
                                                                color="dark" 
                                                                style={{marginBottom: 10}} 
                                                                hidden={this.state.show_segundo}
                                                                disabled={this.state.loading_segundo}
                                                                onClick={() => this.validarCartao('segundo')}
                                                            >
                                                                {this.state.loading_segundo === true ?
                                                                    <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                ''} Validar cartão
                                                            </Button>
                                                            <Collapse isOpen={this.state.show_segundo} style={{marginBottom: 10}} >
                                                                {this.segundo_pagamento()}
                                                            </Collapse>
                                                        </>
                                                    :
                                                    <>
                                                        {this.segundo_pagamento()}
                                                    </>}
                                                </>}
                                        </div>
                                        :
                                        <></>
                                    }
                                    <p><b>Navegador:</b> {this.state.pagamento_secundario.browser.length > 0 ? this.state.pagamento_secundario.browser : 'Sem informações'}</p>
                                    <p><b>Aparelho utilizado:</b> {this.state.pagamento_secundario.device.length > 0 ? this.state.pagamento_secundario.device : 'Sem informações'}</p>
                                    <p><b>Endereço de IP:</b> {this.state.pagamento_secundario.ip_client.length > 0 ? this.state.pagamento_secundario.ip_client : 'Sem informações'}</p>
                                </Collapse>
                                {this.state.pagamento_parcial === true ?
                                <>
                                    {this.state.prevencao_exists === true && this.state.antifraude_finalizada === false ?
                                        <>
                                        {this.state.aqbank_request_secundario !== null ?
                                            (<div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                            <h5>Análise Antifraude do segundo pagamento</h5><hr/>
                                                {this.state.aqbank_request_secundario.finalizado === 0 ?
                                                    <>
                                                        <p><b>Status:</b> {this.state.aqbank_request_secundario.status}</p>
                                                        <p><b>Chegada em Fila:</b> {moment(this.state.pagamento_secundario.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                        <p><b>Inicio de Análise:</b> 
                                                            {moment(this.state.aqbank_request_secundario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                            &nbsp;-&nbsp;
                                                            {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name}</p>
                                                        <FormGroup>
                                                            <Select 
                                                                placeholder="Status" 
                                                                options={list_prevencao_status}
                                                                onChange={(status_current_secound) => this.setState({status_current_secound})}
                                                                value={this.state.status_current_secound}
                                                            />
                                                        </FormGroup>
                                                        <div>
                                                            {this.state.status_current_secound !== null ?
                                                                this.stepPrevencao(this.state.status_current_secound)
                                                                :
                                                                <></>}
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <p><b>Status:</b> {this.getStatus(this.state.aqbank_request_secundario.status)}</p>
                                                        {/* <p><b>Chegada em Fila:</b> {this.state.chegada_fila}</p> */}
                                                        <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                                        <p><b>Inicio de Análise:</b> {moment(this.state.aqbank_request_secundario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                        &nbsp;-&nbsp;
                                                        {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? 
                                                            '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name
                                                        }</p>
                                                        <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_secundario.final_analise).format('DD/MM/YYYY HH:mm')} - {this.state.aqbank_request_primario.responsavel_analise !== null ? this.state.aqbank_request_primario.responsavel_analise.name : ''}</p>
                                                    </>
                                                }
                                            </div>)
                                            :
                                            <>
                                                <Button 
                                                    color="success" 
                                                    disabled={this.state.loading_verificacao_secound} 
                                                    style={{marginBottom: 5}} 
                                                    onClick={() => this.iniciar_segunda_verificacao(this.state.pagamento_secundario.id)}>
                                                    {this.state.loading_verificacao_secound === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                                    &nbsp;Iniciar antifraude do segundo pagamento
                                                </Button>
                                            </>}
                                        </>
                                        :
                                        <>
                                            <p><b>Status do pagamento: </b> {this.getStatus(this.state.pagamento_secundario.antifraude_code)}</p>
                                            {this.state.aqbank_request_secundario !== null ? 
                                                <>
                                                    <p>
                                                        <b>Inicio de Análise:</b> {moment(this.state.aqbank_request_secundario.inicio_analise).format('DD/MM/YYYY HH:mm')} 
                                                        &nbsp;-&nbsp;
                                                        {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? 
                                                            '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name
                                                        }
                                                    </p>
                                                    <p><b>Final de Análise:</b> {moment(this.state.aqbank_request_secundario.final_analise).format('DD/MM/YYYY HH:mm')}
                                                        &nbsp;-&nbsp;
                                                        {this.state.aqbank_request_secundario.responsavel_inicio_analise === null ? 
                                                            '' : this.state.aqbank_request_secundario.responsavel_inicio_analise.name
                                                        }
                                                    </p>

                                                </>
                                            :
                                            <></>}
                                        </>
                                    }
                                </>
                            :<></>}
                            </> : null}
                            {this.state.pagamento_parcial === true &&
                            this.state.prevencao_exists === true && 
                            this.state.antifraude_finalizada === false && 
                            (this.state.status_current_secound !== null || this.state.status_current_first !== null)
                            ?
                                <>
                                <br/>
                                    <FormGroup>
                                        <Button disabled={this.state.loading_button} color="success" onClick={() => this.registrarPrevencaoMultipla()}>
                                            {this.state.loading_button === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                            &nbsp;Salvar Antifraude 
                                        </Button>
                                    </FormGroup>
                                </>
                            :
                            <></>
                            }
                        </div>
                        </Col>
                        <Col>
                            {this.state.ip_localization_client !== null ? 
                                <div style={{marginBottom: '1rem', padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                    <h5>Localização do IP</h5>
                                    <hr/>
                                    <p><b>IP:</b> {this.state.ip_localization_client.query}</p>
                                    <p><b>Servidor:</b> {this.state.ip_localization_client.as}</p>
                                    <p><b>Localização:</b> {this.state.ip_localization_client.city} - {this.state.ip_localization_client.region}</p>
                                    <Button color="warning" onClick={() => this.setState({modal_localization: !this.state.modal_localization})}>
                                        <AiFillCaretDown hidden={this.state.modal_localization}/>
                                        <AiFillCaretUp hidden={!this.state.modal_localization}/> {this.state.modal_localization === false ? 'Abrir mapa de localização de IP' : 'Fechar Mapa'}
                                    </Button>
                                    <Collapse isOpen={this.state.modal_localization}>
                                        <br/>
                                        <div style={{ height: '340px', width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: "AIzaSyCNBu6Af086YRI-3EdGrmsqgdBvjfL7Mdg" }}
                                                defaultCenter={{
                                                    lat: this.state.ip_localization_client.lat,
                                                    lng: this.state.ip_localization_client.lon
                                                }}
                                                defaultZoom={16}
                                                >
                                                <AnyReactComponent
                                                    lat={this.state.ip_localization_client.lat}
                                                    lng={this.state.ip_localization_client.lon}
                                                    text={this.state.ip_localization_client.city +' - '+this.state.ip_localization_client.region}
                                                />
                                            </GoogleMapReact>
                                        </div>
                                    </Collapse>
                                </div>
                            :
                                <></>
                            }
                            
                            <div style={{
                                overflow: 'auto',
                                height: this.state.hitorico_consultas_localiza !== undefined && this.state.hitorico_consultas_localiza.length > 0 ? '855px' : 'auto',                                
                                padding: '15px', 
                                border: '1px solid #dee2e6', 
                                borderRadius: 5
                                }}>
                                <Button 
                                    disabled={this.state.loading_consultar_localiza_vendedor} 
                                    onClick={() => {this.modalConsultaSellerLocalizador(true)}} 
                                    style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                    color="success">
                                    {this.state.loading_consultar_localiza_vendedor === true ?
                                        <ClipLoader color="#fff" size={15}/>
                                        :
                                        <FaSearch />
                                    } Consultar
                                </Button>

                                {/* <Button 
                                            color="success" 
                                            style={{marginLeft: '1rem'}}
                                            onClick={() => {this.modalConsultaSeller(true)}} 
                                            disabled={this.state.loading_consultar_vendedor}>
                                            {this.state.loading_consultar_vendedor === true ? 
                                            <ClipLoader color="#fff" size={15}/>
                                            :
                                            <FaSearch />
                                            }
                                            Consultar Crédito
                                        </Button> */}

                                <h5>Localizador AQBank</h5><hr/>
                                {this.state.hitorico_consultas_localiza !== undefined && this.state.hitorico_consultas_localiza.length > 0 ?
                                    
                                    this.state.hitorico_consultas_localiza.map((consulta, i) => {
                                        if (i == 0) {
                                            return (
                                            <div key={'div_historico_comprador_'+i}>
                                                    <Button 
                                                        disabled={this.state.loading_analises_ip} 
                                                        onClick={(event) => {this.handleOpenModalLocalizaDetalhado(consulta)}}  
                                                        style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                        color="info">
                                                        {this.state.loading_analises_ip === true ?
                                                            <ClipLoader color="#fff" size={15}/>
                                                            :
                                                            <FaEye />
                                                        } Ver Detalhado
                                                    </Button>
                                                <p>
                                                    <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                </p>
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    <div key={"localiza_bar_"+consulta.id} style={{position: 'relative'}}>
                                                        <h5>Endereços</h5><hr/>
                                                        {consulta.payload.resposta.enderecos !== undefined && consulta.payload.resposta.enderecos.length > 0 ?
                                                            consulta.payload.resposta.enderecos.map((endereco, ps) => {
                                                                return (
                                                                    <div>
                                                                        <p>{endereco.cep}</p>
                                                                        <p>{endereco.tipoLogradouro}. {endereco.logradouro}, {endereco.numero} {endereco.complemento !== undefined && endereco.complemento !== '' ? ' - ' + endereco.complemento : ''}</p>
                                                                        <p>{endereco.bairro}</p>
                                                                        <p>{endereco.cidade} / {endereco.uf}</p><hr/>
                                                                    </div>
                                                                )
                                                            })
                                                        :
                                                        <></>
                                                        }
                                                        
                                                    </div>
                                                </Card>
                                                
                                                <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                                                    {consulta.payload.resposta.telefones.fixos !== undefined &&
                                                    consulta.payload.resposta.telefones.fixos.length > 0 ?
                                                        
                                                    consulta.payload.resposta.telefones.fixos.map((telefone, ps) => {
                                                            return(
                                                                <div>
                                                                    <h5>Fixo: {telefone.numero}</h5><hr/>
                                                                    {telefone.numero !== undefined ? 
                                                                        <div>
                                                                            {/* <p><b>Número:</b> </p> */}
                                                                            <p><b>Relação:</b> {telefone.relacao}</p>
                                                                            <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                                            <p><b>WhatsApp Business:</b> {telefone.aplicativos.whatsAppBusiness == false ? 'Não' : 'Sim'}</p>
                                                                        </div>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                        <></>
                                                    }

                                                    {consulta.payload.resposta.telefones.moveis !== undefined &&
                                                    consulta.payload.resposta.telefones.moveis.length > 0 ?
                                                        
                                                    consulta.payload.resposta.telefones.moveis.map((telefone, i) => {
                                                            return(
                                                                <div>
                                                                    <h5>Celular: {telefone.numero}</h5><hr/>
                                                                    {telefone.numero !== undefined ? 
                                                                        <div>
                                                                            {/* <p><b>Número:</b> </p> */}
                                                                            <p><b>Relação:</b> {telefone.relacao}</p>
                                                                            <p><b>Não pertube:</b> {telefone.naoPerturbe == false ? 'Não' : 'Sim'}</p>
                                                                            <p><b>WhatsApp:</b> {telefone.aplicativos.whatsApp == false ? 'Não' : 'Sim'}</p>
                                                                        </div>
                                                                    :
                                                                        <></>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    :
                                                        <></>
                                                    }
                                                </Card>                                                
                                            </div>
                                            )
                                        } else {
                                            return (
                                                <div style={{marginTop: '1rem'}} key={'div_historico_vendedor_localiza_'+consulta.id}>
                                                    <hr/>
                                                        <Button 
                                                            disabled={this.state.loading_analises_ip} 
                                                            onClick={(event) => {this.handleOpenModalLocalizaDetalhado(consulta)}} 
                                                            style={{margunTop: '7px',float: 'right',position: 'relative',top: '-5px'}}
                                                            color="info">
                                                            {this.state.loading_analises_ip === true ?
                                                                <ClipLoader color="#fff" size={15}/>
                                                                :
                                                                <FaEye />
                                                            } Ver Detalhado
                                                        </Button>
                                                    <p>
                                                        <b>Data da consulta:</b> {moment(consulta.created_at).format('DD/MM/YYYY HH:mm')}
                                                    </p>

                                                    <hr />
                                                </div>
                                            )
                                        }
                                    })
                                :
                                    <p>Nenhuma consulta encontrada</p>
                                }
                            </div>
                        </Col>
                    </Row>

                    <br/>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Buyer - E-mail's do pagamento</h5>
                        <hr/>
                        {this.state.loading_buyer_messages_email === false ?
                            <>
                                <ul style={{listStyleType: 'circle'}}>
  
                                    {this.state.mensagens_emails.map(p => {
                                        return <li key={this.uniqueID} style={{marginBottom: '7px'}}>
                                            {p.subject}<br/>
                                            <small>Conteúdo de E-mail:</small>
                                            <div style={{border: '1px solid #7f7f7f66', borderRadius: '15px', padding: '19px'}} dangerouslySetInnerHTML={{ __html: sanitizeHtml(p.message)}}/>
                                            <small>{p.responsavel !== null ? <><b>Operador:</b> {p.responsavel.name}</> : ''}</small>
                                            <small>{p.mail.length > 0 ?
                                                <div data-tip={getStringMaps(p.mail)} style={{ maxHeight: '200px', 
                                                cursor: 'pointer', overflow: 'auto', width: '100%', margin: '12px' }}>
                                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    {p.mail.slice(0, 1).map((p) => {
                                                        return <li key={this.uniqueID}>
                                                        <p><MdMail /> <b>{getTranslate(p.recordType)}</b>
                                                            <br />
                                                            {moment(new Date(p.dateAt).toString()).format('DD/MM/YYYY h:mm:ss a')}</p>
                                                        </li>
                                                    })}
                                                    </ul>
                                                    <ReactTooltip multiline={true} />
                                                </div>
                                                :
                                                <div data-tip={"Postado " + moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')} style={{ cursor: 'pointer', maxHeight: '200px', overflow: 'auto', width: '100%', margin: '12px' }}>
                                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                    <li key={this.uniqueID} style={{ listStyleType: 'none' }}><MdMail /> <b>Postado</b><br />{moment(new Date(p.created_at).toString()).format('DD/MM/YYYY h:mm:ss a')}
                                                    </li>
                                                    </ul>
                                                    <ReactTooltip multiline={true} />
                                                </div>
                                            }</small>
                                        </li>
                                    })}
                                </ul>
                                {this.state.mensagens_emails.length === 0 ? 'Nenhum email encontrado' : ''}
                            </>
                            :
                            <>Carregando Mensagens ...</>
                        }
                        <hr/>
                        <Button color="success" onClick={() => this.setState({isOpenEmailBuyer: !this.state.isOpenEmailBuyer})}>Enviar E-mail para o Buyer</Button>
                        <Collapse isOpen={this.state.isOpenEmailBuyer}>
                        <hr/>
                            <Card >
                                <CardBody style={{minHeight: 250}}>
                                    <Editor
                                        initialEditorState={this.state.mensagem_email}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.handleChangeEmail}
                                        editorStyle={{
                                            fontSize: 12,
                                            fontFamily: "Arial"
                                        }}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <OptionsSMS>
                                        <Button color="danger" onClick={() => this.setState({isOpenEmailBuyer: false})}>Cancelar</Button>
                                        <Button color="success" onClick={() => this.enviarEmail()} disabled={this.state.loading_button_message}>
                                            {this.state.loading_button_message === true ?
                                                <ClipLoader color="#fff" size={15}/>
                                                :
                                                ''} Enviar
                                        </Button>
                                    </OptionsSMS>
                                </CardFooter>
                            </Card>
                        </Collapse>
                    </div>
                    <br/>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Buyer - SMS's do Pagamento</h5>
                        <hr/>
                        {this.state.loading_buyer_messages === false ?
                            <>
                                <ul style={{listStyleType: 'circle'}}>
                                    {this.state.mensagens.map(p => {
                                        return <li key={this.uniqueID} style={{marginBottom: '7px'}}>
                                            {p.mensagem}
                                            <br/><small>
                                                {p.respostas.map(res => {
                                                    return <><b>{getStatus(res.tipo_resposta)}</b> {' - '+getResposta(res.resposta)}</>
                                                })}
                                                {p.respostas.length === 0 ? <><b>{getStatus(p.status_envio)}</b></> : ''}
                                                <br/>{p.responsavel !== null ? 'Operador: '+p.responsavel.name : ''}
                                            </small>
                                        </li>
                                    })}
                                </ul>
                                {this.state.mensagens.length === 0 ? 'Nenhuma mensagem encontrada' : ''}
                            </>
                            :
                            <>Carregando Mensagens ...</>
                        }
                        <hr/>
                        <Button color="success" onClick={() => this.setState({isOpenSMSBuyer: !this.state.isOpenSMSBuyer})}>Enviar SMS para o Buyer</Button>
                        <Collapse isOpen={this.state.isOpenSMSBuyer}>
                            <hr/>
                            <Card>
                                <CardBody>
                                    <Input
                                        name="mensagem"
                                        placeholder="Descrição do SMS"
                                        value={this.state.mensagem}
                                        type="textarea"
                                        style={{
                                            height: '124px',
                                            resize: 'none'
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                    <hr/>
                                    <OptionsSMS>
                                        <Button color="dark" onClick={() => this.adicionarMensagem('{{ NOME }}')} outline>&#x0007B;&#x0007B; NOME &#x0007D;&#x0007D;</Button>
                                    </OptionsSMS>
                                </CardBody>
                                <CardFooter>
                                    <OptionsSMS>
                                        <Button color="danger" onClick={() => this.setState({isOpenSMSBuyer: false})}>Cancelar</Button>
                                        <Button color="success" onClick={() => this.enviarSMS()} disabled={this.state.loading_button_message}>
                                            {this.state.loading_button_message === true ?
                                                <ClipLoader color="#fff" size={15}/>
                                                :
                                                ''} Enviar
                                        </Button>
                                    </OptionsSMS>
                                </CardFooter>
                            </Card>
                        </Collapse>
                    </div>
                    <br/>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                    <h5>Cartões cadastrados</h5><hr/>
                    {this.state.loading_cartao === true ? 
                        <>Carregando Cartões...</> 
                        : 
                        <>
                            {this.state.cartoes.length === 0 ? 
                                'Nenhum cartão encontrado' 
                                : 
                                <DataTable
                                    noHeader={true}
                                    button={true}
                                    columns={columns(this.state, this.setAnaliseCartao, this.bloquearCartao)}
                                    data={this.state.cartoes}
                                    theme="default"
                                />
                            }
                        </>
                    }
                    </div>
                    <br/>
                    {/* {this.state.view_cartao === true ?
                        <>
                            <Button color="success">Ver todos os cartões desse seller</Button>
                            <br/>
                        </>
                        :
                    <></>} */}
                    <div id="ip-transaction">
                        <div hidden={!this.state.view_ip}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>
                                    Transações referentes ao IP {this.state.ip_client}
                                </h5>
                                <hr/>
                                {this.state.transacoes_ip.length === 0 ? 
                                    'Nenhuma transação encontrado' 
                                    : 
                                    <DataTable
                                        noHeader={true}
                                        button={true}
                                        columns={collumns_ip()}
                                        data={this.state.transacoes_ip}
                                        theme="default"
                                    />
                                }
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div id="card-transaction">
                        <div hidden={!this.state.view_cartao}>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>
                                    Transações referentes ao cartão {this.state.numero_cartao.substr(0, 6)}
                                    xxxxxx
                                    {this.state.numero_cartao.substr(-4, 4)}
                                </h5>
                                <hr/>
                                {this.state.transacoes.length === 0 ? 
                                    'Nenhuma transação encontrado' 
                                    : 
                                    <DataTable
                                        noHeader={true}
                                        button={true}
                                        columns={collumns_transacao()}
                                        data={this.state.transacoes}
                                        theme="default"
                                    />
                                }
                            </div>
                            <br/>
                        </div>
                    </div>
                    {this.state.prevencao_exists === true ?
                        this.state.pagamento_parcial === false || (this.state.pagamento_multiplo_finalizado === true || this.state.pagamento_secundario === null) ?
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>Analise Antifraude</h5><hr/>
                            {this.state.antifraude_finalizada === true ?
                                <>
                                    <p><b>Status:</b> {this.getStatusByPayment(this.state.status)}</p>
                                    {/* <p><b>Chegada em Fila:</b> {this.state.chegada_fila}</p> */}
                                    <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                    <p><b>Inicio de Análise:</b> {moment(this.state.inicio_analise).format('DD/MM/YYYY HH:mm')} - {this.state.funcionario_inicio_responsavel === null ? this.getNameByNotas() : this.state.funcionario_inicio_responsavel.name}</p>
                                    <p><b>Final de Análise:</b> {moment(this.state.final_analise).format('DD/MM/YYYY HH:mm')} - {this.state.funcionario_responsavel === null ? 'Sem Informações' : this.state.funcionario_responsavel.name}</p>
                                    {/* <Button color="success" disabled={this.state.loading_verificacao} onClick={() => this.reverterPrevencao()}>
                                        {this.state.loading_verificacao === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                            &nbsp;Re-analisar
                                    </Button>  */}
                                </>
                                :
                                <>
                                <Form>
                                    <p><b>Status:</b> {this.getStatusByPayment(this.state.status)}</p>
                                    <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                    <p><b>Inicio de Análise:</b> {moment(this.state.inicio_analise).format('DD/MM/YYYY HH:mm')} - {this.state.funcionario_inicio_responsavel === null ? this.getNameByNotas() : this.state.funcionario_inicio_responsavel.name}</p>
                                    <FormGroup>
                                        <Select 
                                            placeholder="Status" 
                                            options={list_prevencao_status}
                                            onChange={(status_current) => this.setState({status_current})}
                                            value={this.state.status_current}
                                        />
                                    </FormGroup>
                                    <div>
                                        {this.state.status_current !== null ?
                                        this.stepPrevencao(this.state.status_current)
                                        :
                                        <></>}
                                    </div>
                                    <FormGroup hidden={this.state.status_current === null}>
                                        <Button disabled={this.state.loading_button} color="success" onClick={() => this.registrarPrevencao()}>
                                            {this.state.loading_button === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                            &nbsp;Salvar
                                        </Button>
                                    </FormGroup>
                                </Form>
                                </>
                            }
                        </div>
                        :
                        this.state.pagamento_parcial === true && this.state.antifraude_finalizada === true ?
                            <>
                                <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Analise Antifraude</h5><hr/>
                                    <p><b>Status:</b> {this.getStatusByPayment(this.state.status)}</p>
                                    <p><b>Chegada em Fila:</b> {moment(this.state.data_pagamento).format('DD/MM/YYYY HH:mm')}</p>
                                    <p><b>Inicio de Análise:</b> {moment(this.state.inicio_analise).format('DD/MM/YYYY HH:mm')} - {this.state.funcionario_inicio_responsavel === null ? this.getNameByNotas() : this.state.funcionario_inicio_responsavel.name}</p>
                                    <p><b>Final de Análise:</b> {moment(this.state.final_analise).format('DD/MM/YYYY HH:mm')} - {this.state.funcionario_responsavel === null ? 'Sem Informações' : this.state.funcionario_responsavel.name}</p>
                                </div>
                            </>
                            :
                            <></>
                        :
                    <>
                        <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                            <h5>Status de Pagamento</h5><hr/>
                            <p><b>Status: { this.state.antifraude_code} </b> {this.getStatus(this.state.antifraude_code)}</p>
                            
                            {this.state.antifraude_code === 'APA' || this.state.antifraude_code === 'APM' ? 
                                <div key="div_status_pg">
                                <Row>
                                    <Col md={6}>
                                        <Button 
                                            color="success" 
                                            disabled={this.state.loading_verificacao}
                                            onClick={() => this.iniciarVerificacao()}
                                            >
                                                &nbsp;Iniciar Verificação
                                        </Button> 
                                    </Col>
                                    <Col md={6}>
                                        <Button 
                                            color="warning" 
                                            disabled={this.state.loading_verificacao}
                                            hidden={this.state.cancelar_motivo}
                                            onClick={() => {
                                                this.setState({cancelar_motivo: !this.state.cancelar_motivo})
                                            }}
                                            >
                                            {this.state.loading_verificacao === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                                &nbsp;Cancelar Transação
                                        </Button>
                                    </Col>
                                </Row>
                                <br/>
                                <Collapse isOpen={this.state.cancelar_motivo}>
                                    <Row>
                                        <Col md={12}>
                                            
                                                <label htmlFor="motivo_cancelamento">Motivo do cancelamento</label>
                                                <Input
                                                    name="motivo_cancelamento"
                                                    placeholder="Descreva o motivo do cancelamento"
                                                    value={this.state.motivo_cancelamento}
                                                    type="textarea"
                                                    onChange={this.handleInputChange}
                                                />
                                        
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={6}>
                                            <Button 
                                                color="success" 
                                                disabled={this.state.loading_cancelamento_transacao}
                                                onClick={this.handleCancelarTransacaoAuto}
                                                >
                                                {this.state.loading_cancelamento_transacao === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                                    &nbsp;Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Collapse>
                                </div>
                            :
                                <></>
                            }

                            {this.state.antifraude_code === 'NVO' ? 
                                <Button color="success" disabled={this.state.loading_verificacao} onClick={() => this.iniciarVerificacao()}>
                                    {this.state.loading_verificacao === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                        &nbsp;Iniciar Análise Antifraude
                                    </Button> 
                                : 
                                <></>
                            }
                            {this.state.antifraude_code === '' && this.state.status === 'ANALISANDO' ? 
                                <Button color="success" disabled={this.state.loading_verificacao} onClick={() => this.iniciarVerificacao()}>
                                    {this.state.loading_verificacao === true ? <ClipLoader color="#fff" size={15}/> : ''}
                                        &nbsp;Iniciar Análise Antifraude
                                    </Button> 
                                : 
                                <></>
                            }
                        </div>
                    </>}
                    <br/>
                    <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                        <h5>Notas</h5><hr/>
                        <ul style={{listStyleType: 'circle'}}>
                            {this.state.notas_index.map(p => {
                                return <li key={this.uniqueID} style={{marginBottom: '7px'}}>
                                    <b>Operador:</b> {p.admin !== null ? p.admin.name : 'Sem Informações'} <br/>
                                    <div style={{border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                        {p.descricao.split('\n').map((x, i, {length}) => (
                                            <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                        ))}
                                    </div>
                                    <small>
                                        {moment(p.created_at).format('DD/MM/YYYY HH:mm')}&nbsp;
                                        <ButtonRed 
                                            hidden={!this.hiddenDelete(p.admin_id)}
                                            disabled={this.state.loading_nota_id === p.id}
                                            onClick={() => this.deletarNota(p.id)}>
                                                Apagar        
                                        </ButtonRed>
                                    </small>
                                </li>
                            })}
                        </ul>
                        {this.state.notas_index.length === 0 ? <p>Nenhuma nota</p> : ''}
                        <Button 
                            color="success" 
                            onClick={() => this.setState({is_open_nota: !this.state.is_open_nota})}>
                                Nova Nota
                        </Button>
                        <Collapse isOpen={this.state.is_open_nota}>
                            <br/>
                            <Card>
                                <CardBody>
                                    <Input
                                        name="nota_mensagem"
                                        placeholder="Descrição"
                                        value={this.state.nota_mensagem}
                                        type="textarea"
                                        style={{
                                            height: '124px',
                                            resize: 'none'
                                        }}
                                        onChange={this.handleInputChange}
                                    />
                                </CardBody>
                                <CardFooter>
                                    <OptionsSMS>
                                        <Button color="danger" onClick={() => this.setState({is_open_nota: false})}>Cancelar</Button>
                                        <Button color="success" onClick={() => this.novaNota()} disabled={this.state.loading_nota}>
                                            {this.state.loading_nota === true ?
                                                <ClipLoader color="#fff" size={15}/>
                                                :
                                                ''} Criar
                                        </Button>
                                    </OptionsSMS>
                                </CardFooter>
                            </Card>
                        </Collapse>
                    </div>
                    <br/>
                    <Row>
                        <Col>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Notas do Buyer</h5><hr/>
                                <ul style={{listStyleType: 'circle'}}>
                                    {this.state.notas_buyer.map(p => {
                                        return <li key={this.uniqueID} style={{marginBottom: '7px'}}>
                                            <b>Operador:</b> {p.admin !== null ? p.admin.name : 'Sem Informações'} <br/>
                                            <div style={{border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                                {p.descricao.split('\n').map((x, i, {length}) => (
                                                    <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                                ))}
                                            </div>
                                            <small>{moment(p.created_at).format('DD/MM/YYYY HH:mm')}</small>
                                        </li>
                                    })}
                                </ul>
                                {this.state.notas_buyer.length === 0 ? <p>Nenhuma nota</p> : ''}
                                <Button 
                                    color="success" 
                                    onClick={() => this.setState({is_open_nota_buyer: !this.state.is_open_nota_buyer})}>
                                        Nova Nota
                                </Button>
                                <Collapse isOpen={this.state.is_open_nota_buyer}>
                                    <br/>
                                    <Card>
                                        <CardBody>
                                            <Input
                                                name="nota_mensagem_buyer"
                                                placeholder="Descrição"
                                                value={this.state.nota_mensagem_buyer}
                                                type="textarea"
                                                style={{
                                                    height: '124px',
                                                    resize: 'none'
                                                }}
                                                onChange={this.handleInputChange}
                                            />
                                        </CardBody>
                                        <CardFooter>
                                            <OptionsSMS>
                                                <Button color="danger" onClick={() => this.setState({is_open_nota_buyer: false})}>Cancelar</Button>
                                                <Button color="success" onClick={() => this.novaNotaBuyer()} disabled={this.state.loading_nota_buyer}>
                                                    {this.state.loading_nota_buyer === true ?
                                                        <ClipLoader color="#fff" size={15}/>
                                                        :
                                                        ''} Criar
                                                </Button>
                                            </OptionsSMS>
                                        </CardFooter>
                                    </Card>
                                </Collapse>
                            </div>
                        </Col>
                        <Col>
                            <div style={{padding: '15px', border: '1px solid #dee2e6', borderRadius: 5}}>
                                <h5>Notas do Seller</h5><hr/>
                                <ul style={{listStyleType: 'circle'}}>
                                    {this.state.notas_seller.map(p => {
                                        return <li key={this.uniqueID} style={{marginBottom: '7px'}}>
                                            <b>Operador:</b> {p.admin !== null ? p.admin.name : 'Sem Informações'} <br/>
                                            <div style={{border: '1px solid #7f7f7f66', borderRadius: 5, padding: '15px'}}>
                                                {p.descricao.split('\n').map((x, i, {length}) => (
                                                    <p style={{margin: (i + 1 === length ? 0 : 3), fontSize: 14}}>{x}</p>
                                                ))}
                                            </div>
                                            <small>{moment(p.created_at).format('DD/MM/YYYY HH:mm')}</small>
                                        </li>
                                    })}
                                </ul>
                                {this.state.notas_seller.length === 0 ? <p>Nenhuma nota</p> : ''}
                                <Button 
                                    color="success" 
                                    onClick={() => this.setState({is_open_nota_seller: !this.state.is_open_nota_seller})}>
                                        Nova Nota
                                </Button>
                                <Collapse isOpen={this.state.is_open_nota_seller}>
                                    <br/>
                                    <Card>
                                        <CardBody>
                                            <Input
                                                name="nota_mensagem_seller"
                                                placeholder="Descrição"
                                                value={this.state.nota_mensagem_seller}
                                                type="textarea"
                                                style={{
                                                    height: '124px',
                                                    resize: 'none'
                                                }}
                                                onChange={this.handleInputChange}
                                            />
                                        </CardBody>
                                        <CardFooter>
                                            <OptionsSMS>
                                                <Button color="danger" onClick={() => this.setState({is_open_nota_seller: false})}>Cancelar</Button>
                                                <Button color="success" onClick={() => this.novaNotaSeller()} disabled={this.state.loading_nota_seller}>
                                                    {this.state.loading_nota_seller === true ?
                                                        <ClipLoader color="#fff" size={15}/>
                                                        :
                                                        ''} Criar
                                                </Button>
                                            </OptionsSMS>
                                        </CardFooter>
                                    </Card>
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                <ReactTooltip/>
                <SendEmailByCard
                    visible={this.state.visible_email_card}
                    onClose={this.onCloseOpenSendEmail}
                    id={this.state.id}
                    card_id={this.state.email_card_id} />
            </Div>
        )
    }
}

export default ResumoPrevencao;