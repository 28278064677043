import { Component } from "react";
import { MdSearch } from "react-icons/md";
import { Button, Card, CardBody, FormGroup, Nav, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import styled from 'styled-components';
import NumberFormat from "react-number-format";
import ClipLoader from 'react-spinners/ClipLoader';
import { InputMasked } from "../../components/InputText/InputMasked";
import { toast } from "react-toastify";
import { ENDPOINT } from "../../auth/endpoint";
import { getToken } from "../../auth/set_services";
import axios from "axios";
import DataTable from "react-data-table-component";
import Select from "react-select";
import moment from "moment";

const Div = styled.div`
    .nav-item{
        margin: 0
    }
    .nav-link{
        color: black;
        text-transform: initial;
        cursor: pointer;
    }
`

const colunas_pagamentos = [
    {
        name: "Data da troca",
        selector: "created_at",
        sortable: false,
        cell: row => row.created_at !== null ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : ''
    },
    {
        name: "Plano anterior",
        selector: "plano_anterior",
        sortable: false,
    },
    {
        name: 'Plano novo',
        selector: 'plano_novo',
        sortable: false
    },
    {
        name: "Responsavel pela troca",
        selector: "responsavel",
        sortable: false,
        cell: p => <>{p.responsavel !== undefined && p.responsavel !== null ? p.responsavel.name : ''}</>
    }
];
const columns = () => [
    {
        name: "Tipo de cartão",
        selector: "tipo_cartao",
        sortable: false,
        width: '12%',
        cell: p => p.tipo_cartao.toUpperCase()
    },
    {
        name: "Nome no cartão",
        selector: "nome_proprietario",
        sortable: false,
        width: '15%'
    },
    {
        name: "Número do cartão",
        selector: "numero",
        sortable: false,
        width: '150px'
    },
    {
        name: "Validade",
        selector: "tipo_cartao",
        sortable: false,
        width: '12%',
        cell: p => p.validade_mes + " / "+p.validade_ano
    },
    {
        name: "Apelido do cartão",
        selector: "apelido_cartao",
        sortable: false,
        width: '12%'
    },
    {
        name: "Bandeira",
        selector: "bandeira",
        sortable: false,
        width: '12%',
        cell: p => p.bandeira !== null ? p.bandeira.toUpperCase() : 'Sem informações'
    },
    {
        name: "Dono do Cartao",
        sortable: false,
        width: '250px',
        cell: p => <ul style={{listStyleType: 'circle', marginTop: '15px'}}>
            <li style={{marginBottom: '7px'}}>{p.user.nome} {p.user.sobrenome}</li>
            <li style={{marginBottom: '7px'}}>{p.user.email}</li>
            <li style={{marginBottom: '7px'}}><NumberFormat displayType={'text'} value={p.user.cpf} format={p.user.cpf.length > 11 ? '##.###.###/####-##' : "###.###.###-##"}/></li>
        </ul>
    },
];

const getPlanos = (callback) => {
    var url = ENDPOINT+"planos_list";
    axios.get(url)
    .then((response) => {
        var data = response.data;
        var planos_list = []
        data.data.forEach(e => {
            planos_list.push({
                value: e.id,
                label: e.name
            })
        });
        callback(planos_list);
    })
}
class TrocarPlanos extends Component{
    constructor(props){
        super(props);
        this.state = {
            cpf_cnpj: '',
            cpf_params: false,
            id_current: '',
            nome_completo_current: '',
            cpf_current: '',
            invalid_cpf_current: false,
            email_current: '',
            telefone_current: '',
            endereco_current: {},
            plan_seller: null,
            planos_list: [],
            plano: null,
            historico_planos: [],

            nome_receita_current: '',
            nascimento_receita_current: '',
            cpf_receita_current: '',
            situacao_receita_current: '',

            loading_button: false,
            loading_buyer: true,
            loading_cartao: true,
            loading_request: false,
            isCnpj: false,
            //cartoes: [],

            activeTab: '1'
        }
    }

    componentDidMount(){
        const urlParams = new URLSearchParams(this.props.location.search)
        const cpf_cnpj = urlParams.get('cpf_cnpj');


        if(cpf_cnpj){
            this.setState({
                cpf_cnpj,
                cpf_params: true
            });
    
            setTimeout(() => {
                this.getUser();
            }, 1000);
        }

        getPlanos(this.onPlano.bind(this))
    }
    listData = [
        { name: <>Dados Pessoais</>, active: '1' },
        { name: <>Receita Federal </>, active: '2' }
        /* { name: <>Cartões de crédito </>, active: '3' } */
    ];
    valid_fields = () => {
        var invalids = 0;
        if(this.state.cpf_cnpj.replace(/([^\d])+/gim, '').length > 10){
            this.setState({invalid_cpf_current: false})
        }
        else{
            invalids++;
            this.setState({invalid_cpf_current: true})
        }

        

        return invalids;
    }
    Navs = () => {
        return <Nav tabs>
            {this.listData.map((p, index) => {
                return <NavLink
                    key={index}
                    className={
                        classnames({ active: this.state.activeTab === p.active })}
                    onClick={() => { this.setState({ activeTab: p.active }); }}>
                    <span>{p.name}</span>
                </NavLink>
            })}
        </Nav>
    }
    
    onPlano(planos_list){
        this.setState({planos_list});
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    getUserbyReceita = (cpf) => {
        var url = ENDPOINT+"user/search_cpf_new?cpf="+cpf;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data.data;
            this.setState({
                nome_receita_current: data.nome,
                nascimento_receita_current: data.nascimento,
                cpf_receita_current: data.ni,
                situacao_receita_current: data.situacao.descricao
            })

            return data;
        })
        .catch((error) => {
            var data = error.response.data;
            this.setState({
                nome_receita_current: '',
                nascimento_receita_current: '',
                cpf_receita_current: '',
                situacao_receita_current: data.errors,
            })
        })
    }
    /* getCartoes = (cpf_comprador) => {
        var url = ENDPOINT+"prevencao/consultar_cartoes/"+cpf_comprador;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            this.setState({
                loading_cartao: false,
                cartoes: data
            })
        })
        .catch(() => {
            this.setState({
                loading_cartao: false,
                cartoes: []
            })
        })
    } */
    storeUser = () => {
        this.setState({loading_request: true});
        
        var url = ENDPOINT+"user/trocar_plano";

        var formData = new FormData();
        
        formData.append('id', this.state.id_current);
        formData.append('document', this.state.cpf_cnpj.replace(/([^\d])+/gim, ''));
        formData.append('plano', this.state.plano.label);
    
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onToast(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onToast(obj);
                return obj;
            }
            var data = error.response.data;
            this.onToast(data);
            return data;
        });
    }
    
    onToast(data) {
        if (data.success === true) {
            toast.success(data.message);
            this.setState({loading_request: false});
            this.getUser();
        }
        else {
            toast.error(data.errors);
            this.setState({loading_request: false});
        }
    }
    getUser = () => {
        if(this.valid_fields() === 0){
            this.setState({loading_button: true})
            var url = ENDPOINT+"user/get_aqbank/"+this.state.cpf_cnpj.replace(/([^\d])+/gim, '');
            axios.get(url, {
                headers: {
                    Authorization: 'Bearer '+getToken()
                }
            })
            .then((response) => {
                var data = response.data;
                if(data.success == true){
                    var user = data.data;
                    if(user.type == 'individual'){

                        this.getUserbyReceita(user.taxpayer_id);
                        this.setState({
                            id_current: user.id,
                            nome_completo_current: user.first_name + ' '+ user.last_name,
                            historico_planos: user.historico_plano,
                            cpf_current: user.taxpayer_id,
                            email_current: user.email,
                            telefone_current: user.phone_number,
                            //endereco_current: user.endereco,
                            plan_seller: user.plan_seller,
                            loading_buyer: false,
                            loading_button: false,
                        });

                        if(user.address){
                            this.setState({
                                /* endereco_current: (user.line1 + ', '+user.line2 + ' - '
                                                + user.city + ', '+user.state), */
                                endereco_current: {
                                    cep: user.address.postal_code,
                                    logradouro: user.address.line1 + ', '+user.address.line2,
                                    bairro: user.address.neighborhood,
                                    cidade: user.address.city,
                                    uf: user.address.state,
                                    complemento: user.address.line3,
                                }
                            })
                        }
                    }
                    else{
                        this.setState({
                            id_current: user.id,
                            nome_completo_current: user.owner.first_name + ' '+ user.owner.last_name,
                            historico_planos: user.historico_plano,
                            cpf_current: user.owner.taxpayer_id,
                            email_current: user.owner.email,
                            telefone_current: user.owner.phone_number,
                            //endereco_current: user.endereco,
                            plan_seller: user.plan_seller,
                            loading_buyer: false,
                            loading_button: false,
                        });
                        if(user.owner.address){
                            this.setState({
                                endereco_current: {
                                    cep: user.owner.address.postal_code,
                                    logradouro: user.owner.address.line1 + ', '+user.owner.address.line2,
                                    bairro: user.owner.address.neighborhood,
                                    cidade: user.owner.address.city,
                                    uf: user.owner.address.state,
                                    complemento: user.owner.address.line3,
                                }
                            })
                        }
                    }

                }
                return data;
            })
            .catch((error) => {
                this.setState({
                    id_current: '',
                    nome_completo_current: '',
                    historico_planos: [],
                    cpf_current: '',
                    email_current: '',
                    telefone_current: '',
                    endereco_current: {},
                    plan_seller: '',
        
                    nome_receita_current: '',
                    nascimento_receita_current: '',
                    cpf_receita_current: '',
                    situacao_receita_current: '',
        
                    loading_button: false,
                    loading_buyer: true,
                    loading_cartao: true,
                    //cartoes: [],
        
                    activeTab: '1'
                })
                var data = error.response.data;
                
                toast.error(data.errors);
            })
        }
    }
    
    render(){
        return(
            <Div>
                <h3>Trocar Plano</h3>
                <hr />
                <Card>
                    <CardBody>
                        <div hidden={this.state.cpf_params}>
                            <InputMasked
                                name="cpf_cnpj"
                                mask={this.state.isCnpj === true ? "99.999.999/9999-99" : "999.999.999-99"}
                                placeholder={this.state.isCnpj === true ? "CNPJ" : "CPF"}
                                invalid={this.state.invalid_cpf_current}
                                type="text"
                                onChange={this.handleChange}
                            />
                            <input 
                                type="checkbox" 
                                checked={this.state.isCnpj}
                                onChange={(e) => this.setState({isCnpj: e.target.checked})}
                            /> Mudar para CNPJ
                            <br/>
                            <br/>
                            <Button disabled={this.state.loading_button} color="success" onClick={() => this.getUser()}>
                                {this.state.loading_button === true ?
                                    <ClipLoader color="#fff" size={20}/>:
                                    <MdSearch style={{ padding: 0 }}/>} Buscar</Button>
                        </div>
                        <div hidden={!this.state.cpf_params}>
                            <p><b>CPF/CNPJ: </b>{this.state.cpf_cnpj} &nbsp;
                            {this.state.loading_button === true ?
                                    <ClipLoader color="#000" size={12}/>:
                                    ''
                            }</p>
                        </div>
                        <br/>
                        <div hidden={this.state.loading_buyer} >
                            <this.Navs/>
                            <TabContent hidden={this.state.loading_buyer} activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Card style={{borderTop: 0, borderRadius: 0}}>
                                        <CardBody>
                                            <h5>Dados Pessoais</h5>
                                            <hr/>
                                            <p><b>Nome:</b> {this.state.nome_completo_current}</p>
                                            <p><b>Telefone:</b> {this.state.telefone_current}</p>
                                            <p><b>E-mail:</b> {this.state.email_current}</p>
                                            <p><b>CPF:</b> {this.state.cpf_current}</p>
                                            <p><b>Plano:</b> {this.state.plan_seller}</p>
                                            <hr/>
                                            {this.state.endereco_current !== null ?
                                                <>
                                                    <p><b>CEP:</b> {this.state.endereco_current.cep}</p>
                                                    <p><b>Logradouro:</b> {this.state.endereco_current.logradouro}</p>
                                                    <p><b>Bairro:</b> {this.state.endereco_current.bairro}</p>
                                                    <p><b>Cidade:</b> {this.state.endereco_current.cidade}</p>
                                                    <p><b>Estado:</b> {this.state.endereco_current.uf}</p>
                                                    <p><b>Complemento:</b> {this.state.endereco_current.complemento}</p>
                                                </>
                                            :<></>}
                                            <hr/>
                                            <FormGroup>
                                                <h5>Histórico de troca de planos</h5>
                                                <Card>
                                                <DataTable
                                                    noHeader={true}
                                                    columns={colunas_pagamentos}
                                                    data={this.state.historico_planos}
                                                />
                                                </Card>
                                            </FormGroup>
                                            <hr/>
                                            <FormGroup>
                                                <h5>Selecione o novo plano do cliente</h5>
                                                <Select
                                                    placeholder="Plano a ser mudado"
                                                    isSearchable={false}
                                                    options={this.state.planos_list}
                                                    value={this.state.plano}
                                                    onChange={(plano) => this.setState({plano})}
                                                />
                                            </FormGroup>
                                            {this.state.plano !== null ?
                                                <Button color="info" onClick={this.storeUser}>Trocar Plano</Button>
                                            : null }
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Card style={{borderTop: 0, borderRadius: 0}}>
                                        <CardBody>
                                            <h5>Receita Federal</h5>
                                            <hr/>
                                            <p><b>Nome:</b> {this.state.nome_receita_current}</p>
                                            <p><b>Data de nascimento:</b> <NumberFormat displayType={"text"} format="##/##/####" value={this.state.nascimento_receita_current} /></p>
                                            <p><b>CPF:</b> <NumberFormat displayType={"text"} format="###.###.###-##" value={this.state.cpf_receita_current} /></p>
                                            <p><b>Situação:</b> {this.state.situacao_receita_current}</p>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </div>
                    </CardBody>
                </Card>
            </Div>
        )
    }
}

export default TrocarPlanos;