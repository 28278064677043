import { Button, Col, FormFeedback, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { InputText } from "../../components/InputText/InputText";
import CurrencyInput from "react-currency-masked-input";
import { InputMasked } from "../../components/InputText/InputMasked";
import { ENDPOINT } from "../../auth/endpoint";
import { Component } from "react";
import axios from "axios";
import { getToken } from "../../auth/set_services";
import ModelReturn from "../../components/Modals/ReturnsApiModals/ModelReturn";

class ClienteAQContabilEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            request_cnpj: null,
            disabledCNPJ: false,
            is_cnpj_checked: false,

            name: '',
            name_invalid: false,
            last_name: '',
            last_name_invalid: false,

            nome_fantasia_pretendido: '',
            nome_fantasia_pretendido_invalid: false,
            capital_social: 0,
            capital_social_invalid: false,
            porte_empresa: '',
            porte_empresa_invalid: false,
            mcc: null,
            mcc_invalid: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document_cpf: '',
            document_cnpj: '',
            document_cnpj_invalid: false,
            document_cnpj_loading: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.user !== this.props.user && nextProps.user !== null) {
            this.onEdit(nextProps.user)
        }
    }

    onEdit = (userEdit) => {
        this.setState({
            disabledCNPJ: userEdit.cnpj !== "" ? true : false,
            is_cnpj_checked: userEdit.cnpj !== "" ? true : false,

            name: userEdit.nome,
            last_name: userEdit.sobrenome,

            nome_fantasia_pretendido: userEdit.nome_fantasia_pretendido,
            capital_social: parseFloat(userEdit.capital_social).toFixed(2).toString(),
            porte_empresa: userEdit.porte_empresa,
            cep: userEdit.endereco.cep,
            logradouro: userEdit.endereco.logradouro,
            numero: userEdit.endereco.numero,
            complemento: userEdit.endereco.complemento,
            bairro: userEdit.endereco.bairro,
            cidade: userEdit.endereco.cidade,
            uf: userEdit.endereco.uf,
            email: userEdit.email,
            telefone: this.formatarTelefone(userEdit.telefone),
            document_cpf: userEdit.cpf,
            document_cnpj: userEdit.cnpj
        })
    }

    getBusiness = (cnpj, onData) => {
        var url = ENDPOINT + "user/search_cnpj?cnpj=" + cnpj.replace(/([^\d])+/gim, '');
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onCNPJ(data);
                return data;
            })
            .catch((error) => {

                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onCNPJ(obj);
                    return obj;
                }
                var data = error.response.data;
                this.onCNPJ(data);
                return data;
            });
    }

    handleBlur = (event) => {
        let value = event.target.value;

        if (value.replace(/([^\d])+/gim, '').length > 13) {
            this.getBusiness(value.replace(/([^\d])+/gim, ''), this.onCNPJ.bind(this));
        }
    }

    onCNPJ(data) {
        if (data.success === true) {
            this.setState({ request_cnpj: data.data });
        }
        else {
            this.setState({ request_cnpj: null });
        }
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });

        if (name === 'cep') {
            if (value.replace(/([^\d])+/gim, '').length > 7) {
                this.getCep(value)
            }
        }
    }

    validateFields = () => {
        var invalids = 0;

        if (this.state.name.length > 0) {
            this.setState({ name_invalid: false })
        }
        else {
            invalids++;
            this.setState({ name_invalid: true })
        }

        if (this.state.last_name.length > 0) {
            this.setState({ last_name_invalid: false })
        }
        else {
            invalids++;
            this.setState({ last_name_invalid: true })
        }

        if (this.state.email.length > 0) {
            this.setState({ email_invalid: false })
        }
        else {
            invalids++;
            this.setState({ email_invalid: true })
        }

        if (this.state.telefone.replace(/([^\d])+/gim, '').length > 9) {
            this.setState({ telefone_invalid: false })
        }
        else {
            invalids++;
            this.setState({ telefone_invalid: true })
        }

        if (this.state.is_cnpj_checked == true) {
            if (this.state.cep.replace(/([^\d])+/gim, '').length > 7) {
                this.setState({ cep_invalid: false })
            }
            else {
                invalids++;
                this.setState({ cep_invalid: true })
            }


            if (this.state.telefone.replace(/([^\d])+/gim, '').length > 9) {
                this.setState({ telefone_invalid: false })
            }
            else {
                invalids++;
                this.setState({ telefone_invalid: true })
            }


            if (this.state.logradouro.length > 0) {
                this.setState({ logradouro_invalid: false })
            }
            else {
                invalids++;
                this.setState({ logradouro_invalid: true })
            }

            if (this.state.numero.length > 0) {
                this.setState({ numero_invalid: false })
            }
            else {
                invalids++;
                this.setState({ numero_invalid: true })
            }

            if (this.state.complemento.length > 0) {
                this.setState({ complemento_invalid: false })
            }
            else {
                invalids++;
                this.setState({ complemento_invalid: true })
            }

            if (this.state.bairro.length > 0) {
                this.setState({ bairro_invalid: false })
            }
            else {
                invalids++;
                this.setState({ bairro_invalid: true })
            }

            if (this.state.cidade.length > 0) {
                this.setState({ cidade_invalid: false })
            }
            else {
                invalids++;
                this.setState({ cidade_invalid: true })
            }

            if (this.state.uf.length > 0) {
                this.setState({ uf_invalid: false })
            }
            else {
                invalids++;
                this.setState({ uf_invalid: true })
            }

        }

        if (invalids > 0) {
            this.storeUser();
        }

        return invalids
    }

    onRegister = () => {
        if (this.validateFields() === 0) {
            this.setState({ loading: true });
            this.storeUser();
        }
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }
    onClose = () => {
        this.setState({
            request_cnpj: null,
            disabledCNPJ: false,
            is_cnpj_checked: false,

            name: '',
            name_invalid: false,
            last_name: '',
            last_name_invalid: false,

            nome_fantasia_pretendido: '',
            nome_fantasia_pretendido_invalid: false,
            capital_social: 0,
            capital_social_invalid: false,
            porte_empresa: '',
            porte_empresa_invalid: false,
            mcc: null,
            mcc_invalid: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document_cpf: '',
            document_cnpj: '',
            document_cnpj_invalid: false,
            document_cnpj_loading: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    getCep = (value) => {
        var url = "https://viacep.com.br/ws/"+ value + "/json/";
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.setState({
                    logradouro: data.logradouro,
                    complemento: data.complemento,
                    uf: data.uf,
                    bairro: data.bairro,
                    cidade: data.localidade
                })
                /* if (data.success === true) {
                    this.setState({
                        logradouro: data.data.end,
                        complemento: data.data.complemento2,
                        uf: data.data.uf,
                        bairro: data.data.bairro,
                        cidade: data.data.cidade
                    })
                } */
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                    return obj;
                }
                var data = error.response.data;
                this.onData(data);
                return data;
            });

    }

    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            });
            this.props.refreshData(this.state);
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    formatarTelefone = (telefone) => {
        // Remove todos os caracteres que não são números
        telefone = telefone.replace(/\D/g, "");

        // Aplica a máscara (XX) XXXX-XXXX ou (XX) XXXXX-XXXX
        if (telefone.length <= 10) {
            telefone = telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
        } else {
            telefone = telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
        }

        return telefone;
    }
    storeUser = () => {
        var url = ENDPOINT + "user/edit_user_aqcontabil/" + this.props.user.id;

        var formData = new FormData();
        formData.append('cpf', this.state.document_cpf);
        formData.append('nome', this.state.name);
        formData.append('sobrenome', this.state.last_name);
        if (this.state.is_cnpj_checked === false) {
            formData.append('isCnpj', 0);
            formData.append('nome_fantasia_pretendido', this.state.nome_fantasia_pretendido);
            formData.append('capital_social', this.state.capital_social);
            formData.append('porte_empresa', this.state.porte_empresa);
            formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            formData.append('cep', this.state.cep);
            formData.append('logradouro', this.state.logradouro);
            formData.append('numero', this.state.numero);
            formData.append('complemento', this.state.complemento);
            formData.append('bairro', this.state.bairro);
            formData.append('cidade', this.state.cidade);
            formData.append('uf', this.state.uf);
            formData.append('pais', 'Brasil');
        }
        else {
            formData.append('isCnpj', 1);
            formData.append('cnpj', this.state.document_cnpj.replace(/([^\d])+/gim, ''));
            formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            formData.append('cep', this.state.cep);
            formData.append('logradouro', this.state.logradouro);
            formData.append('numero', this.state.numero);
            formData.append('complemento', this.state.complemento);
            formData.append('bairro', this.state.bairro);
            formData.append('cidade', this.state.cidade);
            formData.append('uf', this.state.uf);
            formData.append('pais', 'Brasil');
        }

        formData.append('email', this.state.email);

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
            .then((response) => {
                var data = response.data;
                this.onData(data);
                return data;
            })
            .catch((error) => {
                if (error.message === 'Network Error') {
                    var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                    var obj = JSON.parse(response);
                    this.onData(obj);
                    return obj;
                }
                var data = error.response.data;
                this.onData(data);
                return data;
            });
    }

    onValor = (event) => {
        var valor_current = event.target.value;
        if (valor_current.length > 1) {
        valor_current = valor_current.replaceAll('.', '');
        var digits = valor_current.substr(valor_current.length - 2);
        valor_current = valor_current.slice(0, -2);
        valor_current = valor_current + '.' + digits;
        }

        var valido = /^[\d,.?!]+$/.test(valor_current);
        if (valido === true || valor_current === '') {
            this.setState({capital_social: valor_current});
        }
    };

    render() {
        return <Modal isOpen={this.props.visible} size={"lg"}>
            <ModalHeader>Editar Cliente</ModalHeader>
            <ModalBody hidden={this.state.loading}>
                <FormGroup>
                    <FormGroup>
                        <h6><b>Nome: </b> </h6>
                        <InputText
                            name="name"
                            value={this.state.name}
                            placeholder="Digite o Nome"
                            invalid={this.state.name_invalid}
                            onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Sobrenome : </b> </h6>
                        <InputText
                            name="last_name"
                            value={this.state.last_name}
                            placeholder="Digite o Sobrenome"
                            invalid={this.state.last_name_invalid}
                            onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <h6><b>E-mail: </b> </h6>
                        <InputText
                            name="email"
                            value={this.state.email}
                            placeholder="Digite o e-mail"
                            invalid={this.state.email_invalid}
                            onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup>
                        <h6><b>Telefone:</b> </h6>
                        {/* <InputText placeholder="Telefone"/> */}
                        <InputMasked
                            mask="(99) 99999-9999"
                            name="telefone"
                            value={this.state.telefone}
                            placeholder="Digite o telefone"
                            invalid={this.state.telefone_invalid}
                            onChange={this.handleChange} />
                    </FormGroup>

                    <Row>
                        <Col>
                            <p>A empresa tem cnpj ? &nbsp;&nbsp;
                                <input type="radio" name="is_cnpj_checked" checked={this.state.is_cnpj_checked == true} disabled={this.state.disabledCNPJ} onChange={() => this.setState({ is_cnpj_checked: true })} /> Sim &nbsp;
                                <input type="radio" name="is_cnpj_checked" checked={this.state.is_cnpj_checked == false} disabled={this.state.disabledCNPJ} onChange={() => this.setState({ is_cnpj_checked: false })} /> Não</p>
                        </Col>
                    </Row>

                    <h6><b>CPF: </b> {this.state.document_cpf}</h6>
                    {this.state.is_cnpj_checked == false && <Row>
                        <Col>
                            <FormGroup>
                                <h6><b>Informe qual o nome fantasia que você irá usar na abertura de seu cnpj:</b> </h6>
                                <InputText
                                    name="nome_fantasia_pretendido"
                                    value={this.state.nome_fantasia_pretendido}
                                    placeholder="Nome Fantasia"
                                    invalid={this.state.nome_fantasia_pretendido_invalid}
                                    onChange={this.handleChange} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Qual será o capital social que vai abrir/registrar seu CNPJ:</b> </h6>
                                <CurrencyInput
                                    inputMode="tel"
                                    className="form-control"
                                    currency="BRL"
                                    name="capital_social"
                                    value={this.state.capital_social}
                                    defaultValue={'0.00'}
                                    onChange={(event) => this.onValor(event)} />
                                <Input hidden invalid={this.state.capital_social_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Qual tipo/porte de empresa deseja abrir? </b></h6>
                                <Input
                                    type="select"
                                    placeholder="Porte da Empresa"
                                    name="porte_empresa"
                                    invalid={this.state.porte_empresa_invalid}
                                    onChange={(event) => this.handleChange(event)}>
                                    <option value="">Porte da Empresa</option>
                                    <option value="MEI">MEI</option>
                                    <option value="EIRELI">EIRELI</option>
                                    <option value="Empresario Individual">Empresario Individual</option>
                                    <option value="Sociedade Empresária Limitada">Sociedade Empresária Limitada</option>
                                    <option value="Sociedade Simples">Sociedade Simples</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    }
                    {this.state.is_cnpj_checked == true && <Row>
                        <Col>
                            <InputMasked
                                name="document_cnpj"
                                value={this.state.document_cnpj}
                                mask={"99.999.999/9999-99"}
                                placeholder={"CNPJ"}
                                disabled={this.state.disabledCNPJ}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                            />
                            {this.state.request_cnpj !== null && <>
                                <br />
                                <h6><b>Nome da Empresa: </b> {this.state.request_cnpj.nomeEmpresarial}</h6>
                                <h6><b>Data de Constituição: </b> {this.state.request_cnpj.dataAbertura}</h6>
                                <h6><b>Natureza Juridica: </b>{this.state.request_cnpj.naturezaJuridica.descricao}</h6>
                            </>}
                            <br />
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col md={12}>
                            <h5>Dados de endereço</h5>
                            <hr />
                            <FormGroup>
                                <h6><b>CEP: </b> </h6>
                                <InputMasked
                                    name="cep"
                                    mask="99999-999"
                                    value={this.state.cep}
                                    placeholder="Digite o cep"
                                    invalid={this.state.cep_invalid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <h6><b>Logradouro: </b> </h6>
                                <InputText
                                    name="logradouro"
                                    value={this.state.logradouro}
                                    placeholder="Digite o nome da Rua"
                                    invalid={this.state.logradouro_invalid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Número: </b> </h6>
                                <InputText
                                    name="numero"
                                    value={this.state.número}
                                    placeholder="Digite o numero"
                                    invalid={this.state.numero_invalid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Complemento: </b> </h6>
                                <InputText
                                    name="complemento"
                                    value={this.state.complemento}
                                    placeholder="Digite o complemento"
                                    invalid={this.state.complemento_invalid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <h6><b>Bairro: </b> </h6>
                                <InputText
                                    name="bairro"
                                    value={this.state.bairro}
                                    placeholder="Digite o bairro"
                                    invalid={this.state.bairro_invalid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Estado (UF): </b> </h6>
                                <InputText
                                    name="uf"
                                    maxLength={2}
                                    value={this.state.uf}
                                    placeholder="Digite o uf"
                                    invalid={this.state.uf_invalid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Cidade: </b> </h6>
                                <InputText
                                    name="cidade"
                                    value={this.state.cidade}
                                    placeholder="Digite a cidade"
                                    invalid={this.state.cidade_invalid}
                                    onChange={this.handleChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                </FormGroup>
            </ModalBody>

            <ModalBody
                style={{
                    minHeight: '114px',
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                    paddingBottom: '47px'
                }} hidden={!this.state.loading}>
                <ModelReturn
                    error_message={this.state.error_message}
                    success_message={this.state.success_message}
                    loading={this.state.loading}
                    onClose={this.onClose}
                    onError={this.onError.bind(this)}
                />

            </ModalBody>
            <ModalFooter hidden={this.state.loading}>
                <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                <Button color="success" onClick={() => this.onRegister()}>Salvar</Button>
            </ModalFooter>
        </Modal>
    }
}

export default ClienteAQContabilEdit;