import { Component } from "react";
import { MdSearch } from "react-icons/md";
import Select from "react-select";
import { Button, Col, Modal, Input, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, FormFeedback } from "reactstrap";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import axios from 'axios';
import { getToken } from '../../../auth/set_services';
import { ENDPOINT } from "../../../auth/endpoint";
import NumberFormat from "react-number-format";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import ClipLoader from 'react-spinners/ClipLoader'

const origem_list = [
    {value: 'chat', label: 'Chat Site'},
    {value: 'whatsapp', label: 'WhatsApp'},
    {value: 'sac', label: 'Contato SAC'},
    {value: 'prospeccao', label: 'Prospecção Externa'},
    {value: 'rede_social', label: 'Rede Social'},
    {value: 'lista_interna', label: 'Lista Interna'}
];

const produto_interesse_list = [
    {value: 'mp5', label: 'Maquininha MP5'},
    {value: 'd195', label: 'Maquininha D195'},
    {value: 's920', label: 'Maquininha S920'},
    {value: 'a910', label: 'Maquininha A910'},
    {value: 'mp15', label: 'Maquininha MP15'},
    {value: 'loja_virtual', label: 'Loja Virtual'},
    {value: 'link_pagamento', label: 'Link de Pagamento'},
    {value: 'api_integracao', label: 'API de Integração'},
    {value: 'tag_aqpasso', label: 'TAG AQPasso'},
];


const ponto_list = [
    { value: 'shopping_nova_lapa', label: 'Shopping Nova Lapa'},
    { value: 'shopping_bahia', label: 'Shopping da Bahia'},
    { value: 'shopping_sp_market', label: 'Shopping SP Market'},
    { value: 'filial_sao_paulo', label: 'Filial São Paulo'},
    { value: 'filial_salvador', label: 'Filial Salvador'},
    { value: 'matriz_bh', label: 'Matriz BH'},
];

const getBusiness = (cnpj, onData) => {
    var url = ENDPOINT+"user/search_cnpj?cnpj="+cnpj.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        console.log(error)
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });
}

const getUser = (cpf, onData) => {
    var url = ENDPOINT+"user/search_cpf_new?cpf="+cpf.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
const getMCC = (onData) => {
    var url = ENDPOINT+"user/mcc";
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
const getPlanos = (onData) => {
    var url = ENDPOINT+"user/planos";
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}



class PrevendaModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            isCnpj: false,
            step: 0,
            socios: [],
            request: {},
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            data_nascimento: null,
            data_nascimento_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            unidade_atendimento: null,
            unidade_atendimento_invalid: false,
            origem_cliente: null,
            origem_cliente_invalid: false,
            produto_interesse: null, 
            produto_interesse_invalid: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    componentDidMount(){

    }
    handleSelect = (type) => {
        this.setState({type})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});

        if(name === 'cep'){
            if(value.replace(/([^\d])+/gim, '').length > 7){
                this.getCep(value)
            }
        }
    }
    onSearch(){
        this.setState({document_loading: true})
        if(this.state.isCnpj === true){
            getBusiness(this.state.document, this.onCNPJ.bind(this));
        }
        else{
            getUser(this.state.document, this.onCPF.bind(this));
        }
    }
    onCNPJ(data){
        if(data.success === true){
            var socios = [];
            var socio = null;
            if(data.data.socios){
                data.data.socios.forEach(e => {
                    socios.push({
                        value: e.nome,
                        label: e.nome
                    })
                });
    
                socio = {
                    value: data.data.socios[0].nome,
                    label: data.data.socios[0].nome
                }
            }
            else{
                socios.push({
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                })
    
                socio = {
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                }
            }
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 2,
                request: data.data,
                email: '',
                socios,
                socio
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    onCPF(data){
        if(data.success === true){
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 1,
                request: data.data,
                email: '',
                socios: [],
                socio: null
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    getCep = (value) => {
        var url = ENDPOINT+'user/search_cep?cep='+value;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data.success === true){
                this.setState({
                    logradouro: data.data.end,
                    complemento: data.data.complemento2,
                    uf: data.data.uf,
                    bairro: data.data.bairro,
                    cidade: data.data.cidade
                })
            }
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });

    }
    storeUser = () => {
        var url = ENDPOINT+"user/store_user_prevenda";

        var formData = new FormData();
        formData.append('document', this.state.request.ni.replace(/([^\d])+/gim, ''));
        if(this.state.step === 1){
            formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
        }
        else{
            formData.append('socio_name', this.state.socio.value);
            if(this.state.request.telefones){
                formData.append('telefone', this.state.request.telefones[0].ddd + ''+this.state.request.telefones[0].numero);
            }
            else{
                formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            }
        }
        formData.append('email', this.state.email);
        if(this.state.unidade_atendimento !== null){
            formData.append('unidade_atendimento', this.state.unidade_atendimento.value);
        }
        
        if(this.state.origem_cliente !== null){
            formData.append('origem_cliente', this.state.origem_cliente.value);
        }
        
        if(this.state.produto_interesse !== null){
            formData.append('produto_interesse', this.state.produto_interesse.value);
        }

        if(this.state.type !== null){
            formData.append('type', this.state.type.value);
        }

        formData.append('data_nascimento', this.state.data_nascimento)

        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    validateFields = () => {
        var invalids = 0;
        if(this.state.step === 1){
            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }
            if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                this.setState({telefone_invalid: false})
            }
            else{
                invalids++;
                this.setState({telefone_invalid: true})
            }
        }
        else if(this.state.step === 2){
            if(!this.state.request.telefones){
                if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                    this.setState({telefone_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({telefone_invalid: true})
                }
            }
            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }
            if(this.state.data_nascimento !== null){
                this.setState({data_nascimento_invalid: false})
            }
            else{
                invalids++;
                this.setState({data_nascimento_invalid: true})
            }
            
        }
        else{
            this.onSearch();
            invalids++
        }

        return invalids
    }
    onRegister = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    onData = (data) => {
        
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose = () => {
        this.setState({
            isCnpj: false,
            step: 0,
            socios: [],
            request: {},
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            unidade_atendimento: null,
            unidade_atendimento_invalid: false,
            origem_cliente: null,
            origem_cliente_invalid: false,
            produto_interesse: null, 
            produto_interesse_invalid: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    render(){
        return <Modal isOpen={this.props.visible} size={"lg"}>
            <ModalHeader>Novo Cliente</ModalHeader>
            <ModalBody hidden={this.state.loading}>
                <FormGroup>
                    <Row>
                        <Col md={10} sm={10} xs={10}>
                            <InputMasked
                                name="document"
                                value={this.state.document}
                                mask={this.state.isCnpj === true ? "99.999.999/9999-99" : "999.999.999-99"}
                                placeholder={this.state.isCnpj === true ? "CNPJ" : "CPF"}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={1} sm={1} xs={1}>
                            <Button color="info" onClick={() => this.onSearch()}>
                                {this.state.document_loading === true ?
                                    <ClipLoader color="#fff" size={20}/>:
                                    <MdSearch style={{ padding: 0 }}/>}
                            </Button>
                        </Col>
                    </Row>
                    <input 
                        type="checkbox" 
                        checked={this.state.isCnpj}
                        onChange={(e) => this.setState({isCnpj: e.target.checked})}
                    /> Mudar para CNPJ
                </FormGroup>
                {this.state.step === 1 ?
                    <div>
                        <Row>
                            <Col md={12}>
                                <h6><b>Nome: </b> {this.state.request.nome}</h6>
                                <h6><b>CPF: </b> 
                                    <NumberFormat 
                                        displayType={'text'}  
                                        format="###.###.###-##" 
                                        value={this.state.request.ni} /></h6>
                                <h6><b>Situação: </b> {this.state.request.situacao.descricao}</h6>
                                <h6><b>Data de Nascimento: </b> 
                                    <NumberFormat 
                                        displayType={'text'}  
                                        format="##/##/####" 
                                        value={this.state.request.nascimento} /></h6>
                                <FormGroup>
                                    <h6><b>E-mail: </b> </h6>
                                    <InputText 
                                        name="email"
                                        value={this.state.email}
                                        placeholder="Digite o e-mail"
                                        invalid={this.state.email_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Telefone:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputMasked
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        value={this.state.telefone}
                                        placeholder="Digite o telefone"
                                        invalid={this.state.telefone_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            <FormGroup>
                                <h6><b>Unidade de Atendimento:</b> </h6>
                                <Select
                                    placeholder="Selecione a unidade de atendimento"
                                    options={ponto_list}
                                    value={this.state.unidade_atendimento}
                                    onChange={(unidade_atendimento) => this.setState({unidade_atendimento})}
                                />
                                <Input hidden invalid={this.state.unidade_atendimento_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Origem do Cliente:</b> </h6>
                                <Select
                                    placeholder="Selecione a origem do cliente"
                                    options={origem_list}
                                    value={this.state.origem_cliente}
                                    onChange={(origem_cliente) => this.setState({origem_cliente})}
                                />
                                <Input hidden invalid={this.state.origem_cliente_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Produto de Interesse:</b> </h6>
                                <Select
                                    placeholder="Selecione o produto de interesse"
                                    options={produto_interesse_list}
                                    value={this.state.produto_interesse}
                                    onChange={(produto_interesse) => this.setState({produto_interesse})}
                                />
                                <Input hidden invalid={this.state.produto_interesse_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    :
                    this.state.step === 2 ?
                        <div>
                            <hr/>
                            <h6><b>Nome da Empresa: </b> {this.state.request.nomeEmpresarial}</h6>
                            <h6><b>Data de Constituição: </b> {this.state.request.dataAbertura}</h6>
                            <h6><b>Natureza Juridica: </b>{this.state.request.naturezaJuridica.descricao}</h6>
                            {this.state.request.telefones ?
                                <h6><b>Telefone: </b> {'('+this.state.request.telefones[0].ddd + ') '+this.state.request.telefones[0].numero}</h6>
                                :
                                <FormGroup>
                                    <h6><b>Telefone:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputMasked
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        value={this.state.telefone}
                                        placeholder="Digite o telefone"
                                        invalid={this.state.telefone_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            }
                            <FormGroup>
                                <h6><b>Sócios: </b> </h6>
                                <Select
                                    placeholder="Sócios"
                                    options={this.state.socios}
                                    value={this.state.socio}
                                    onChange={(socio) => this.setState({socio})}
                                />
                                <Input hidden invalid={this.state.socio_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>E-mail Pessoal: </b> </h6>
                                <InputText 
                                    name="email"
                                    value={this.state.email}
                                    placeholder="Digite o e-mail"
                                    invalid={this.state.email_invalid}
                                    onChange={this.handleChange}
                                />
                                <Input hidden invalid={this.state.email_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                                <FormGroup>
                                    <h6><b>Data de Nascimento:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputText
                                        type="date"
                                        name="data_nascimento"
                                        value={this.state.data_nascimento}
                                        placeholder="Digite a data de nascimento"
                                        invalid={this.state.data_nascimento_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            <FormGroup>
                                <h6><b>Unidade de Atendimento:</b> </h6>
                                <Select
                                    placeholder="Selecione a unidade de atendimento"
                                    options={ponto_list}
                                    value={this.state.unidade_atendimento}
                                    onChange={(unidade_atendimento) => this.setState({unidade_atendimento})}
                                />
                                <Input hidden invalid={this.state.unidade_atendimento_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Origem do Cliente:</b> </h6>
                                <Select
                                    placeholder="Selecione a origem do cliente"
                                    options={origem_list}
                                    value={this.state.origem_cliente}
                                    onChange={(origem_cliente) => this.setState({origem_cliente})}
                                />
                                <Input hidden invalid={this.state.origem_cliente_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Produto de Interesse:</b> </h6>
                                <Select
                                    placeholder="Selecione o produto de interesse"
                                    options={produto_interesse_list}
                                    value={this.state.produto_interesse}
                                    onChange={(produto_interesse) => this.setState({produto_interesse})}
                                />
                                <Input hidden invalid={this.state.produto_interesse_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                        </div>
                    :
                    <></>
                }
            </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
            <ModalFooter hidden={this.state.loading}>
                <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                <Button color="success" onClick={() => this.onRegister()}>{this.state.step === 0 ? 'Avançar' : 'Cadastrar'}</Button>
            </ModalFooter>
        </Modal>
    }
}

export default PrevendaModal;