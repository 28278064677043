import { Component } from "react";
import { MdSearch } from "react-icons/md";
import Select from "react-select";
import { Button, Col, Modal, Input, ModalBody, ModalFooter, ModalHeader, Row, FormGroup, FormFeedback, Collapse } from "reactstrap";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import axios from 'axios';
import { getToken } from '../../../auth/set_services';
import { ENDPOINT } from "../../../auth/endpoint";
import NumberFormat from "react-number-format";
import ModelReturn from "../ReturnsApiModals/ModelReturn";
import ClipLoader from 'react-spinners/ClipLoader'

const conta_tipo = [
    {value: 'checking', label: 'Conta Poupança'},
    {value: 'savings', label: 'Conta Corrente'}
]
const getBusiness = (cnpj, onData) => {
    let data = {
        code: 200,
        data: {
            nome: '',
            nascimento: '',
        },
        message: "CNPJ Encontrado",
        success: true,
    }

    //onData(data)

    var url = ENDPOINT+"user/search_cnpj?cnpj="+cnpj.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        console.log(error)
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });
}

const getUser = (cpf, onData) => {

    let data = {
        code: 200,
        data: {
            nome: '',
            nascimento: '',
        },
        message: "Cpf Encontrado",
        success: true,
    }

    onData(data)

    // var url = ENDPOINT+"user/search_cpf_new?cpf="+cpf.replace(/([^\d])+/gim, '');
    // axios.get(url, {
    //     headers: {
    //         Authorization: 'Bearer '+getToken()
    //     }})
    // .then((response) => {
    //     var data = response.data;
    //     onData(data);
    //     return data;
    // })
    // .catch((error) => {
    //     if(error.message === 'Network Error'){
    //         var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
    //         var obj = JSON.parse(response);
    //         onData(obj);
    //         return obj;
    //     }

    //     var data = error.response.data;
    //     onData(data);
    //     return data;
    // });
}
const getMCC = (onData) => {
    var url = ENDPOINT+"user/mcc";
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
const getPlanos = (onData) => {
    var url = ENDPOINT+"user/planos";
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}

const validaCpfCnpj = (val) => {
    if (val.length == 14) {
        var cpf = val.trim();
     
        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');
        
        var v1 = 0;
        var v2 = 0;
        var aux = false;
        
        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;   
            }
        } 
        
        if (aux == false) {
            return false; 
        } 
        
        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p; 
        } 
        
        v1 = ((v1 * 10) % 11);
        
        if (v1 == 10) {
            v1 = 0; 
        }
        
        if (v1 != cpf[9]) {
            return false; 
        } 
        
        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p; 
        } 
        
        v2 = ((v2 * 10) % 11);
        
        if (v2 == 10) {
            v2 = 0; 
        }
        
        if (v2 != cpf[10]) {
            return false; 
        } else {   
            return true; 
        }
    } else if (val.length == 18) {
        var cnpj = val.trim();
        
        cnpj = cnpj.replace(/\./g, '');
        cnpj = cnpj.replace('-', '');
        cnpj = cnpj.replace('/', ''); 
        cnpj = cnpj.split(''); 
        
        var v1 = 0;
        var v2 = 0;
        var aux = false;
        
        for (var i = 1; cnpj.length > i; i++) { 
            if (cnpj[i - 1] != cnpj[i]) {  
                aux = true;   
            } 
        } 
        
        if (aux == false) {  
            return false; 
        }
        
        for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
            if (p1 >= 2) {  
                v1 += cnpj[i] * p1;  
            } else {  
                v1 += cnpj[i] * p2;  
            } 
        } 
        
        v1 = (v1 % 11);
        
        if (v1 < 2) { 
            v1 = 0; 
        } else { 
            v1 = (11 - v1); 
        } 
        
        if (v1 != cnpj[12]) {  
            return false; 
        } 
        
        for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
            if (p1 >= 2) {  
                v2 += cnpj[i] * p1;  
            } else {   
                v2 += cnpj[i] * p2; 
            } 
        }
        
        v2 = (v2 % 11); 
        
        if (v2 < 2) {  
            v2 = 0;
        } else { 
            v2 = (11 - v2); 
        } 
        
        if (v2 != cnpj[13]) {   
            return false; 
        } else {  
            return true; 
        }
    } else {
        return false;
    }
 }



class UserModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            isCnpj: false,
            step: 0,
            socios: [],
            mcc_list: [],
            planos_list: [],
            request: {},
            mcc: null,
            mcc_invalid: false,
            nome_invalid: false,
            nascimento_invalid: false,
            plano: null,
            plano_invalid: false,
            load_cep: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            nome: '',
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            holder_name: '',
            holder_name_invalid: false,
            bank_code: '',
            bank_code_invalid: false,
            routing_number: '',
            routing_number_invalid: false,
            account_number: '',
            account_number_invalid: false,
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        }
    }
    componentDidMount(){
        //console.log(getToken())
        getMCC(this.onMcc.bind(this));
        getPlanos(this.onPlano.bind(this))
    }
    handleSelect = (type) => {
        this.setState({type})
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        
        this.setState({[name]: value});

        if(name === 'cep'){
            if(value.replace(/([^\d])+/gim, '').length > 7){
                this.getCep(value)
            }
        }
    }
    onSearch(){
        this.setState({document_loading: true})

        if (validaCpfCnpj(this.state.document)) {
            if(this.state.isCnpj === true){
                getBusiness(this.state.document, this.onCNPJ.bind(this));
            }
            else{
                getUser(this.state.document, this.onCPF.bind(this));
            }
        } else {
            this.setState({document_loading: false, document_invalid: true})
        }
    }
    onMcc(data){
        if(data.success === true){
            var mcc_list = []
            data.data.forEach(e => {
                mcc_list.push({
                    value: e.id,
                    label: e.category + ' - ' + e.description
                })
            });

            this.setState({mcc_list});
        }
    }
    onPlano(data){
        if(data.success === true){
            var planos_list = []
            data.data.forEach(e => {
                planos_list.push({
                    value: e.id,
                    label: e.name
                })
            });

            this.setState({planos_list});
        }
    }
    onCNPJ(data){
        if(data.success === true){
            var socios = [];
            var socio = null;
            if(data.data.socios){
                data.data.socios.forEach(e => {
                    socios.push({
                        value: e.nome,
                        label: e.nome
                    })
                });
    
                socio = {
                    value: data.data.socios[0].nome,
                    label: data.data.socios[0].nome
                }
            }
            else{
                socios.push({
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                })
    
                socio = {
                    value: data.data.nomeEmpresarial,
                    label: data.data.nomeEmpresarial
                }
            }
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 2,
                request: data.data,
                email: '',
                socios,
                socio
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    onCPF(data){
        if(data.success === true){
            this.setState({
                document_invalid: false,
                document_loading: false,
                step: 1,
                request: data.data,
                email: '',
                socios: [],
                socio: null
            });
        }
        else{
            this.setState({
                document_invalid: true,
                document_loading: false,
                step: 0,
                request: {},
                email: '',
                socios: [],
                socio: null
            });
        }
    }
    getCep = (value) => {
        var url = ENDPOINT+'user/search_cep?cep='+value;
        axios.get(url, {
            headers: {
                Authorization: 'Bearer ' + getToken()
            }
        })
        .then((response) => {
            var data = response.data;
            if(data.success === true){
                this.setState({
                    logradouro: data.data.end,
                    complemento: data.data.complemento2,
                    uf: data.data.uf,
                    bairro: data.data.bairro,
                    cidade: data.data.cidade,
                    load_cep: true,
                })
            }
            return data;
        })
        .catch((error) => {
            this.setState({load_cep: true})
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });

    }
    storeUser = () => {
        var url = ENDPOINT+"user/store_user";

        var formData = new FormData();
        formData.append('document', this.state?.document.replace(/([^\d])+/gim, ''));
        formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
        formData.append('nome', this.state.nome);
        formData.append('cep', this.state.cep);
        formData.append('rua', this.state.logradouro);
        formData.append('numero', this.state.numero);
        formData.append('complemento', this.state.complemento);
        formData.append('bairro', this.state.bairro);
        formData.append('cidade', this.state.cidade);
        formData.append('uf', this.state.uf);
        formData.append('pais', 'Brasil');

        if(this.state.step === 1){
            // data CPF
        }
        else{
            formData.append('socio_name', this.state.socio.value);
            if(this.state?.request?.telefones){
                formData.append('telefone', this.state?.request?.telefones[0]?.ddd + ''+this.state?.request?.telefones[0]?.numero);
            }
            else{
                formData.append('telefone', this.state.telefone.replace(/([^\d])+/gim, ''));
            }
        }

        formData.append('email', this.state.email);
        formData.append('plano', this.state.plano.label);
        formData.append('mcc', this.state.mcc.value);
        
        formData.append('holder_name', this.state.holder_name);
        formData.append('bank_code', this.state.bank_code);
        formData.append('routing_number', this.state.routing_number);
        formData.append('account_number', this.state.account_number);
        if(this.state.type !== null){
            formData.append('type', this.state.type.value);
        }

        
        console.log(
            {
                document: this.state?.document.replace(/([^\d])+/gim, ''),
                cep: this.state.cep,
                rua: this.state.logradouro,
                numero: this.state.numero,
                complemento: this.state.complemento,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                uf: this.state.uf,
                pais: 'Brasil',
                telefone: this.state.telefone.replace(/([^\d])+/gim, ''),
                email: this.state.email,
                plano: this.state.plano.label,
                mcc: this.state.mcc.value,
                holder_name: this.state.holder_name,
                bank_code: this.state.bank_code,
                routing_number: this.state.routing_number,
                account_number: this.state.account_number,
                type: this.state.type.value,
            }
        )
        
        
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            this.onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                this.onData(obj);
                return obj;
            }
            var data = error.response.data;
            this.onData(data);
            return data;
        });
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    validateFields = () => {
        var invalids = 0;
        // console.log(this.state.step)
        // if(this.state.step > 0) {
        //     return false;
        // }

        function validabirthdate(birth_date_value) { 
            var data = birth_date_value; 
            data = data.replaceAll(/\//g, "-");
            var data_array = data.split("-"); 
            
            if(data_array[0].length != 4){
               data = data_array[2]+"-"+data_array[1]+"-"+data_array[0]; 
            }
            var hoje  = new Date();
            var nasc  = new Date(data);
            var idade = hoje.getFullYear() - nasc.getFullYear();
            var m = hoje.getMonth() - nasc.getMonth();
            if (m < 0 || (m === 0 && hoje.getDate() < nasc.getDate())) idade--;
            
            if(idade < 16){
               //alert("Pessoas menores de 16 não podem se cadastrar.");
               return false;
            }
         
            return true;
        }

        function validaData(stringData) {
            var regExpCaracter = /[^\d]/; 
            var regExpEspaco = /^\s+|\s+$/g; 

            stringData = stringData.replaceAll(/\//g, "-")

            if(stringData.length != 10) {
                return false;
            }

            let splitData = stringData.split('-');

            if(splitData.length != 3) {            
                return false;
            }

            splitData[0] = splitData[0].replace(regExpEspaco, '');
            splitData[1] = splitData[1].replace(regExpEspaco, '');
            splitData[2] = splitData[2].replace(regExpEspaco, '');

            if ((splitData[0].length != 2) || (splitData[1].length != 2) || (splitData[2].length != 4)) {                    
                return false;
            }

            if (regExpCaracter.test(splitData[0]) || regExpCaracter.test(splitData[1]) || regExpCaracter.test(splitData[2])) {                    
                return false;
            }

            let dia = parseInt(splitData[0],10);
            let mes = parseInt(splitData[1],10)-1; //O JavaScript representa o mês de 0 a 11 (0->janeiro, 1->fevereiro... 11->dezembro)
            let ano = parseInt(splitData[2],10);    
            var novaData = new Date(ano, mes, dia);                                    
            var hoje = new Date();              

            if (novaData > hoje) {
                return false;
            }
            
            if ((novaData.getDate() != dia) || (novaData.getMonth() != mes) || (novaData.getFullYear() != ano)) {                    
                return false;
            } else {                 
                return true;
            }
        }

        // nome: '',
        // nascimento: '',

        if(this.state.step === 1){
            let nomeSobrenome = /\b[A-Za-zÀ-ú][A-Za-zÀ-ú]+,?\s[A-Za-zÀ-ú][A-Za-zÀ-ú]{2,19}\b/gi;
            if(!nomeSobrenome.test(this.state.nome)) {
                invalids++;
                this.setState({nome_invalid: true})
            } else {
                this.setState({nome_invalid: false})
            }

            // if(validaData(this.state.request.nascimento) && validabirthdate(this.state.request.nascimento)) {
            //     this.setState({nascimento_invalid: false})
            // } else {
            //     invalids++;
            //     this.setState({nascimento_invalid: true})
            // }

            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }


            if(this.state.cep.replace(/([^\d])+/gim, '').length > 7){
                this.setState({cep_invalid: false})
            }
            else{
                invalids++;
                this.setState({cep_invalid: true})
            }


            if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                this.setState({telefone_invalid: false})
            }
            else{
                invalids++;
                this.setState({telefone_invalid: true})
            }
    
    
            if(this.state.logradouro.length > 0){
                this.setState({logradouro_invalid: false})
            }
            else{
                invalids++;
                this.setState({logradouro_invalid: true})
            }
    
            if(this.state.numero.length > 0){
                this.setState({numero_invalid: false})
            }
            else{
                invalids++;
                this.setState({numero_invalid: true})
            }
    
            if(this.state.complemento.length > 0){
                this.setState({complemento_invalid: false})
            }
            else{
                invalids++;
                this.setState({complemento_invalid: true})
            }
    
            if(this.state.bairro.length > 0){
                this.setState({bairro_invalid: false})
            }
            else{
                invalids++;
                this.setState({bairro_invalid: true})
            }
    
            if(this.state.cidade.length > 0){
                this.setState({cidade_invalid: false})
            }
            else{
                invalids++;
                this.setState({cidade_invalid: true})
            }
    
            if(this.state.uf.length > 0){
                this.setState({uf_invalid: false})
            }
            else{
                invalids++;
                this.setState({uf_invalid: true})
            }
            
            if(this.state.plano !== null){
                this.setState({plano_invalid: false})
            }
            else{
                invalids++;
                this.setState({plano_invalid: true})
            }
        }
        else if(this.state.step === 2){
            if(!this.state?.request?.telefones){
                if(this.state.telefone.replace(/([^\d])+/gim, '').length > 9){
                    this.setState({telefone_invalid: false})
                }
                else{
                    invalids++;
                    this.setState({telefone_invalid: true})
                }
            }

            if(this.state.plano !== null){
                this.setState({plano_invalid: false})
            }
            else{
                invalids++;
                this.setState({plano_invalid: true})
            }
            
            if(this.state.mcc !== null){
                this.setState({mcc_invalid: false})
            }
            else{
                invalids++;
                this.setState({mcc_invalid: true})
            }

            if(this.state.email.length > 0){
                this.setState({email_invalid: false})
            }
            else{
                invalids++;
                this.setState({email_invalid: true})
            }
            
        }
        else{
            this.onSearch();
            invalids++
        }

        return invalids
    }
    onRegister = () => {
        if(this.validateFields() === 0){
            this.setState({loading: true});
            this.storeUser();
        }
    }
    onData = (data) => {
        if(data.success === true){
            this.setState({
                success_message: data.message
            })
        }
        else{
            this.setState({
                error_message: data.errors
            })
        }
    }
    onClose = () => {
        this.setState({
            isCnpj: false,
            step: 0,
            socios: [],
            request: {},
            mcc: null,
            mcc_invalid: false,
            plano: null,
            plano_invalid: false,
            cep: '',
            logradouro: '',
            logradouro_invalid: false,
            numero: '',
            numero_invalid: false,
            complemento: '',
            complemento_invalid: false,
            bairro: '',
            bairro_invalid: false,
            cidade: '',
            cidade_invalid: false,
            uf: '',
            uf_invalid: false,
            holder_name: '',
            holder_name_invalid: false,
            bank_code: '',
            bank_code_invalid: false,
            routing_number: '',
            routing_number_invalid: false,
            account_number: '',
            account_number_invalid: false,
            type: '',
            type_invalid: false,
            email: '',
            email_invalid: false,
            telefone: '',
            telefone_invalid: false,
            document: '',
            document_invalid: false,
            document_loading: false,
            socio: null,
            socio_invalid: false,
            loading: false,
            error_message: '',
            success_message: '',
        });
        this.props.onClose(false);
    }

    render(){
        return <Modal isOpen={this.props.visible} size={"lg"}>
            <ModalHeader>Novo Cliente</ModalHeader>
            <ModalBody hidden={this.state.loading}>
                <FormGroup>
                    <Row>
                        <Col md={10} sm={10} xs={10}>
                            <InputMasked
                                name="document"
                                value={this.state.document}
                                mask={this.state.isCnpj === true ? "99.999.999/9999-99" : "999.999.999-99"}
                                placeholder={this.state.isCnpj === true ? "CNPJ" : "CPF"}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col md={1} sm={1} xs={1}>
                            <Button color="info" onClick={() => this.onSearch()}>
                                {this.state.document_loading === true ?
                                    <ClipLoader color="#fff" size={20}/>:
                                    <MdSearch style={{ padding: 0 }}/>}
                            </Button>
                        </Col>
                    </Row>
                    <input 
                        type="checkbox" 
                        checked={this.state.isCnpj}
                        onChange={(e) => this.setState({isCnpj: e.target.checked})}
                    /> Mudar para CNPJ
                </FormGroup>
                <Row>
                    <Col md={12}>
                        <div className="alert alert-danger" style={this.state.document_invalid ? {display: 'block'} : {display: 'none'}}>
                            <span><strong>Atenção!</strong> Documento digitado inválido.</span>
                        </div>
                    </Col>
                </Row>
                {this.state.step === 1 ?
                    <div>
                        <Row>
                            <Col md={6}>
                                <h6><b>CPF: </b> 
                                    <NumberFormat 
                                        displayType={'text'}  
                                        format="###.###.###-##" 
                                        value={this.state.document} /></h6>
                                {/* <h6><b>Situação: </b> {this.state?.request?.situacao?.descricao}</h6> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                
                                <FormGroup>
                                    <h6><b>Nome Completo: </b> </h6>
                                    <InputText 
                                        name="nome"
                                        value={this.state.nome}
                                        placeholder="Digite o nome"
                                        invalid={this.state.nome_invalid}
                                        onChange={(e) => {
                                            let data = this.state
                                            data.nome = e.target.value
                                            this.setState({...data})
                                        }}
                                        />
                                </FormGroup>
                                {/* <FormGroup>
                                    <h6><b>Data de Nascimento: </b> </h6>
                                    <InputMasked
                                        name="nascimento"
                                        value={this.state?.request?.nascimento}
                                        invalid={this.state.nascimento_invalid}
                                        mask={"99/99/9999"}
                                        placeholder={"DD/MM/AAAA"}
                                        onChange={this.handleChange}
                                        />
                                </FormGroup> */}

                                <FormGroup>
                                    <h6><b>E-mail: </b> </h6>
                                    <InputText 
                                        name="email"
                                        value={this.state.email}
                                        placeholder="Digite o e-mail"
                                        invalid={this.state.email_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Telefone:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputMasked
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        value={this.state.telefone}
                                        placeholder="Digite o telefone"
                                        invalid={this.state.telefone_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>Plano:</b> </h6>
                                    <Select
                                        placeholder="Selecione o Plano"
                                        options={this.state.planos_list}
                                        value={this.state.plano}
                                        onChange={(plano) => this.setState({plano})}
                                    />
                                    <Input hidden invalid={this.state.plano_invalid} />
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <h6><b>MCC:</b> </h6>
                                    <Select
                                        placeholder="MCC"
                                        options={this.state.mcc_list}
                                        value={this.state.mcc}
                                        onChange={(mcc) => this.setState({mcc})}
                                    />
                                    <Input hidden invalid={this.state.mcc_invalid} />
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <h6><b>CEP: </b> </h6>
                                    <InputMasked 
                                        name="cep"
                                        mask="99999-999"
                                        value={this.state.cep}
                                        placeholder="Digite o cep"
                                        invalid={this.state.cep_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                                <Collapse isOpen={this.state.load_cep}>
                                    <FormGroup>
                                        <h6><b>Logradouro: </b> </h6>
                                        <InputText 
                                            name="logradouro"
                                            value={this.state.logradouro}
                                            placeholder="Digite o nome da Rua"
                                            invalid={this.state.logradouro_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Número: </b> </h6>
                                        <InputText 
                                            name="numero"
                                            value={this.state.número}
                                            placeholder="Digite o numero"
                                            invalid={this.state.numero_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Complemento: </b> </h6>
                                        <InputText 
                                            name="complemento"
                                            value={this.state.complemento}
                                            placeholder="Digite o complemento"
                                            invalid={this.state.complemento_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Bairro: </b> </h6>
                                        <InputText 
                                            name="bairro"
                                            value={this.state.bairro}
                                            placeholder="Digite o bairro"
                                            invalid={this.state.bairro_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Cidade: </b> </h6>
                                        <InputText 
                                            name="cidade"
                                            value={this.state.cidade}
                                            placeholder="Digite o cidade"
                                            invalid={this.state.cidade_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <h6><b>Estado (UF): </b> </h6>
                                        <InputText 
                                            name="uf"
                                            maxLength={2}
                                            value={this.state.uf}
                                            placeholder="Digite o uf"
                                            invalid={this.state.uf_invalid}
                                            onChange={this.handleChange}/>
                                    </FormGroup>
                                </Collapse>
                            </Col>
                        </Row>
                        <br/>
                        <h5>Dados da conta bancaria</h5>
                        <hr/>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <h6><b>Nome do portador da conta bancaria: </b> </h6>
                                    <InputText 
                                        name="holder_name"
                                        value={this.state.holder_name}
                                        placeholder="Digite o portador da conta"
                                        invalid={this.state.holder_name_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <h6><b>Agencia sem DV (4 Digitos): </b> </h6>
                                    <InputText 
                                        name="routing_number"
                                        type="number"
                                        value={this.state.routing_number}
                                        placeholder="Digite a agencia sem o digito"
                                        invalid={this.state.routing_number_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <h6><b>Número da Conta: </b> </h6>
                                    <InputText 
                                        name="account_number"
                                        type="number"
                                        value={this.state.account_number}
                                        placeholder="Digite o Número da Conta"
                                        invalid={this.state.account_number_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                    <h6><b>Tipo de conta: </b> </h6>
                                <Select
                                    onChange={this.handleSelect}
                                    value={this.type}
                                    options={conta_tipo}
                                    placeholder="Tipo de conta"/>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <h6><b>Código do banco: </b> </h6>
                                    <InputText 
                                        name="bank_code"
                                        type="number"
                                        value={this.state.bank_code}
                                        placeholder="Digite o código do banco"
                                        invalid={this.state.bank_code_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    :
                    this.state.step === 2 ?
                        <div>
                            <hr/>
                            <h6><b>Nome da Empresa: </b> {this.state?.request?.nomeEmpresarial}</h6>
                            <h6><b>Data de Constituição: </b> {this.state?.request?.dataAbertura}</h6>
                            <h6><b>Natureza Juridica: </b>{this.state?.request?.naturezaJuridica?.descricao}</h6>
                            {this.state?.request?.telefones ?
                                <h6><b>Telefone: </b> {'('+this.state?.request?.telefones[0]?.ddd + ') '+this.state?.request?.telefones[0]?.numero}</h6>
                                :
                                <FormGroup>
                                    <h6><b>Telefone:</b> </h6>
                                    {/* <InputText placeholder="Telefone"/> */}
                                    <InputMasked
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        value={this.state.telefone}
                                        placeholder="Digite o telefone"
                                        invalid={this.state.telefone_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            }
                            <FormGroup>
                                <h6><b>Sócios: </b> </h6>
                                <Select
                                    placeholder="Sócios"
                                    options={this.state.socios}
                                    value={this.state.socio}
                                    onChange={(socio) => this.setState({socio})}
                                />
                                <Input hidden invalid={this.state.socio_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>E-mail Pessoal: </b> </h6>
                                <InputText 
                                    name="email"
                                    value={this.state.email}
                                    placeholder="Digite o e-mail"
                                    invalid={this.state.email_invalid}
                                    onChange={this.handleChange}
                                />
                                <Input hidden invalid={this.state.email_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>MCC:</b> </h6>
                                <Select
                                    placeholder="MCC"
                                    options={this.state.mcc_list}
                                    value={this.state.mcc}
                                    onChange={(mcc) => this.setState({mcc})}
                                />
                                <Input hidden invalid={this.state.mcc_invalid} />
                                <FormFeedback>Campo obrigatório</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h6><b>Plano:</b> </h6>
                                <Select
                                    placeholder="Selecione o Plano"
                                    options={this.state.planos_list}
                                    value={this.state.plano}
                                    onChange={(plano) => this.setState({plano})}
                                />
                                <Input hidden invalid={this.state.plano_invalid} />
                                <FormFeedback>Campo Obrigatório</FormFeedback>
                            </FormGroup>
                        <hr/>
                        <h5>Dados da conta bancaria</h5>
                        <br/>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <h6><b>Nome do portador da conta bancaria: </b> </h6>
                                    <InputText 
                                        name="holder_name"
                                        value={this.state.holder_name}
                                        placeholder="Digite o portador da conta"
                                        invalid={this.state.holder_name_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <h6><b>Agencia sem DV (4 Digitos): </b> </h6>
                                    <InputText 
                                        name="routing_number"
                                        type="number"
                                        value={this.state.routing_number}
                                        placeholder="Digite a agencia sem o digito"
                                        invalid={this.state.routing_number_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <h6><b>Número da Conta: </b> </h6>
                                    <InputText 
                                        name="account_number"
                                        type="number"
                                        value={this.state.account_number}
                                        placeholder="Digite o Número da Conta"
                                        invalid={this.state.account_number_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6><b>Tipo de conta: </b> </h6>
                                <Select
                                    onChange={this.handleSelect}
                                    value={this.type}
                                    options={conta_tipo}
                                    placeholder="Tipo de conta"/>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <h6><b>Código do banco: </b> </h6>
                                    <InputText 
                                        name="bank_code"
                                        type="number"
                                        value={this.state.bank_code}
                                        placeholder="Digite o código do banco"
                                        invalid={this.state.bank_code_invalid}
                                        onChange={this.handleChange}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        </div>
                    :
                    <></>
                }
            </ModalBody>
                <ModalBody 
                    style={{
                        minHeight: '114px', 
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn 
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                        />
                </ModalBody>
            <ModalFooter hidden={this.state.loading}>
                <Button color="danger" onClick={() => this.onClose()}>Cancelar</Button>
                <Button color="success" onClick={() => this.onRegister()}>{this.state.step === 0 ? 'Avançar' : 'Cadastrar'}</Button>
            </ModalFooter>
        </Modal>
    }
}

export default UserModal;