import React from 'react';
import { HiViewGrid } from 'react-icons/hi';
import { Button, Col, Row } from 'reactstrap';
import { ENDPOINT } from '../../../auth/endpoint';
import { getPermissoes, getToken } from '../../../auth/set_services';
import ReactTooltip from 'react-tooltip';
import 'react-dropdown/style.css';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const columns = () => [
  {
    name: "Data",
    selector: "birthdate",
    sortable: false,
    width: '150px',
    cell: row => moment(row.created_at).format('DD/MM/YYYY HH:mm:ss')
  },
  {
    name: "Dados",
    selector: "name",
    sortable: false,
    width: '35%',
    cell: row => {
      return <p><br/>
        <b>Nome:</b> {row.social_name != null ? row.social_name + ' | ' + row.name : row.name.toUpperCase()}<br/>
        <b>Documento:</b> <NumberFormat 
                    displayType={'text'} 
                    value={row.document} 
                    format={row.document.replace(/([^\d])+/gim, '').length > 11 ? '##.###.###/####-##' : "###.###.###-##"} 
                    />
        </p>
    } 
  },
  {
    name: "Telefone",
    selector: "phone",
    sortable: false,
    width: '150px',
    cell: row => "(" +row.ddd+") "+row.phone
  },
  {
    name: "Empresa possui beneficio?",
    selector: "is_name_benefit",
    sortable: false,
    width: '150px',
    cell: row => row.is_name_benefit ? row.is_name_benefit.toUpperCase() : "S/N"
  },
  {
    name: "Dados empresa",
    selector: "is_benefit",
    sortable: false,
    width: '250px',
    cell: row => {
      if (row.business_name) {
        return <p><br/>
          {(row.business_name ? row.business_name.toUpperCase() : row.business_name)}<br/>
          <b>Colaboradores:</b> {(row.collaborators ? row.collaborators : '')}
        </p>
      }

      return  "S/N";
    }
  },
  /* {
    name: "Endereço",
    selector: "address",
    sortable: false,
    width: '250px',
    cell: row => {
      if (row.address) {
        return <div style={{ margin: 8 }}>
                <p>{row.address.logradouro}, {row.address.numero} {row.address.complemento !== null && row.address.complemento !== undefined && row.address.complemento !== '' ? ' - ' + row.address.complemento : ''} - {row.address.bairro}
                <br/>{row.address.cidade} - {row.address.uf}
                <br/>
                  <b>
                    <NumberFormat 
                      displayType={'text'} 
                      value={row.address.cep.replace(/([^\d])+/gim, '')}
                      format={"#####-###"} 
                      />
                  </b> 
                  </p>
              </div>
      }

      return  "S/N";
    }
  } */
];

export const getRows = (state, callback) => {
  var url = ENDPOINT + "multibeneficio/leads/listar?page=" + state.activePage + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&document=" + document;
  }
  else {
    url += "&document=" + document;
  }

  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }

  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      //console.log(p)
      callback(p.data, p.last_page, p.total);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}

export const getRowsPerPage = (page, state, callback) => {
  var url = ENDPOINT + "multibeneficio/leads/listar?page=" + page + "&search=" + state.search;

  var document = state.document.replace(/([^\d])+/gim, '');
  if (state.is_cnpj === false) {
    url += "&document=" + document;
  }
  else {
    url += "&document=" + document;
  }


  if (state.seller_id) {
    url += "&seller_id=" + state.seller_id
  }


  if (state.status_user.length > 0) {
    url += "&status_usuario=" + state.status_user
  }

  var myHeaders = new Headers();

  myHeaders.append('Authorization', 'Bearer ' + getToken());

  var myInit = {
    method: 'GET',
    headers: myHeaders,
    mode: 'cors',
    cache: 'default'
  };
  fetch(url, myInit)
    .then(response => response.json())
    .then((p) => {
      callback(p.data, p.last_page, p.total);
    })
    .catch(() => {
      toast.error('Ocorreu um erro no servidor!');
    })
}