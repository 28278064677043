import axios from "axios";
import Moment from "moment";
import React from "react";
import { MdSearch } from "react-icons/md";
import { toast } from "react-toastify";
import {
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Row,
    FormFeedback
} from "reactstrap"
import { ENDPOINT } from "../../../auth/endpoint";
import { getToken } from "../../../auth/set_services";
import { addOperador } from "../../../services/request_taggys";
import { ButtonOutline } from "../../Button/ButtonOutline";
import { InputMasked } from "../../InputText/InputMasked";
import { InputText } from "../../InputText/InputText";
import ModelReturn from "../ReturnsApiModals/ModelReturn";

const getUser = (cpf, onData) => {
    var url = ENDPOINT+"user/search_cpf_new?cpf="+cpf.replace(/([^\d])+/gim, '');
    axios.get(url, {
        headers: {
            Authorization: 'Bearer '+getToken()
        }})
    .then((response) => {
        var data = response.data;
        onData(data);
        return data;
    })
    .catch((error) => {
        if(error.message === 'Network Error'){
            var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
            var obj = JSON.parse(response);
            onData(obj);
            return obj;
        }
        var data = error.response.data;
        onData(data);
        return data;
    });

}
class AddOperador extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cpf_current: null,
            nome: '',
            nome_invalid: false,
            sobrenome: '',
            sobrenome_invalid: false,
            email: '',
            email_invalid: false,
            cpf: '',
            cpf_invalid: false,
            cnpj: '',
            cnpj_invalid: false,
            data_nascimento: '',
            data_nascimento_invalid: false,
            telefone: '',
            telefone_invalid: false,
            is_form_user: false,
            loading: false,
            error_message: '',
            success_message: ''
        }
        this.onRegister = this.onRegister.bind(this);
        this.onClose = this.onClose.bind(this);
        this.loadUser = this.loadUser.bind(this);
    }
    componentWillUpdate(nextProps, nextState) {
        if (nextProps.editing !== null && nextProps.editing !== this.props.editing) {
            this.loadUser(nextProps.editing);
        }
    }


    onCPF(data){
        if(data.success === true){
            var convert = data.data.nascimento.match(/(\d\d)(\d\d)(\d\d\d\d)/);
            var dateValue = new Date(convert[3], convert[2] - 1, convert[1]);
            this.setState({
                is_form_user: true,
                cpf_current: data.data.ni,
                nome: data.data.nome,
                nome_invalid: false,
                sobrenome: '',
                sobrenome_invalid: false,
                email: '',
                email_invalid: false,
                cnpj_invalid: false,
                data_nascimento: Moment(new Date(dateValue).toDateString()).format('YYYY-MM-DD'),
                data_nascimento_invalid: false,
                telefone: '',
                telefone_invalid: false,
            })
        }
        else{
            this.setState({
                is_form_user: false,
                cpf_current: null,
                nome: '',
                nome_invalid: false,
                sobrenome: '',
                sobrenome_invalid: false,
                email: '',
                email_invalid: false,
                data_nascimento: '',
                data_nascimento_invalid: false,
                telefone: '',
                telefone_invalid: false,
            })
        }
    }

    validateFields() {
        var invalid = 0;
        if(this.state.cpf_current !== null){
            this.setState({cpf_invalid: false})
            if (this.state.nome.length > 3) {
                this.setState({
                    nome_invalid: false
                })
            }
            else {
                invalid++;
                this.setState({
                    nome_invalid: true
                })
            }

            if (this.state.data_nascimento.length > 0) {
                this.setState({
                    data_nascimento_invalid: false
                })
            }
            else {
                invalid++
                this.setState({
                    data_nascimento_invalid: true
                })
            }

            if (this.state.email.length > 3) {
                this.setState({
                    email_invalid: false
                })
            }
            else {
                invalid++
                this.setState({
                    email_invalid: true
                })
            }

            if (this.state.telefone.replace(/([^\d])+/gim, '').length > 10) {
                this.setState({
                    telefone_invalid: false
                })
            }
            else {
                invalid++
                this.setState({
                    telefone_invalid: true
                })
            }
            if(this.props.cnpj === null){
                if (this.state.cnpj.replace(/([^\d])+/gim, '').length > 13) {
                    this.setState({
                        cnpj_invalid: false
                    })
                }
                else {
                    invalid++
                    this.setState({
                        cnpj_invalid: true
                    })
                }
            }

        }
        else{
            invalid++;
            this.setState({cpf_invalid: true})
        }


        return invalid;

    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        this.setState({ [name]: value });
    }
    onData(data) {
        
        if (data.success === true) {
            this.setState({
                success_message: data.message
            });
            //this.storeTaggy(this.onDataToast.bind(this))

        }
        else {
            this.setState({
                error_message: data.errors
            })
        }
    }
    onDataToast(data) {
        
        if (data.success === true) {
            toast.success(data.message);
        }
        else {
            toast.warning(data.errors);
        }
    }
    onRegister() {
        if (this.validateFields() === 0) {
            this.setState({ loading: true });
            var cnpj = (this.props.cnpj !== null ? this.props.cnpj : this.state.cnpj);
            addOperador(this.state, cnpj, this.onData.bind(this));
        }
    }

    storeTaggy = (onData) => {
        this.setState({loading: true});
        var url = ENDPOINT+'operadores/store_taggy';

        var cnpj = (this.props.cnpj !== null ? this.props.cnpj : this.state.cnpj.replace(/([^\d])+/gim, ''));

        var formData = new FormData();

        formData.append('num_taggys', 1);
        formData.append('cpf', cnpj);
        formData.append('cnpj', this.props.cnpj);
    
        axios.post(url, formData, {
            headers: {
                Authorization: 'Bearer '+getToken()
            }})
        .then((response) => {
            var data = response.data;
            onData(data);
            return data;
        })
        .catch((error) => {
            if(error.message === 'Network Error'){
                var response = '{"success": "false", "errors": "Ocorreu um erro no servidor" }';
                var obj = JSON.parse(response);
                onData(obj);
                return obj;
            }
            var data = error.response.data;
            onData(data);
            return data;
        });
    }
    onClose() {
        this.setState({
            cpf_current: null,
            nome: '',
            nome_invalid: false,
            sobrenome: '',
            sobrenome_invalid: false,
            email: '',
            email_invalid: false,
            cpf: '',
            cpf_invalid: false,
            cnpj: '',
            cnpj_invalid: false,
            data_nascimento: '',
            data_nascimento_invalid: false,
            telefone: '',
            telefone_invalid: false,
            is_form_user: false,
            loading: false,
            error_message: '',
            success_message: ''
        });
        this.props.onClose(false);
    }
    onError() {
        this.setState({
            error_message: '',
            loading: false
        })
    }

    loadUser(user) {
        this.setState({
            nome: user.nome,
            sobrenome: user.sobrenome === null ? '' : user.sobrenome,
            cpf: user.cpf,
            data_nascimento: user.data_nascimento === null ? '' : user.data_nascimento,
            email: user.email === null ? '' : user.email,
            telefone: user.telefone === null ? '' : user.telefone,
        });
    }
    onSearchCpf(){
        getUser(this.state.cpf, this.onCPF.bind(this));
    }

    render() {
        return (
            <Modal isOpen={this.props.visible} size={'lg'}>
                <ModalHeader>
                    Adicionar Operadores ao CNPJ
            </ModalHeader>
                <ModalBody hidden={this.state.loading}>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col md={11}>
                                    <InputMasked
                                        mask="999.999.999-99"
                                        name="cpf"
                                        value={this.state.cpf}
                                        placeholder="CPF"
                                        invalid={this.state.cpf_invalid}
                                        onChange={this.handleChange} />
                                    <FormFeedback>CPF Inválido / Não Buscado</FormFeedback>
                                </Col>
                                <Col md={1} style={{ padding: 0 }}>
                                    <ButtonOutline
                                        style={{ margin: 0 }}
                                        onClick={this.onSearchCpf.bind(this)}
                                    ><MdSearch style={{ padding: 0 }} /></ButtonOutline>
                                </Col>
                            </Row>
                        </FormGroup>
                        <div hidden={!this.state.is_form_user}>
                            <FormGroup>
                                <InputText
                                    name="nome"
                                    value={this.state.nome}
                                    type="text"
                                    disabled
                                    placeholder="Nome"
                                    invalid={this.state.nome_invalid}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>Informe o Nome</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <InputText
                                    name="data_nascimento"
                                    value={this.state.data_nascimento}
                                    type="date"
                                    disabled
                                    placeholder="Data de Nascimento"
                                    invalid={this.state.data_nascimento_invalid}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>Informe a data de nascimento</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <InputText
                                    name="email"
                                    value={this.state.email}
                                    type="text"
                                    placeholder="E-mail"
                                    invalid={this.state.email_invalid}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>Informe um e-mail valido</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <InputMasked
                                    name="telefone"
                                    value={this.state.telefone}
                                    type="text"
                                    mask='(99) 99999-9999'
                                    placeholder="Celular"
                                    invalid={this.state.telefone_invalid}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>Informe um celular valido</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <InputMasked
                                    name="cnpj"
                                    value={this.state.cnpj}
                                    hidden={this.props.cnpj !== null}
                                    type="text"
                                    mask='99.999.999/9999-99'
                                    placeholder="CNPJ (Cadastrado em nosso sistema)"
                                    invalid={this.state.cnpj_invalid}
                                    onChange={this.handleChange}
                                />
                                <FormFeedback>Informe um CNPJ valido e cadastrado em nosso sistema</FormFeedback>
                            </FormGroup>
                        </div>
                    </Form>
                </ModalBody>
                <ModalBody
                    style={{
                        minHeight: '114px',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        paddingBottom: '47px'
                    }} hidden={!this.state.loading}>
                    <ModelReturn
                        error_message={this.state.error_message}
                        success_message={this.state.success_message}
                        loading={this.state.loading}
                        onClose={this.onClose}
                        onError={this.onError.bind(this)}
                    />

                </ModalBody>
                <ModalFooter hidden={this.state.loading}>
                    <Button color="danger" onClick={() => this.onClose(false)}>Cancelar</Button>
                    <Button color="success" onClick={this.onRegister}>Cadastrar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddOperador;